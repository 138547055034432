<template>
  <div class="HistoryCard" :class="{ hover: hover && !open, open: open }" ref="card">
    <div
      class="top"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="slideAccordion"
    >
      <img class="arrow" src="@assets/img/dropdownArrow.svg" alt="dropdownArrow" />
      <ul class="list">
        <li>
          <b class="title">Round ID</b>
          <b>{{ card.gameId }}</b>
        </li>
        <li>
          <b class="title">Date</b>
          <b class="date">{{ date }}</b>
          <u class="time">{{ time }}</u>
        </li>
        <li>
          <b class="title">Bet</b>
          <b class="currency">{{ currency(card.totalBet) }}</b>
        </li>
        <li>
          <b class="title">win</b>
          <b class="currency">{{ currency(card.totalWin) }}</b>
        </li>
      </ul>
    </div>
    <div class="content" ref="content">
      <!-- cards -->
      <div class="tableWrap">
        <h6>Cards</h6>
        <table>
          <thead>
            <tr>
              <th>BOX</th>
              <th>Cards</th>
              <th>Results</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PLAYER</td>
              <td>{{ card.playerCards }}</td>
              <td>{{ card.totalPlayerScore }}</td>
            </tr>
            <tr>
              <td>BANKER</td>
              <td>{{ card.dealerCards }}</td>
              <td>{{ card.totalDealerScore }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- bets -->
      <div class="tableWrap">
        <h6>Bets</h6>
        <table>
          <thead>
            <tr>
              <th>BOX</th>
              <th>Bet</th>
              <th>win</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(bet, key) in card.bets" :key="bet.id">
              <td>{{ key | betName }}</td>
              <td class="currency">{{ currency(bet.amount) }}</td>
              <td class="currency">{{ currency(bet.win) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul class="list noWrap shaffleList">
        <li>
          <b class="title default">SHA</b>
          <span>{{ fair.hash }}</span>
        </li>
        <li>
          <b class="title default">Initial shuffle</b>
          <span>{{ fair.initialShuffle }}</span>
        </li>
        <li>
          <b class="title default">Server seed</b>
          <span>{{ fair.serverSeed }}</span>
        </li>
        <li>
          <b class="title default">Player seed</b>
          <span>{{ fair.playerSeed }}</span>
        </li>
        <li>
          <b class="title default">Final shuffle</b>
          <span>{{ fair.finalShuffle }}</span>
        </li>
      </ul>
      <button class="mainBtn verifyBtn h36" @click="verify">verify</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TweenMax } from "gsap";
export default {
  name: "HistoryCard",
  components: {},
  props: {
    card: { default: () => {} },
  },
  data: function () {
    return {
      hover: false,
      open: false,
      openClass: false,
      unsubscribe: null,
    };
  },
  filters: {
    betName(name) {
      return name.replace(/_/gim, " ");
    },
  },
  computed: {
    ...mapGetters(["GET_ICurrencyFormatter"]),
    carrentDate() {
      return new Date(this.card.resolveDate);
    },
    date() {
      const year = this.carrentDate.getFullYear();
      const month = this.prefix(this.carrentDate.getMonth() + 1);
      const day = this.prefix(this.carrentDate.getDate());
      return `${day}/${month}/${year}`;
    },
    time() {
      const hours = this.prefix(this.carrentDate.getHours());
      const minutes = this.prefix(this.carrentDate.getMinutes());
      const seconds = this.prefix(this.carrentDate.getSeconds());
      return `${hours}:${minutes}:${seconds}`;
    },
    fair() {
      return this.card.provablyFair;
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type, payload }, state) => {
      if (type == "SET_ModalOpen" && payload.open == false && this.open) {
        setTimeout(this.slideAccordion, 500);
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    currency(value) {
      return this.GET_ICurrencyFormatter.format(value);
    },
    prefix: (index) => (index < 10 ? "0" + index : index),
    getTotalCardsHeight() {
      const list = Array.prototype.slice.call(document.querySelectorAll(".HistoryCard"));
      return list.reduce((summ, elem) => summ + elem.clientHeight, 0);
    },
    slideAccordion() {
      const time = 0.25;
      const el = this.$refs.content;
      let hNow = el.clientHeight;
      let hOpen = el.clientHeight;
      this.openClass = !this.open;
      if (!this.open) {
        el.removeAttribute("style");
        TweenMax.set(el, { display: "block", height: "" });
        hOpen = el.clientHeight;
      }
      let hNew = !this.open ? hOpen : 0;
      TweenMax.fromTo(
        el,
        time,
        { height: hNow },
        {
          display: this.open ? "none" : "block",
          margin: !this.open ? "" : 0,
          height: hNew,
          onComplete: () => {
            TweenMax.set(el, { height: "" });
            const psContainer = el.closest(".ps-container");
            if (psContainer != null) {
              if (this.open && psContainer.scrollHeight >= this.getTotalCardsHeight()) {
                psContainer.scrollTo(0, 0);
                psContainer.dispatchEvent(new Event("scroll", { bubbles: true }));
                psContainer.scrollTo(0, psContainer.scrollHeight);
              }
              this.open = !this.open;
            }
          },
        }
      );
    },
    verify() {
      let url = new URL("https://provfair.com/verify/");
      const params = {
        clientSeed: this.fair.playerSeed,
        initialHash: this.fair.hash,
        serverSeed: this.fair.serverSeed,
        initialShuffle: this.fair.initialShuffle,
        finalShuffle: this.fair.finalShuffle,
      };
      for (const key in params) {
        url.searchParams.set(key, params[key]);
      }
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
