<template>
  <Layout id="ProvablyFair" title="Provably Fair">
    <div class="roundInfo previousRound" :class="{ noPreviousRound: !previousRound }">
      <div class="roundTitle">
        <h5>Previous round</h5>
        <p>Round ID: {{ previousRound ? previousRound.gameId : "-" }}</p>
      </div>
      <table>
        <thead></thead>
        <tbody>
          <tr>
            <td>SHA</td>
            <td>
              <span>{{ previousRound ? previousRound.provablyFair.hash : "-" }}</span>
            </td>
          </tr>
          <tr>
            <td>Initial shuffle</td>
            <td>
              <span>{{
                previousRound ? previousRound.provablyFair.initialShuffle : "-"
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Server seed</td>
            <td>
              <span>{{
                previousRound ? previousRound.provablyFair.serverSeed : "-"
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Player seed</td>
            <td>
              <span>{{
                previousRound ? previousRound.provablyFair.playerSeed : "-"
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Final shuffle</td>
            <td>
              <span>{{
                previousRound ? previousRound.provablyFair.finalShuffle : "-"
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="roundInfo currentRound" v-if="GET_GameState">
      <div class="roundTitle">
        <h5>New round</h5>
        <p>Round ID: {{ GET_GameState.gameId }}</p>
      </div>
      <table>
        <thead></thead>
        <tbody>
          <tr>
            <td>SHA</td>
            <td>
              <input
                type="text"
                class="input"
                :value="GET_GameState.provablyFair.hash"
                readonly
              />
            </td>
          </tr>
          <tr>
            <td>Player seed</td>
            <td>
              <input
                type="text"
                class="input"
                v-model="PlayerSeed"
                @blur="changePlayerSeed"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "@/components/Modals/PopupLayout";
export default {
  name: "ProvablyFair",
  components: {
    Layout,
  },
  data() {
    return {
      PlayerSeed: "",
    };
  },
  computed: {
    ...mapGetters(["GET_HistoryData", "GET_GameState", "GET_PlayerSeed"]),
    emptyHistory() {
      return !this.GET_HistoryData.length;
    },
    previousRound() {
      if (this.emptyHistory) return false;
      return this.GET_HistoryData[0];
    },
  },
  methods: {
    changePlayerSeed() {
      this.$store.commit("SET_PlayerSeed", this.PlayerSeed);
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  created() {
    this.PlayerSeed = this.GET_PlayerSeed;
    this.unsubscribe = this.$store.subscribe(({ type, payload }, state) => {
      if (type == "SET_PlayerSeed" && payload.length) {
        this.PlayerSeed = payload;
      }
    });
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>

<style lang="less">
#ProvablyFair .title {
  margin-bottom: 20px;
}
</style>
