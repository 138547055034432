<template>
  <div class="popup_block" :class="[id]" :id="id">
    <div
      class="popupWrap flex"
      @click="hidePopup"
      v-if="GET_CurrentPopup && GET_CurrentPopup == id"
    >
      <div class="popupContent">
        <img
          src="@assets/img/closeModal.svg"
          alt="close"
          class="close"
          @click="$popup.hide()"
        />
        <!--  -->
        <h5 class="h2 title">{{ title }}</h5>
        <div
          v-scrollbar="scrollOptions"
          class="content"
          ref="scrollbar"
          :class="{ showTopCurtain, showBottomCurtain }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PopupLayout",
  props: {
    id: {
      type: String,
      default: "",
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    disableScroll: {
      default: false,
    },
  },
  data() {
    return {
      showTopCurtain: false,
      showBottomCurtain: false,
      settings: {
        suppressScrollY: this.$props.disableScroll,
        suppressScrollX: this.$props.disableScroll,
        wheelPropagation: false,
        unsubscribeAction: null,
      },
      scrollOptions: {
        thumbMinSize: 20,
        renderByPixels: true,
        continuousScrolling: false,
        alwaysShowTracks: true,
        speed: 0.2,
        onlyScrollIfNeeded: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["GET_CurrentPopup"]),
  },
  created() {
    this.unsubscribeAction = this.$store.subscribeAction(({ type, payload }, state) => {
      if (type == "SHOW_Popup" && payload == this.id) {
        setTimeout(() => {
          this.initScrollBar();
        }, 250);
      } else if (type == "HIDE_Popup" && this.GET_CurrentPopup == this.id) {
        this.destroyScrollBar();
      }
    });
  },
  methods: {
    hidePopup(e) {
      if (
        e.target.closest(".popupContent") == null &&
        !e.target.classList.contains("popupContent")
      ) {
        this.$popup.hide();
      }
    },
    initScrollBar() {
      this.$refs.scrollbar.addEventListener("scroll", this.teek);
      this.showTopCurtain = false;
      console.log({
        scrollHeight: this.$refs.scrollbar.scrollHeight,
        clientHeight: this.$refs.scrollbar.clientHeight
      });
      if (this.$refs.scrollbar.scrollHeight > this.$refs.scrollbar.clientHeight + 1) {
        this.showBottomCurtain = true;
      }
    },
    destroyScrollBar() {
      if (this.$refs.scrollbar)
        this.$refs.scrollbar.removeEventListener("scroll", this.teek);
    },
    teek({ detail: { limit, offset } = {} }) {
      this.showTopCurtain = offset.y > 0;
      this.showBottomCurtain = limit.y > 0 && limit.y != offset.y;
    },
  },
  beforeDestroy() {
    this.destroyScrollBar();
    if (this.unsubscribeAction) this.unsubscribeAction();
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
