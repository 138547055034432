<template>
  <div class="scoreBlock">
    <div class="scoreBlockItem player">
      <div class="scoreItem">{{ GET_PlayerScore }}</div>
      <div class="cardsWrap">
        <div class="name">PLAYER</div>
        <div class="cards">
          <Card
            v-for="card in GET_Cards.player"
            :key="card.key + '_' + rndKey"
            :card="card"
            :thirdCard="card.key == 3"
          />
        </div>
      </div>
    </div>
    <div class="scoreBlockItem banker">
      <div class="cardsWrap">
        <div class="name">BANKER</div>
        <div class="cards">
          <Card
            v-for="card in GET_Cards.banker"
            :key="card.key + '_' + rndKey"
            :card="card"
            :thirdCard="card.key == 6"
          />
        </div>
      </div>
      <div class="scoreItem">{{ GET_BankerScore }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Card from "@/components/Card/";
export default {
  name: "ScoreBlock",
  props: {
    playerScore: { default: 0 },
    bankerScore: { default: 0 },
  },
  components: { Card },

  data: function () {
    return {
      unsubscribe: null,
      rndKey: 0,
    };
  },

  computed: {
    ...mapGetters(["GET_Cards", "GET_PlayerScore", "GET_BankerScore"]),
  },
  mounted: function () {
    this.$nextTick(function () {});
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type, payload }, state) => {
      if (type == "SET_WindowWidth") this.rndKey = this.$rnd();
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {},
};
</script>

<style lang="less" scoped src="./component.less"></style>
