<template>
  <Layout id="Menu" title="" :disableScroll="true">
    <MenuContent :inMobileMenu="true" />
  </Layout>
</template>

<script>
import Layout from "@/components/Modals/PopupLayout";
import MenuContent from "@/components/Menu/MenuContent";
export default {
  name: "MenuModal",
  components: {
    Layout,
    MenuContent,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="less" scoped src="./component.less"></style>
<style lang="less">
#Menu {
  .popupContent {
    padding: 0 0 17px;
  }
  .h2.title {
    display: none;
  }
}
</style>
