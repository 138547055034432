import Vue from "vue";
import Vuex from "vuex";
import { TweenMax } from 'gsap';
import getSymbolFromCurrency from 'currency-symbol-map'

Vue.use(Vuex);

import chips from './modules/chips'
import table from './modules/table'
import game from './modules/game'
import menu from './modules/menu'
import sound from './modules/sound'
import history from './modules/history'
import currency from './modules/currency'
import dealer from './modules/dealer'
import connection from './modules/connection'

const myVuex = new Vuex.Store({
  state: {
    currentPopup: null,
    disabledInterface: false,
    loading: true,
    loadingPercent: 0,
    session_id: null,
    gameState: {
      lastWin: 0,
      lastWinTemp: 0,
      storageData: {}
    },
    betsData: null,
    cursor: null,
    cursorImage: null,
    guestName: "Guest",
    rotatedCardNow: null,
    rotatedCardNowOpen: false,
    thirdCardNow: false,
    showUserCards: false,
    isMobile: false,
    // 
    canShowBetAreaOutcomes: false,
    disabledRotateBtn: true,
    activeTable: false, // temp enabled
    canDeal: false,
    canRebet: false,
    canRotate: false,
    windowWidth: window.innerWidth,
    windowHeight: window.windowHeight,
    modalOpen: false,
    modalOpenName: '',
    showPreloader: true,
  },
  mutations: {
    SET_CurrentPopup: (state, payload) => {
      state.currentPopup = payload
    },
    SET_DisabledInterface: (state, payload) => {
      state.disabledInterface = payload
    },
    SET_ModalOpen: (state, { open = false, name = "" } = {}) => {
      state.modalOpen = open;
      state.modalOpenName = name;
    },
    SET_Controls: (state, data) => {
      const { canDeal,
        canRebet,
        canRotate,
        canShowBetAreaOutcomes,
        activeTable,
        disabledRotateBtn,
        rotatedCardNow,
        rotatedCardNowIndex,
        rotatedCardNowOpen,
        showUserCards,
        thirdCardNow,
      } = data;
      if (canDeal != undefined) state.canDeal = canDeal;
      if (canRebet != undefined) state.canRebet = canRebet;
      if (canRotate != undefined) state.canRotate = canRotate;
      if (canShowBetAreaOutcomes != undefined) state.canShowBetAreaOutcomes = canShowBetAreaOutcomes;
      if (activeTable != undefined) state.activeTable = activeTable;
      if (disabledRotateBtn != undefined) state.disabledRotateBtn = disabledRotateBtn;
      if (rotatedCardNow != undefined) state.rotatedCardNow = rotatedCardNow;
      if (rotatedCardNowIndex != undefined) state.game.rotatedCardNowIndex = rotatedCardNowIndex;
      if (rotatedCardNowOpen != undefined) state.rotatedCardNowOpen = rotatedCardNowOpen;
      if (showUserCards != undefined) state.showUserCards = showUserCards;
      if (thirdCardNow != undefined) state.thirdCardNow = thirdCardNow;
    },
    SET_WindowWidth: (state, payload) => {
      state.windowWidth = payload
    },
    SET_WindowHeight: (state, payload) => {
      state.windowHeight = payload
    },
    SET_isMobile: (state, payload) => {
      state.isMobile = payload
    },
    SET_Loading: (state, payload) => {
      state.loading = payload
    },
    SET_Session_id: (state, payload) => {
      state.session_id = payload
    },
    SET_GameState: (state, payload) => {
      state.gameState = payload
    },
    SET_Cursor: (state, payload) => {
      state.cursor = payload
    },
    SET_CursorImage: (state, payload) => {
      state.cursorImage = payload
    },
    SET_BetsData: (state, payload) => {
      state.betsData = payload
    },
    SET_LoadingPercent: (state, payload) => {
      state.loadingPercent = payload;
    },
  },
  getters: {
    GET_CurrentPopup: state => state.currentPopup,
    GET_DisabledInterface: (state) => state.disabledInterface,
    IS_NO_COMMISSION_BACCARAT: state => {
      if (!state.gameState || !state.gameState.config) return false;
      return state.gameState.config.variation == 'NO_COMMISSION_BACCARAT'
    },
    GET_ModalOpen: state => {
      return {
        name: state.modalOpenName,
        open: state.modalOpen,
      }
    },
    GET_WindowWidth: state => {
      return state.windowWidth
    },
    GET_WindowHeight: state => {
      return state.windowHeight
    },
    isDesktop: state => {
      return state.windowWidth > 991
    },
    GET_isMobile: state => {
      return state.isMobile
    },
    GET_ShowUserCards: state => {
      return state.showUserCards
    },
    GET_RotatedCardNow: state => {
      return state.rotatedCardNow
    },
    GET_RotatedCardNowOpen: state => {
      return state.rotatedCardNowOpen
    },
    GET_CursorImage: state => {
      return state.cursorImage
    },
    GET_BetsData: state => {
      return state.betsData
    },
    GET_GameState: state => {
      return state.gameState
    },
    GET_CanShowBetAreaOutcomes: state => {
      return state.canShowBetAreaOutcomes
    },
    GET_DisabledRotateBtn: state => {
      return state.disabledRotateBtn
    },
    GET_ActiveTable: state => {
      return state.activeTable
    },
    GET_CanDeal: state => {
      return state.canDeal
    },
    GET_CanRebet: state => {
      return state.canRebet
    },
    GET_CanRotate: state => {
      return state.canRotate
    },
    GET_StateCards: state => {
      const { playerCards, dealerCards } = state.gameState;
      const thirdDealerCard = dealerCards && dealerCards[2] != undefined;
      const thirdPlayerCard = playerCards && playerCards[2] != undefined;
      return { thirdDealerCard, thirdPlayerCard, playerCards, dealerCards }
    },
    // 
    GET_TotalBet: (state, { GET_TableAreasList }) => {
      return GET_TableAreasList.reduce(
        (sum, current) => parseFloat((sum + current.total).toFixed(10)),
        0
      );
    },
    GET_BalanceWithBets: (state, { GET_TotalBet }) => {
      if (!state.gameState) return 0;
      return parseFloat((state.gameState.balance - GET_TotalBet).toFixed(10))
    },
    GET_Balance: (state, { GET_TotalBet }) => {
      if (!state.gameState) return 0;
      return state.gameState.balance
    },
    GET_LastWin: state => {
      if (!state.gameState || !state.gameState.totalWin) return 0;
      return state.gameState.lastWin;
    },
    GET_BetTypeLimits: state => {
      return state.gameState.config.betTypeLimits;
    },
    GET_DisableOppositeBets: state => state.gameState.config.disableOppositeBets,
    GET_ThirdCardNow: state => state.thirdCardNow,
    DISABLED_Menu: state => {
      return state.gameState.state != "NO_GAME"
    },
    IS_Loading: state => state.loading,
    GET_LoadingPercent: state => state.loadingPercent,
  },
  actions: {
    SHOW_Popup({ commit }, payload) {
      commit('SET_CurrentPopup', payload)
    },
    HIDE_Popup({ commit }) {
      commit('SET_CurrentPopup', null)
    },
    HIDE_Preloader({ state, commit, dispatch }) {
      commit("SET_Loading", false);
      setTimeout(() => {
        TweenMax.set('.Interface', { opacity: 1 });
        TweenMax.to('#Preloader', 1, {
          opacity: 0, ease: "Power0.easeNone", onComplete: () => {
            state.showPreloader = false;
          }
        });

        if (state.game.needReloadPage) {
          dispatch('SHOW_Reload');
        }
      }, 500);

      setTimeout(() => {
        dispatch("ENABLE_Sounds");
      }, 1000);
    },
    PlayInit({ commit, state, dispatch }, data) {
      if (!state.gameState) state.gameState = {};
      state.gameState.thirdDealerCard = null;
      state.gameState.thirdPlayerCard = null;
      state.gameState.playerCards = null;
      state.gameState.dealerCards = null;
      state.gameState.config = {};
      state.activeTable = true;
      state.activeTable = true;
      dispatch('RESET_PlayerCards')
      dispatch('RESET_BankerCards')
      commit("SET_GameState", { ...state.gameState, ...data });
    },
    PlayDeal({ commit, state }, data) {
      const { balance } = state.gameState
      commit("SET_GameState", { ...state.gameState, ...data, balance, lastWinTemp: data.totalWin });
    },
    GameResolve({ commit, state, dispatch }, data) {
      const lastWin = state.gameState.lastWinTemp;
      commit("SET_GameState", { ...state.gameState, ...data, lastWin });
      dispatch('CLEAR_All_TableArea');
    },
    SET_CursorImage({ commit }, payload) {
      commit("SET_CursorImage", payload);
    },
    ShowHideCursor({ state }, show = true) {
      TweenMax.to(state.cursor, 0.25, {
        opacity: show ? 1 : 0
      })
    },
    SAVE_Bets({ state, getters }) {
      const data = {};
      getters.GET_TableAreasList.forEach(({ key, bets, total }) => {
        data[key] = { bets, total };
      });
      localStorage.setItem("bets", JSON.stringify(data));
    },
    RESTORE_Bets({ state, getters }) {
      if (!localStorage.bets) return false;
      const data = JSON.parse(localStorage.bets);
      for (const key in data) {
        const element = data[key];
        getters.GET_TableAreas[key].bets = element.bets;
        getters.GET_TableAreas[key].total = element.total;
      };
      state.table.tableAreasClone = JSON.parse(JSON.stringify(getters.GET_TableAreas)); // clone
    },
    REBET({ commit, getters, state }, setX2 = false) {
      commit('SET_Controls', {
        canShowBetAreaOutcomes: false
      });
      const summ = (() => {
        let count = 0;
        for (const key in getters.GET_BetsData) {
          if (Object.hasOwnProperty.call(getters.GET_BetsData, key)) {
            count += getters.GET_BetsData[key];
          }
        }
        return count;
      })();

      if (summ * (setX2 ? 2 : 1) > getters.GET_Balance) {
        Vue.prototype.$showError(
          "Insufficient funds",
          "Pleace make a deposit or decrease bet size"
        );
        return false;
      } else {

        // check Bet limit max
        const betTypeLimits = Object.entries(getters.GET_BetTypeLimits);
        for (const key in getters.GET_BetsData) {
          if (Object.hasOwnProperty.call(getters.GET_BetsData, key)) {
            const bet = getters.GET_BetsData[key];
            const currentLimit = betTypeLimits.find((el) => {
              return el[1].betTypes.includes(key);
            })[1];

            if (bet * (setX2 ? 2 : 1) > currentLimit.max) {
              let betName = key.replace(/ /gi, "").toLocaleLowerCase();
              betName = betName[0].toUpperCase() + betName.slice(1);
              Vue.prototype.$showError("", `${betName} bet limit max.`);
              return false;
            }
          }
        }

        for (const key in state.table.tableAreasClone) {
          const elem = state.table.tableAreasClone[key];
          const { total, bets } = elem;
          const mainObj = state.table.tableAreas[key];
          if (total > 0 && bets.length) {
            mainObj.total = total * (setX2 ? 2 : 1);
            mainObj.bets = [];
            for (let index = 0; index < (setX2 ? 2 : 1); index++) {
              bets.forEach((element) => {
                mainObj.bets.push(element);
              });
            };
          };
        };
        commit('SET_Controls', {
          canDeal: true,
          canRebet: false,
          canRotate: false,
        });
      }
    },
    RESUME_Game({ commit }) {
      commit('SET_Controls', {
        canShowBetAreaOutcomes: false,
        disabledRotateBtn: true,
        activeTable: false,
        canDeal: false,
        canRebet: false,
        canRotate: false,
      });
    },
  },
  modules: {
    chips, table, game, menu, sound, history, currency, dealer, connection
  }
});
export default myVuex
