<template>
  <div class="popup_wrap">
    <div class="popup_bg"></div>
    <GameRules />
    <History />
    <ProvablyFair />
    <Menu v-if="!$isDesktop()" />
    <TableLimits v-if="!$isDesktop()" />
    <Statistics v-if="!$isDesktop()" />
    <FeedbackModal v-if="!$isDesktop()" />
  </div>
</template>

<script>
import GameRules from "./ModalsList/GameRules/";
import History from "./ModalsList/History/";
import ProvablyFair from "./ModalsList/ProvablyFair/";
import Menu from "./ModalsList/Menu/";
import TableLimits from "./ModalsList/TableLimits/";
import Statistics from "./ModalsList/Statistics/";
import FeedbackModal from "./ModalsList/Feedback/";
export default {
  components: {
    GameRules,
    History,
    ProvablyFair,
    Menu,
    TableLimits,
    Statistics,
    FeedbackModal,
  },
  // updated() {
  //   this.$popup.show("Statistics");
  // },
  // mounted() {
  //   this.$popup.show("Statistics");
  // },
};
</script>

<style lang="less" src="./component.less"></style>
