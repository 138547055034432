import axios from "axios";

export default {
    install(Vue, { store }) {
        let errorCount = 0;
        const urlParams = new URLSearchParams(window.location.search);
        const baseURL = urlParams.get('engine_base_url') ? urlParams.get('engine_base_url') : 'https://qa-core.onetouch.io';
        const api = axios.create({
            baseURL: decodeURI(baseURL),
            headers: {
                common: {
                    Accept: "application/json;",
                },
            },
        });

        api.interceptors.request.use(async (req) => {
            // if (store.getters.GET_ConnectionLost) {
            //     return {
            //         ...req,
            //         cancelToken: new axios.CancelToken((cancel) => cancel('Connection lost now!'))
            //     };
            // }

            // set sessionId
            if (req.data == undefined) req.data = {};
            req.data.sessionId = store.state.session_id;
            // set time
            req.time = { startTime: new Date() };
            return req;
        });

        api.interceptors.response.use(
            (res) => {
                const apiUrl = res.config.url;
                res.config.time.endTime = new Date();
                res.duration = res.config.time.endTime - res.config.time.startTime;
                // console.log(
                //     "Request " + apiUrl + " took " + res.duration / 1000 + " s"
                // );
                //
                return res;
            },
            async (error) => {
                if (error.config.ping) { // ping error
                    return Promise.reject(error);
                }
                
                if (!error.response) {
                    store.dispatch('SHOW_Reload');
                    return Promise.reject(error);
                }
                const originalRequest = error.response.config;
                const status = error.response ? error.response.status : 404;
                console.log("error status", status);
                console.log("error", error.response.data);
                if (originalRequest.play) {
                    store.dispatch('SHOW_Reload');
                } else {
                    // 
                    if (++errorCount < 3) {
                        if (error.response.data.type == "API_ERROR" && error.response.data.errors[0].code == 'game.state.error') {
                            Vue.prototype.$game.playInit(() => {
                                Vue.prototype.$game.getState();
                            });
                        }
                    } else {
                        store.dispatch('SHOW_Reload');
                    }
                    return Promise.reject(error);
                }
            }
        );
        Vue.prototype.$axios = api;
        return api;
    },
};
