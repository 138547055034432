<template>
  <table class="statisticsTable derivedTable BigEyeBoyTable">
    <tbody>
      <tr v-for="(row, rowIndex) in tableData" :key="name.trim() + '_row_' + rowIndex">
        <td
          v-for="(column, columnIndex) in row"
          :key="name + '_column_' + columnIndex"
          :style="'width:' + 100 / tableData[0].length + '%'"
        >
          <div class="row margin0" v-if="column">
            <div class="redColumn">
              <span
                class="circle red"
                v-for="redCount in column.redCount"
                :key="
                  'row_' +
                  rowIndex +
                  '_column_' +
                  columnIndex +
                  '_redCount_' +
                  redCount +
                  '_' +
                  GET_HistoryDataKey
                "
              ></span>
            </div>
            <div class="blueCount">
              <span
                class="circle blue"
                v-for="blueCount in column.blueCount"
                :key="
                  'row_' +
                  rowIndex +
                  '_column_' +
                  columnIndex +
                  '_blueCount_' +
                  blueCount +
                  '_' +
                  GET_HistoryDataKey
                "
              ></span>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BUILD_DERIVED_TABLE, CREATE_TABLE } from "../compare";

export default {
  name: "BigEyeBoyTable",
  data() {
    return {
      name: "BigEyeBoyTable",
      n: 1,
      table: null,
      list: null,
      tableData: null,
    };
  },
  computed: {
    ...mapGetters(["GET_BigRoad", "GET_StatisticsTableSettings", "GET_HistoryDataKey"]),
  },
  methods: {
    ...mapActions(["CAN_BuildDerivedRoad", "GET_BigRoadListSlice"]),
  },
  async mounted() {
    if (await this.CAN_BuildDerivedRoad(this.n - 1)) {
      this.list = await this.GET_BigRoadListSlice(this.n - 1);
      this.tableData = BUILD_DERIVED_TABLE(this.list, this.n);
    } else {
      this.tableData = CREATE_TABLE();
    }
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
<style lang="less" src="../derived.less"></style>
