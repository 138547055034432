<template>
  <div id="cursor" v-if="image">
    <img :src="image" alt="cursor" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "myCursor",
  data: function () {
    return {
      cursor: null,
    };
  },
  computed: {
    ...mapGetters(["GET_CursorImage", "GET_isMobile"]),
    image() {
      return this.GET_CursorImage
        ? require(`@/assets/img/chips/${this.GET_CursorImage}`)
        : null;
    },
  },
  methods: {
    onDocumentMouseMove(event) {
      this.cursor.style.left = event.clientX + "px";
      this.cursor.style.top = event.clientY + "px";
    },
  },
  mounted: function () {
    this.cursor = document.getElementById("cursor");
    document.addEventListener("mousemove", this.onDocumentMouseMove, false);
    this.$store.commit("SET_Cursor", this.cursor);
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.onDocumentMouseMove, false);
    this.$store.commit("SET_Cursor", null);
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
