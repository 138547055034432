<template>
  <Layout id="TableLimits" title="Table limits" :disableScroll="true" class="smallTitle">
    <LimitsTable class="inMobileMenu" />
  </Layout>
</template>

<script>
import Layout from "@/components/Modals/PopupLayout";
import LimitsTable from "@/components/LimitsTable/";

export default {
  name: "TableLimitsModal",
  components: {
    Layout,
    LimitsTable,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="less" scoped src="./component.less"></style>
<style lang="less">
#TableLimits {
  .popupContent {
    padding-left: 0;
  }
  .content {
    padding-right: 0;
  }
  .h2.title {
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
