export default {
    install(Vue) {
        Vue.prototype.$getBetsKey = (key) => {
            switch (key) {
                case "lucky_6":
                    return "LUCKY_SIX";
                    break;
                case "player_pair":
                    return "PLAYER_PAIR";
                    break;
                case "big":
                    return "BIG";
                    break;
                case "small":
                    return "SMALL";
                    break;
                case "banker_pair":
                    return "BANKER_PAIR";
                    break;
                case "player":
                    return "PLAYER";
                    break;
                case "tie":
                    return "TIE";
                    break;
                case "banker":
                    return "BANKER";
                    break;
                default:
                    return "";
                    break;
            }
        }
    }
}