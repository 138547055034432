<template>
  <div class="GamePanel" :class="{ noDealer: !GET_Dealer }">
    <template v-if="$isDesktop()">
      <div class="topRow row nowrap between margin0">
        <UserStatistics />
        <SelectDealer v-if="!GET_Dealer" />
        <GameTable v-else />
        <Chips />
      </div>
      <div class="bottomRow row margin0 between alignCenter">
        <div class="leftContent">
          <div class="name">Welcome, {{ $store.state.guestName }}</div>
        </div>
        <ControllButtons v-if="GET_Dealer" />
        <div class="rightContent">
          <Menu />
        </div>
      </div>
    </template>
    <template v-else>
      <SelectDealer v-if="!GET_Dealer" />
      <template v-else>
        <GameTable />
        <ControllButtons />
      </template>
      <Chips />
      <UserStatistics />
    </template>
  </div>
</template>

<script>
import UserStatistics from "@/components/UserStatistics/";
import ControllButtons from "./ControllButtons/";
import Chips from "@/components/Chips/";
import GameTable from "@/components/GameTable/";
import Menu from "@/components/Menu/";
import SelectDealer from "@/components/SelectDealer/";

import { mapGetters } from "vuex";

export default {
  components: { ControllButtons, UserStatistics, Chips, GameTable, Menu, SelectDealer },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters(["GET_GameState", "GET_Dealer"]),
  },
  mounted: function () {
    this.$nextTick(function () {});
  },
  created() {
    if (this.GET_GameState && this.GET_GameState.state === "DEAL_DONE") {
      this.$store.dispatch("RESTORE_Bets");
    }
  },
  methods: {},
};
</script>

<style lang="less" scoped src="./component.less"></style>
