import Vue from "vue";
import store from "../..";
import { TweenMax } from 'gsap';

const state = {
    networkError: false,
    babylon: null,
    cardsList: [],
    rotatedCardNowIndex: 0,
    flipCardTimer: null,
    flipCardTime: 20,
    playerScore: 0,
    bankerScore: 0,
    winnerName: '',
    cards: {
        player: [
            { active: false, class: "first", key: 1, index: 0, type: "player" },
            { active: false, class: "second", key: 2, index: 1, type: "player" },
            { active: false, class: "third", key: 3, index: 2, type: "player" },
        ],
        banker: [
            { active: false, class: "first", key: 4, index: 0, type: "banker" },
            { active: false, class: "second", key: 5, index: 1, type: "banker" },
            { active: false, class: "third", key: 6, index: 2, type: "banker" },
        ],
    },
    statisticsTableSettings: {
        rows: 6,
        columns: 10,
    },
    needReloadPage: false,
}
const getters = {
    GET_StatisticsTableSettings: state => {
        const { rows, columns } = state.statisticsTableSettings;
        return { rows, columns, maxItems: rows * columns }
    },
    GET_History: state => {
        let from = store.getters.GET_HistoryData.length - store.getters.GET_StatisticsTableSettings.maxItems;
        const to = store.getters.GET_HistoryData.length;
        if (from < 0) from = 0;
        return store.getters.GET_HistoryData.slice(from, to);
    },
    GET_HistoryReverse: state => {
        let from = store.getters.GET_HistoryDataReverse.length - store.getters.GET_StatisticsTableSettings.maxItems;
        const to = store.getters.GET_HistoryDataReverse.length;
        if (from < 0) from = 0;
        return store.getters.GET_HistoryDataReverse.slice(from, to);
    },
    GET_WinnerName: state => {
        return state.winnerName
    },
    GET_Cards: state => {
        return state.cards
    },
    GET_PlayerScore: state => {
        return state.playerScore
    },
    GET_BankerScore: state => {
        return state.bankerScore
    },
    GET_FlipCardTime: state => {
        return state.flipCardTime
    },
    GET_CardsList: state => {
        return state.cardsList
    },
    NEED_FlitCard: state => {
        return (store.getters.GET_TableAreas.banker.total > 0 ||
            store.getters.GET_TableAreas.player.total > 0) && store.getters.GET_Settings['Squeeze On']
    },
    GET_FlitCardName: state => {
        if (store.getters.NEED_FlitCard) {
            if (store.getters.GET_TableAreas.banker.total > 0 &&
                store.getters.GET_TableAreas.player.total > 0) return 'pair';
            if (store.getters.GET_TableAreas.banker.total) return 'banker';
            return 'player';
        }
        return false;
    },
    GET_NetworkError: state => state.networkError,
    GET_Babylon: state => state.babylon,
}
const mutations = {
    SET_NetworkError: (state, payload) => {
        state.networkError = payload;
    },
    SET_NeedReloadPage: (state, payload) => {
        state.needReloadPage = payload;
    },
    SET_CardsList: (state, payload) => {
        state.cardsList = payload;
    },
    SET_WinnerName: (state, payload) => {
        state.winnerName = payload;
    },
    SET_PlayerScore: (state, payload) => {
        state.playerScore = payload
    },
    SET_BankerScore: (state, payload) => {
        state.bankerScore = payload
    },
    SET_Babylon_methods: (state, payload) => {
        state.babylon = payload;
    },
    CLEAR_FlipTimerIter: (state) => {
        clearInterval(state.flipCardTimer);
        state.flipCardTimer = null;
    },
}
const actions = {
    SET_PlayerScore({ commit }, payload) {
        commit('SET_PlayerScore', payload);
    },
    SET_BankerScore({ commit }, payload) {
        commit('SET_BankerScore', payload);
    },
    PLAY_AnimationByName({ state }, name) {
        state.babylon.PlayAnimation(name);
    },
    PLAY_NextCharacterAnimationQueue({ state, getters }, name) {
        state.babylon.nextCharacterAnimationQueue[getters.GET_Dealer].push(name)
    },
    GAME_Resume({ commit }, payload) {
        commit('SET_Controls', {
            activeTable: false,
            canShowBetAreaOutcomes: false,
            disabledRotateBtn: true,
            activeTable: false,
            canDeal: false,
            canRebet: false,
            canRotate: false,
        });
    },
    GAME_Deal({ commit, state, getters, dispatch }, payload) {
        // set bets
        const bets = {};
        let minBet, maxBet;
        let minBetError = false;
        let maxBetError = false;
        getters.GET_TableAreasList.forEach(({ total, key, min, max }) => {
            if (total) bets[Vue.prototype.$getBetsKey(key)] = total;
            if (!minBetError && total > 0 && total < min) minBetError = true, minBet = min;
            if (!maxBetError && total > 0 && total > max) maxBetError = true, maxBet = max;
        });
        // check limits
        if (minBetError) {
            Vue.prototype.$showError("", `Min position bet not reached. Limit ${getters.GET_ICurrencyFormatter.format(minBet)}`);
            return false;
        }
        if (maxBetError) {
            Vue.prototype.$showError("", `Max position bet reached. Limit ${getters.GET_ICurrencyFormatter.format(maxBet)}`);
            return false;
        }
        commit('SET_Controls', {
            activeTable: false,
            canShowBetAreaOutcomes: false,
            disabledRotateBtn: true,
            canDeal: false,
            canRebet: false,
            canRotate: false,
        });
        commit('SET_BetsData', bets);
        Vue.prototype.$game.playDeal(() => {
            dispatch('SAVE_Bets');
            dispatch('CLONE_TableAreas')
            store.dispatch('PLAY_AnimationByName', 'anim_2');
            store.dispatch('PLAY_NextCharacterAnimationQueue', 'anim_3');
        })
    },
    GAME_DealCallFirstBack({ commit, state, getters, dispatch }, payload) {
        const { playerCards, dealerCards } = getters.GET_StateCards;
        if (getters.NEED_FlitCard) {
            commit('SET_Controls', {
                rotatedCardNow: getters.GET_FlitCardName != 'banker' ? playerCards[0] : dealerCards[0],
                rotatedCardNowIndex: 0,
                canRotate: true,
            });
            dispatch('SHOW_FlipCard');
        } else {
            store.dispatch('PLAY_AnimationByName', 'anim_4')
        }
    },
    SHOW_Reload({ commit, state, getters, dispatch }, payload) {
        commit('SET_NetworkError', true)
        document.querySelector('body').style.pointerEvents = 'none';
        TweenMax.fromTo('#ReloadPage', 0.5,
            {
                opacity: 0,
                x: "-50%",
                y: 20,
                display: 'flex'
            },
            {
                opacity: 1,
                x: "-50%",
                y: 0,
                display: 'flex'
            })
    },
    INIT_FlipTimerIter({ commit, state, getters, dispatch }, payload) {
        TweenMax.to('#FlipCardLayout .timer', 0.5, { opacity: 1 });
        commit('CLEAR_FlipTimerIter');
        state.flipCardTimer = setInterval(() => {
            dispatch('GAME_FlipTimerIter')
        }, 1000);
    },
    GAME_FlipTimerIter({ commit, state, getters, dispatch }, payload) {
        if (!store.getters.GET_ConnectionLost) {
            state.flipCardTime = state.flipCardTime - 1;
            if (state.flipCardTime <= 0) {
                commit('CLEAR_FlipTimerIter');
                dispatch('GAME_FlipCard');
                setTimeout(() => {
                    state.flipCardTime = 20;
                }, 500);
            }
        }
    },
    GAME_FlipCard({ commit, state, getters, dispatch }, payload) {
        commit('CLEAR_FlipTimerIter');
        TweenMax.to("#FlipCardLayout .timer", 0.5, { opacity: 0 });
        commit('SET_Controls', {
            disabledRotateBtn: true,
            rotatedCardNowOpen: true,
        });
        setTimeout(() => {
            state.flipCardTime = 20;
            commit('SET_Controls', { rotatedCardNowOpen: false });
            if (getters.GET_FlitCardName == 'banker') { // current score
                dispatch('SHOW_CurrentBankerScore');
                state.cards.banker[state.rotatedCardNowIndex].active = true;
            } else if (getters.GET_FlitCardName == 'player') {
                dispatch('SHOW_CurrentPlayerScore');
                state.cards.player[state.rotatedCardNowIndex].active = true;
            } else {
                if (state.rotatedCardNowIndex > 2) {
                    state.cards.banker[state.rotatedCardNowIndex % 3].active = true;
                    dispatch('SHOW_CurrentBankerScore');
                } else {
                    state.cards.player[state.rotatedCardNowIndex].active = true;
                    dispatch('SHOW_CurrentPlayerScore');
                }
            }
            setTimeout(() => {
                const flip = () => {
                    const { playerCards, dealerCards } = getters.GET_StateCards;
                    commit('SET_Controls', {
                        rotatedCardNow: state.rotatedCardNowIndex + 1 > 2 ?
                            dealerCards[++state.rotatedCardNowIndex % 3] :
                            playerCards[++state.rotatedCardNowIndex],
                        disabledRotateBtn: false,
                    });
                    dispatch('INIT_FlipTimerIter');
                };
                const { thirdPlayerCard, thirdDealerCard } = getters.GET_StateCards;
                if (getters.GET_FlitCardName == 'pair') {
                    if (state.rotatedCardNowIndex + 1 == 2) {
                        if (getters.GET_ThirdCardNow) {
                            state.rotatedCardNowIndex = 5;
                        } else {
                            state.rotatedCardNowIndex++;
                        }
                        flip();
                    } else if (state.rotatedCardNowIndex + 1 == 5) {
                        dispatch('HIDE_FlipCard');
                        setTimeout(() => dispatch('PLAY_AnimationByName', 'anim_4'), 500);
                    } else if (state.rotatedCardNowIndex + 1 == 6) {
                        dispatch('HIDE_FlipCard');
                        setTimeout(() => { dispatch('DETECT_PairThirdCard') }, 500);
                    } else {
                        if (getters.GET_ThirdCardNow) {
                            if (thirdDealerCard) {
                                state.rotatedCardNowIndex = 4;
                            } else {
                                dispatch('HIDE_FlipCard');
                                setTimeout(() => { dispatch('DETECT_PairThirdCard') }, 500);
                            }
                        }
                        flip();
                    }
                } else {
                    if (state.rotatedCardNowIndex + 1 == 2) {
                        dispatch('HIDE_FlipCard');
                        setTimeout(() => dispatch('PLAY_AnimationByName', 'anim_4'), 500);
                    } else if (
                        (thirdPlayerCard || thirdDealerCard) &&
                        state.rotatedCardNowIndex + 1 == 3
                    ) {
                        dispatch('HIDE_FlipCard');
                        setTimeout(() => { dispatch('DETECT_PairThirdCard') }, 500);
                    } else {
                        const { playerCards, dealerCards } = getters.GET_StateCards;
                        commit('SET_Controls', {
                            rotatedCardNow: getters.GET_FlitCardName == 'player' ?
                                playerCards[++state.rotatedCardNowIndex] :
                                dealerCards[++state.rotatedCardNowIndex],
                            disabledRotateBtn: false,
                        });
                        dispatch('INIT_FlipTimerIter');
                    }
                }
            }, 500);
        }, 1250)
    },
    RESET_PlayerCards({ state }) {
        state.cards.player[0].active = false;
        state.cards.player[1].active = false;
        state.cards.player[2].active = false;
    },
    RESET_BankerCards({ state }) {
        state.cards.banker[0].active = false;
        state.cards.banker[1].active = false;
        state.cards.banker[2].active = false;
    },

    DETECT_PairThirdCard({ commit, state, getters, dispatch }, showThirdCard = false) {
        const { thirdPlayerCard, thirdDealerCard } = getters.GET_StateCards;
        if (thirdPlayerCard && thirdDealerCard) {
            store.dispatch('PLAY_AnimationByName', 'anim_5A')
        } else if (thirdPlayerCard) {
            store.dispatch('PLAY_AnimationByName', 'anim_5P')
        } else if (thirdDealerCard) {
            store.dispatch('PLAY_AnimationByName', 'anim_5B')
        } else {
            store.dispatch('SHOW_Results')
        }
        // getters.GET_CardsList[4].setEnabled(thirdPlayerCard);
        // getters.GET_CardsList[6].setEnabled(thirdPlayerCard);
        // getters.GET_CardsList[5].setEnabled(thirdDealerCard);
        // getters.GET_CardsList[7].setEnabled(thirdDealerCard);
    },
    DETECT_PlayerThirdCard({ commit, state, getters, dispatch }) {
        const { thirdPlayerCard, thirdDealerCard, playerCards } = getters.GET_StateCards;
        if (thirdPlayerCard) {
            commit('SET_Controls', {
                rotatedCardNow: playerCards[2],
                rotatedCardNowIndex: 2,
                canRotate: true,
            });
            dispatch('SHOW_FlipCard');
        } else {
            if (thirdDealerCard) {
                store.dispatch('PLAY_AnimationByName', 'anim_5B')
            } else {
                store.dispatch('SHOW_Results')
            }
        }
        // getters.GET_CardsList[4].setEnabled(thirdPlayerCard);
        // getters.GET_CardsList[6].setEnabled(thirdPlayerCard);
    },
    DETECT_DealerThirdCard({ commit, state, getters, dispatch }) {
        const { thirdDealerCard, thirdPlayerCard, dealerCards } = getters.GET_StateCards;
        if (thirdDealerCard) {
            commit('SET_Controls', {
                rotatedCardNow: dealerCards[2],
                rotatedCardNowIndex: 2,
                canRotate: true,
            });
            dispatch('SHOW_FlipCard');
        } else {
            if (thirdPlayerCard) {
                store.dispatch('PLAY_AnimationByName', 'anim_5P')
            } else {
                store.dispatch('SHOW_Results')
            }
        }
        // getters.GET_CardsList[5].setEnabled(thirdDealerCard);
        // getters.GET_CardsList[7].setEnabled(thirdDealerCard);
    },
    SHOW_Results({ commit, state, getters, dispatch }) {
        commit('CLEAR_FlipTimerIter');
        commit('SET_Controls', { disabledRotateBtn: true, rotatedCardNowIndex: 0, thirdCardNow: false });
        const playerScore = getters.GET_GameState.playerCards[getters.GET_GameState.playerCards.length - 1].handScore;
        const bankerScore = getters.GET_GameState.dealerCards[getters.GET_GameState.dealerCards.length - 1].handScore;
        dispatch('SET_PlayerScore', playerScore);
        dispatch('SET_BankerScore', bankerScore);
        dispatch('ShowHideWinner', { show: true, callBack: true });
        Vue.prototype.$game.gameResolve();
    },
    SET_PairCards({ commit, state, getters, dispatch }, showThirdCard = false) {
        const { thirdPlayerCard, thirdDealerCard } = getters.GET_StateCards;
        // player
        state.cards.player[0].active = true;
        state.cards.player[1].active = true;
        if (showThirdCard && thirdPlayerCard) state.cards.player[2].active = true;
        // banker
        state.cards.banker[0].active = true;
        state.cards.banker[1].active = true;
        if (showThirdCard && thirdDealerCard) state.cards.banker[2].active = true;
        commit('SET_Controls', { rotatedCardNowIndex: showThirdCard ? 2 : 1 });
        dispatch('SHOW_CurrentPlayerScore', showThirdCard); // current score
        dispatch('SHOW_CurrentBankerScore', showThirdCard); // current score
        if (!showThirdCard) dispatch('DETECT_PairThirdCard')
    },
    SET_PlayerCards({ commit, state, getters, dispatch }, params = []) {
        const [force, showThirdCard] = params;
        const { playerCards, thirdPlayerCard } = getters.GET_StateCards;
        if (force) {
            state.cards.player[0].active = true;
            state.cards.player[1].active = true;
        }
        if (thirdPlayerCard && showThirdCard) state.cards.player[2].active = true;
    },
    SHOW_CurrentPlayerScore({ commit, state, getters, dispatch }, third = false) {
        const cardsCount = getters.GET_StateCards.playerCards.length;
        const score = getters.GET_StateCards.playerCards[
            third ? cardsCount - 1 : (state.rotatedCardNowIndex % 3 < cardsCount ? state.rotatedCardNowIndex % 3 : cardsCount - 1)
        ].handScore;
        commit('SET_PlayerScore', score)
    },
    SHOW_CurrentBankerScore({ commit, state, getters, dispatch }, third = false) {
        const cardsCount = getters.GET_StateCards.dealerCards.length;
        const score = getters.GET_StateCards.dealerCards[
            third ? cardsCount - 1 : (state.rotatedCardNowIndex % 3 < cardsCount ? state.rotatedCardNowIndex % 3 : cardsCount - 1)
        ].handScore;
        commit('SET_BankerScore', score)
    },
    SET_BankerCards({ commit, state, getters, dispatch }, params = []) {
        const [force, showThirdCard] = params;
        const { thirdDealerCard, dealerCards } = getters.GET_StateCards;
        if (force) {
            state.cards.banker[0].active = true;
            state.cards.banker[1].active = true;
        }
        if (thirdDealerCard && showThirdCard) state.cards.banker[2].active = true;
        store.dispatch('SHOW_CurrentBankerScore', showThirdCard); // current Banker score
    },

    CONVERT_CardTextureData({ state }, [index, card]) {
        state.babylon.ConvertCardTextureData(index, card);
    },
    CONVERT_CardsTextureData({ state, getters }) {
        // set card textures
        const { playerCards, thirdPlayerCard, dealerCards, thirdDealerCard } = getters.GET_StateCards;
        // player
        state.babylon.ConvertCardTextureData(2, playerCards[0].card);
        state.babylon.ConvertCardTextureData(0, playerCards[1].card);
        if (thirdPlayerCard) {
            state.babylon.ConvertCardTextureData(4, playerCards[2].card);
        }
        // dealer
        state.babylon.ConvertCardTextureData(3, dealerCards[0].card);
        state.babylon.ConvertCardTextureData(1, dealerCards[1].card);
        if (thirdDealerCard) {
            state.babylon.ConvertCardTextureData(5, dealerCards[2].card);
        }
    },
    HIDE_FlipCard({ commit, state, getters, dispatch }, payload) {
        commit('CLEAR_FlipTimerIter');
        TweenMax.to('#FlipCardLayout .timer', 0.25, { opacity: 0 })
        TweenMax.to('#FlipCardLayout .card', 0.5, { opacity: 0, y: 100 }).delay(0.25)
        TweenMax.fromTo('#FlipCardLayout', 1, { opacity: 1 }, {
            opacity: 0, display: 'none'
        })
    },
    SHOW_FlipCard({ commit, state, getters, dispatch }, payload) {
        commit('SET_Controls', {
            disabledRotateBtn: true,
            canDeal: false,
            canRebet: false,
            canRotate: true,
            showUserCards: true,
        });
        TweenMax.fromTo('#FlipCardLayout', 1, { opacity: 0, display: 'block' }, { opacity: 1 })
        setTimeout(() => {
            TweenMax.fromTo('#FlipCardLayout .card', 0.5, { opacity: 0, y: 100 }, { opacity: 1, y: 0 }).delay(0.4)
        }, 100);
        TweenMax.fromTo('#FlipCardLayout .timer', 0.5, { opacity: 0 }, {
            opacity: 1, onComplete: () => {
                commit('SET_Controls', { disabledRotateBtn: false });
                dispatch('INIT_FlipTimerIter');
            }
        }).delay(1)
    },
    DETECT_Winner({ state, getters, commit, dispatch }) {
        const { GET_PlayerScore, GET_BankerScore } = getters;
        if (GET_PlayerScore == GET_BankerScore) {
            commit('SET_WinnerName', 'TIE');
            dispatch('PLAY_AnimationByName', 'anim_8');
        } else {
            commit('SET_WinnerName', (GET_PlayerScore > GET_BankerScore ? 'Player' : 'Banker') + ' Wins');
            dispatch('PLAY_AnimationByName', GET_PlayerScore > GET_BankerScore ? 'anim_6' : 'anim_7');
        }
    },
    GAME_DoneCallback({ commit, dispatch }) {
        Vue.prototype.$game.playInit(() => {
            dispatch("GET_History", true);
            commit('SET_PlayerScore', 0);
            commit('SET_BankerScore', 0);
            commit('SET_Controls', {
                disabledRotateBtn: true,
                canShowBetAreaOutcomes: true,
                showUserCards: false,
                canRotate: false,
                canRebet: true,
                activeTable: true,
                canDeal: false
            });
        });
    },
    ShowHideWinner({ state, dispatch }, { show = true, callBack = true }) {
        if (show) dispatch('DETECT_Winner');
        TweenMax.fromTo('#winner', 0.5,
            {
                opacity: show ? 0 : 1,
                y: show ? 20 : 0,
                display: 'flex'
            },
            {
                opacity: show ? 1 : 0,
                y: show ? 0 : 20,
                display: show ? 'flex' : 'none',
                onComplete: () => {
                    if (callBack) {
                        setTimeout(() => {
                            dispatch('ShowHideWinner', {
                                show: false,
                                callBack: false,
                            });
                        }, 2000);
                    }
                }
            })
    },
};
export default {
    state, getters, mutations, actions
}