import Vue from "vue";
import { TweenMax } from 'gsap';
import store from '../../'

const documentClick = (e) => {
    if (e.target.closest("#MenuContent") == null) store.dispatch('SHOW_HIDE_Menu');
};


const state = {
    menuOpen: false,
    menuOpenClass: false,
    menuOpenInProgress: false,
    menuSecondLevel: false,
    defaultSettings: {
        'Volume': 50,
        'Audio': true,
        'Background music': true,
        'Squeeze On': true,
    },
};

const getters = {
    GET_MenuIsOpen: state => {
        return state.menuOpen;
    },
    GET_MenuIsOpenClass: state => {
        return state.menuOpenClass;
    },
    GET_MenuSecondLevel: state => {
        return state.menuSecondLevel
    },
    GET_Settings: (state, getters) => {
        let clearObj = {};
        const storageData = getters.GET_GameState.storageData;
        for (const key in storageData) {
            if (Object.hasOwnProperty.call(storageData, key)) {
                if (!key.includes('history')) {
                    const value = storageData[key];
                    clearObj[key] = (() => {
                        if (key == 'model') {
                            return value
                        } else if (!isNaN(parseInt(value))) { // number
                            return parseInt(value);
                        } else {
                            return (value == 'true' || value == true) ? true : false
                        };
                    })();
                }
            }
        }
        return clearObj;
    },
    GET_DefaultSettings: state => state.defaultSettings,
};

const mutations = {
    SET_MenuSecondLevel: (state, payload) => {
        state.menuSecondLevel = payload
    },
    SET_MenuOpen: (state, payload) => {
        state.menuOpen = payload
    },
    SET_MenuOpenClass: (state, payload) => {
        state.menuOpenClass = payload
    },
    SET_OneSettings: (state, { name, value, callback }) => {
        store.state.gameState.storageData[name] = value
        if (callback) store.dispatch('CHANGE_Setting', { name, value })
    },
    SET_Settings: (state, payload) => {
        store.state.gameState.storageData = payload
    },
    UPDATE_Settings: (state, payload) => {
        store.state.gameState.storageData = { ...store.state.gameState.storageData, ...payload }
    },
    SET_DefaultSettings: (state, { name, value }) => {
        state.defaultSettings[name] = value
    },
};

const actions = {
    SET_DefaultSettings: ({ commit }, { name, value }) => {
        commit('SET_DefaultSettings', { name, value })
    },
    SHOW_MenuSecondLevel: async ({ state, commit, getters }, name) => {
        const secondLevelItems = document.querySelectorAll('#MenuContentLevel2 .secondLevelItem');
        if (secondLevelItems != null) {
            secondLevelItems.forEach(el => el.style.display = 'none');
            const current = document.querySelector(`#MenuContentLevel2 .secondLevelItem[data-name='${name}']`);
            if (current != null) {
                current.style.display = 'block';
                current.classList.add('active')
                const height = current.getBoundingClientRect().height;
                const time = 0.25;
                commit('SET_MenuSecondLevel', true);
                TweenMax.to('#MenuContentWrap', time, { height, ease: 'Power0.easeNone' });
                TweenMax.fromTo('#MenuContentLevel1', time, { x: "0%" }, { x: "-100%", ease: 'Power2.easeInOut' });
                TweenMax.fromTo('#MenuContentLevel2', time, { x: "100%" }, { x: "0%", ease: 'Power2.easeInOut' });
            }
        }
    },
    HIDE_MenuSecondLevel: ({ state, commit, getters }, time = 0.25) => {
        TweenMax.to('#MenuContentLevel1', time, { x: 0, ease: 'Power2.easeInOut' });
        TweenMax.to('#MenuContentLevel2', time, { x: "100%", ease: 'Power2.easeInOut' });
        const MenuContentLevel1 = document.getElementById('MenuContentLevel1');
        const height = MenuContentLevel1.getBoundingClientRect().height;
        TweenMax.to('#MenuContentWrap', time, { height, ease: 'Power0.easeNone' });
        commit('SET_MenuSecondLevel', false);
        const secondLevelItems = document.querySelectorAll('#MenuContentLevel2 .secondLevelItem');
        if (secondLevelItems != null) {
            secondLevelItems.forEach(element => {
                element.classList.remove('active')
            });
        }
    },
    SHOW_HIDE_Menu: ({ state, commit, getters, dispatch }, important = false) => {
        if (state.menuOpenInProgress && !important) return false;
        state.menuOpenInProgress = true;
        const { GET_MenuIsOpen: open } = getters;
        const shift = 15;
        if (!open) {
            commit('SET_MenuOpen', true);
            setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 100);
        };
        commit('SET_MenuOpenClass', !open);
        TweenMax.fromTo('#MenuContent', 0.25,
            { y: open ? 0 : shift, opacity: open ? 1 : 0 },
            {
                y: open ? shift : 0, opacity: open ? 0 : 1, onComplete: () => {
                    document[open ? 'removeEventListener' : 'addEventListener']("click", documentClick, false);
                    document[open ? 'removeEventListener' : 'addEventListener']("tap", documentClick, false);
                    document[open ? 'removeEventListener' : 'addEventListener']("touchend", documentClick, false);
                    commit('SET_MenuOpen', !open)
                    commit('SET_MenuOpenClass', !open)
                    state.menuOpenInProgress = false;
                    if (open && getters.GET_MenuSecondLevel) dispatch('HIDE_MenuSecondLevel', 0)
                }
            }
        );
    },
    UPDATE_OneSetting: ({ state, commit, getters, dispatch }, name) => {
        const value = !store.state.gameState.storageData[name];
        commit('SET_OneSettings', { name, value })
        dispatch('CHANGE_Setting', { name, value })
        Vue.prototype.$game.setStorage(store.state.gameState.storageData)
    },
    UPDATE_Settings: ({ state, commit, getters, dispatch }, setStore = false) => {
        const settings = { ...getters.GET_DefaultSettings, ...getters.GET_Settings };
        commit('SET_Settings', settings)
        Vue.prototype.$game.setStorage(settings)
    },
};

export default {
    state, getters, mutations, actions
}