<template>
  <div>
    <table class="statisticsTable BigRoadTable">
      <tbody>
        <tr v-for="(row, rowIndex) in sliceTableData" :key="name + '_row_' + rowIndex">
          <td
            v-for="(column, columnIndex) in row"
            :key="name + '_column_' + columnIndex"
            :style="'width:' + 100 / sliceTableData[0].length + '%'"
          >
            <!-- {{ rowIndex }} - {{ columnIndex }} -->
            <span
              :class="[
                column.winner,
                { pairBanker: column.pairBanker },
                { pairPlayer: column.pairPlayer },
                { isNatural: column.isNatural },
              ]"
              v-if="column"
            >
              <span class="tieLine" v-if="column.tie"></span>
              <span class="natural" v-if="column.isNatural"></span>
              <u
                class="countPrivTie"
                v-if="column.countPrivTie && column.countPrivTie > 1"
                >{{ column.countPrivTie }}</u
              >
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BigRoadTable",
  data() {
    return {
      name: "BigRoadTable",
      maxRow: 6,
      table: null,
      prevWinnerObj: {},
      rowIndex: 0,
      columnIndex: 0,
      changeWinnerCount: 0,
      bigRoadColumns: [],
      bigRoadList: [],
    };
  },
  computed: {
    ...mapGetters([
      "GET_AllHistoryaReverse",
      "GET_AllHistory",
      "GET_StatisticsTableSettings",
    ]),
    history() {
      return this.GET_AllHistoryaReverse.map((element, index, arr) => {
        const { score, winner, isNatural, tie } = this.getHistoryInfo(element);
        let prevTie = false;
        let winnerBeforeTie = null;
        const difference = (() => {
          if (index > 0) {
            const elBeforeTie = this.getElBeforeTie(index);
            const { winner: winnerPrev, tie: tiePrev } = this.getHistoryInfo(
              arr[index - 1]
            );

            if (tiePrev) {
              prevTie = true;
              if (elBeforeTie) {
                const { winner: winnerBeforeTieTemp } = elBeforeTie;
                winnerBeforeTie = elBeforeTie;
                if (tie) return false;
                return winnerBeforeTieTemp != winner;
              } else {
                return false;
              }
            }

            if (tiePrev == tie && tie == true) return false;
            if (tie && !prevTie) return false;
            return winnerPrev != winner || (prevTie && !tie);
          }
          return false;
        })();

        return {
          index,
          tie,
          score,
          winner,
          element,
          prevTie,
          isNatural,
          difference,
          pairBanker: this.getPair("BANKER", element),
          pairPlayer: this.getPair("PLAYER", element),
          winnerBeforeTie,
        };
      });
    },
    tableData() {
      // let onceTailFix = false;
      if (this.history && this.history.length) {
        this.history.forEach((historyElemInit, index, arr) => {
          const historyElem = { ...historyElemInit };

          const { tie, prevTie, difference, winnerBeforeTie } = historyElem;
          // historyElem.index = index;
          if (difference) {
            this.rowIndex = 0;
            this.columnIndex = ++this.changeWinnerCount;
          }
          historyElem.column = this.changeWinnerCount;
          this.history[index].column = this.changeWinnerCount;
          if (!this.bigRoadColumns[this.changeWinnerCount])
            this.bigRoadColumns[this.changeWinnerCount] = {
              count: 0,
              tail: 0,
              items: [],
            };
          if (tie) {
            if (index > 0) {
              const { pairPlayer, pairBanker, winner, isNatural } = winnerBeforeTie
                ? winnerBeforeTie
                : arr[index - 1];
              if (this.rowIndex - 1 >= 0) this.rowIndex--;

              if (
                this.table[this.rowIndex + 1][this.columnIndex - 1] &&
                this.table[this.rowIndex + 1][this.columnIndex - 1].column ==
                  historyElem.column
              ) {
                this.rowIndex++;
                this.columnIndex--;
              }

              this.table[this.rowIndex][this.columnIndex] = historyElem;
              this.table[this.rowIndex][this.columnIndex].pairPlayer = pairPlayer;
              this.table[this.rowIndex][this.columnIndex].pairBanker = pairBanker;
              this.table[this.rowIndex][this.columnIndex].winner = winner;
              this.table[this.rowIndex][this.columnIndex].isNatural = isNatural;
              this.table[this.rowIndex++][
                this.columnIndex
              ].countPrivTie = this.calcPrevTie(historyElem, index, arr);
              if (this.rowIndex == this.maxRow) {
                this.rowIndex--;
                this.columnIndex++;
              }
            } else {
              this.table[this.rowIndex][this.columnIndex] = historyElem;
            }
          } else {
            this.bigRoadColumns[this.changeWinnerCount].count++;
            this.bigRoadColumns[this.changeWinnerCount].items.push(historyElem);
            if (prevTie) {
              if (this.getElBeforeTie(index)) {
                this.table[this.rowIndex++][this.columnIndex] = historyElem;
              } else {
                const { pairPlayer, pairBanker, isNatural, tie } = this.history[
                  index - 1
                ];
                if (this.rowIndex - 1 < 0) this.rowIndex = 1;
                this.table[this.rowIndex - 1][this.columnIndex] = historyElem;
                this.table[this.rowIndex - 1][this.columnIndex].pairPlayer = pairPlayer;
                this.table[this.rowIndex - 1][this.columnIndex].pairBanker = pairBanker;
                this.table[this.rowIndex - 1][this.columnIndex].tie = tie;
                this.table[this.rowIndex - 1][this.columnIndex].isNatural = isNatural;
                this.table[this.rowIndex - 1][
                  this.columnIndex
                ].countPrivTie = this.calcPrevTie(historyElem, index, arr, false);
              }
              if (index - 1 == 0 && arr[index - 1].tie) {
                this.table[this.rowIndex - 1][this.columnIndex].tie = true;
              }
              if (this.rowIndex == this.maxRow) {
                this.rowIndex--;
                this.columnIndex++;
              }
            } else {
              if (this.hasNextRow(this.rowIndex, this.columnIndex, historyElem)) {
                // console.log("else-3", this.rowIndex, this.columnIndex);
                if (
                  index &&
                  this.history[index - 1].isTail &&
                  !this.history[index - 1].tie &&
                  this.history[index - 1].column == historyElem.column
                ) {
                  historyElem.isTail = true;
                  this.history[index].isTail = true;
                  this.bigRoadColumns[this.changeWinnerCount].tail++;
                }
                this.table[this.rowIndex++][this.columnIndex] = historyElem;
              } else {
                // console.log("else-4", this.rowIndex, this.columnIndex);
                this.setNextDragonCell(historyElem, index);
              }
            }
          }
          this.setHistoryElemPos(index);
          this.bigRoadList.push(historyElem);
        });
      }
      return this.table;
    },
    sliceTableData() {
      let needSliceColumns = false;
      let maxColumnsInRow = 0;
      const columns = this.GET_StatisticsTableSettings.columns;
      this.tableData.forEach((row) => {
        if (row.length > columns) needSliceColumns = true;
        if (row.length > maxColumnsInRow) maxColumnsInRow = row.length;
      });

      let newTableData = this.tableData.slice();
      if (needSliceColumns) {
        newTableData = newTableData.map((row) => {
          const newRow = [];
          for (let index = 0; index < maxColumnsInRow; index++) {
            newRow[index] = row[index];
          }
          return newRow.slice(newRow.length - columns);
        });
      }
      newTableData = newTableData.slice(0, this.maxRow);
      return newTableData;
    },
  },
  methods: {
    setHistoryElemPos(index) {
      let column;
      let row = this.table.findIndex((row, indexRow) => {
        const columnIter = row.findIndex((el) => el && el.index == index);
        if (columnIter >= 0) column = columnIter;
        return columnIter >= 0;
      });
      this.table[row][column].row = row;
      // this.table[row][column].column = column;
      return { row, column };
    },
    setNextDragonCell(historyElem, index) {
      // last cell in column is empty
      if (!historyElem.tie) {
        historyElem.isTail = true;
        this.history[index].isTail = true;
        this.bigRoadColumns[this.changeWinnerCount].tail++;
      }
      // if ( !historyElem.tie && this.history[index + 1] && !this.history[index + 1].tie && !this.history[index + 1].difference) this.history[index + 1].isTail = true;

      if (
        this.table[this.rowIndex - 1][this.columnIndex] &&
        this.table[this.rowIndex - 1][this.columnIndex].winner == historyElem.winner &&
        this.table[this.rowIndex][this.columnIndex - 1] &&
        this.table[this.rowIndex][this.columnIndex - 1].winner == historyElem.winner
      ) {
        this.table[--this.rowIndex][++this.columnIndex] = historyElem;
        this.rowIndex++;
        return;
      }
      this.table[this.rowIndex][this.columnIndex++] = historyElem;
      return;
    },
    hasNextColumn() {
      return this.table[this.rowIndex][this.columnIndex + 1] == undefined;
    },
    hasNextRow() {
      if (
        this.rowIndex + 1 >= this.maxRow ||
        this.table[this.rowIndex + 1][this.columnIndex]
      )
        return false;
      return true;
    },
    calcPrevTie(historyElem, realIndex, arr, addCount = true) {
      let index = realIndex;
      let count = 0;
      const recursive = (index, arr) => {
        if (arr[index].prevTie) {
          count++;
          if (index > 0) recursive(index - 1, arr);
        }
      };
      if (historyElem.prevTie) {
        if (addCount) count++;
        recursive(index, arr);
      }
      return count;
    },
    getPair(name, history) {
      const cards = history.resultTable[name].cards.split(", ");
      if (cards[0][0] === cards[1][0]) return true;
      // if (
      //   cards[0][0] === cards[1][0] ||
      //   (cards[2] != undefined &&
      //     (cards[1][0] === cards[2][0] || cards[0][0] === cards[2][0]))
      // )
      //   return true;
      return false;
    },
    getElBeforeTie(mainIndex) {
      let elem = undefined;
      const func = (index) => {
        const { score, winner, isNatural, tie } = this.getHistoryInfo(
          this.GET_AllHistoryaReverse[index]
        );
        if (tie && index > 0) {
          func(index - 1);
        } else if (!tie) {
          elem = { score, winner, isNatural, tie, index };
        }
      };
      func(mainIndex - 1);
      return elem;
    },
    getHistoryInfo(history) {
      const { thirdDealerCard, thirdPlayerCard } = history;
      const totalDealerScore = parseInt(history.totalDealerScore);
      const totalPlayerScore = parseInt(history.totalPlayerScore);
      const score =
        totalDealerScore > totalPlayerScore ? totalDealerScore : totalPlayerScore;
      let winner = totalDealerScore > totalPlayerScore ? "banker" : "player";
      if (totalDealerScore == totalPlayerScore) winner = "tie";
      const tie = winner === "tie";
      const isNatural = (() => {
        if (thirdDealerCard == "" && thirdPlayerCard == "") {
          if (
            totalDealerScore > totalPlayerScore &&
            (totalDealerScore == 9 || totalDealerScore == 8)
          )
            return true;
          if (
            totalPlayerScore > totalDealerScore &&
            (totalPlayerScore == 9 || totalPlayerScore == 8)
          )
            return true;
        }
        return false;
      })();
      return { score, winner, isNatural, tie };
    },
  },
  created() {
    const rows = new Array(this.GET_StatisticsTableSettings.rows);
    for (let index = 0; index < rows.length; index++) {
      rows[index] = new Array(this.GET_StatisticsTableSettings.columns);
    }
    this.table = rows;
  },
  mounted() {
    this.$store.dispatch("SET_BigRoad", {
      table: this.table,
      list: this.bigRoadList,
      columns: this.bigRoadColumns,
    });
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
