import MyBabylon from '../index'
import store from "@/store/";


import { SelectDealerScene, SetDefaultMobileDealer } from './index'
import { SetCamera, MoveCameraToDealer } from './camera'

let responsiveNow;
const responsiveSteps = [0, 620];

const getResponsive = (responsive, window_width = window.innerWidth) => {
    let prev = false;
    let i;
    let responsiveArr = responsive.slice();
    responsiveArr.push(99999999)
    let exit = false;
    responsiveArr.forEach((element, index) => {
        if (!exit) {
            if (prev !== false && window_width <= element && window_width > prev) {
                i = index - 1;
                exit = true;
            } else {
                prev = element;
            }
        }
    });

    // console.log('responsive = ' + responsiveArr);
    // console.log(responsive[i]);
    return responsiveArr[i];
};

const checkResizeInit = () => {
    responsiveNow = getResponsive(responsiveSteps);
    // checkResizeDo(responsiveNow);
    window.addEventListener("resize", () => {
        if (responsiveNow != getResponsive(responsiveSteps)) {
            responsiveNow = getResponsive(responsiveSteps);
            checkResizeDo(responsiveNow);
        }
    });
}
const checkResizeDo = (responsiveNow) => {
    switch (responsiveNow) {
        case 0:
            SelectDealerScene(false);
            mobile()
            break;
        case 620:
            SelectDealerScene(false);
            desktop()
            break;
        default:
            break;
    }
}



const mobile = () => {
    MyBabylon.camera.position.x = 0;
    if (!store.getters.GET_TempDealer) SetDefaultMobileDealer();
    if (!store.getters.GET_Dealer) MoveCameraToDealer();
    SetCamera();
}

const desktop = () => {
    MyBabylon.camera.position.x = 0;
}


export { checkResizeInit }