<template>
  <div class="Canvas">
    <div id="fps" v-show="showFPS">0</div>
    <canvas id="renderCanvas"></canvas>
    <button
    v-if="GET_ShowUnmuteIcon"
      class="babylonUnmuteIcon"
      title="Unmute"
      style="top: 20px; left: 20px"
      @click="$store.dispatch('ENABLE_Sounds', true)"
    ></button>
  </div>
</template>

<script>
import Babylon from "@/babylon/";
import { mapGetters } from "vuex";

export default {
  name: "Canvas",
  computed: {
    ...mapGetters(["GET_ShowUnmuteIcon"]),
    showFPS() {
      return process.env.develop;
    },
  },
  mounted: function () {
    this.$store.commit("SET_Babylon_methods", {
      nextCharacterAnimationQueue: Babylon.nextCharacterAnimationQueue,
      ConvertCardTextureData: Babylon.ConvertCardTextureData,
      ResetCardAnimation: Babylon.ResetCardAnimation,
      Animate_Dealer: Babylon.Animate_Dealer,
      init3dGame: Babylon.init3dGame.bind(Babylon),
      ClearDealerScene: Babylon.ClearDealerScene,
      PlayAnimation: Babylon.PlayAnimation,
      SelectDealerScene: Babylon.SelectDealerScene,
      ReconnectCallback: Babylon.ReconnectCallback.bind(Babylon),
    });
    this.$store.commit("SET_Babylon_sound", Babylon.sound);
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
