export default {
    install(Vue) {
        Vue.prototype.$showError = (title = "", text = "") => {
            Vue.prototype.$notify({
                type: "warn",
                group: "foo",
                title: title,
                text: `<img src="${require('@/assets/img/notify.svg')}" />` + text,
                duration: 3000,               
                ignoreDuplicates: true
            });

        }
    }
}