import Vue from "vue";
const state = {
    tableAreasClone: null,
    tableAreas: {
        player_pair: {
            top: true,
            title: "Player <br/> pair",
            symbol: null,
            bets: [],
            total: 0,
        },
        big: {
            top: true,
            title: "Big",
            symbol: null,
            bets: [],
            total: 0,
        },
        small: {
            top: true,
            title: "Small",
            symbol: null,
            bets: [],
            total: 0,
        },
        banker_pair: {
            top: true,
            title: "Banker <br/> pair",
            symbol: null,
            bets: [],
            total: 0,
        },
        player: {
            top: false,
            title: "player",
            symbol: "player.svg",
            bets: [],
            total: 0,
        },
        tie: {
            top: false,
            title: "TIE",
            symbol: "tie.svg",
            bets: [],
            total: 0,
        },
        lucky_6: {
            top: false,
            no_commission: true,
            title: "Lucky <span>6</span>",
            symbol: null,
            bets: [],
            total: 0,
        },
        banker: {
            top: false,
            title: "banker",
            symbol: "banker.svg",
            bets: [],
            total: 0,
        },
    },
}
state.tableAreasClone = JSON.parse(JSON.stringify(state.tableAreas)); //clone

const getters = {
    GET_TableAreas: state => {
        return state.tableAreas;
    },
    GET_TableAreasList: (state, { GET_TableAreas, IS_NO_COMMISSION_BACCARAT, GET_BetTypeLimits }) => {
        const list = [];
        const betTypeLimits = Object.entries(GET_BetTypeLimits);
        for (const key in GET_TableAreas) {
            if (Object.hasOwnProperty.call(GET_TableAreas, key)) {
                const element = GET_TableAreas[key];
                const currentLimitEl = betTypeLimits.find((el) => el[1].betTypes.includes(Vue.prototype.$getBetsKey(key)))
                const { min, max } = currentLimitEl[1];
                if (!IS_NO_COMMISSION_BACCARAT && !element.no_commission || IS_NO_COMMISSION_BACCARAT) {
                    list.push({ key, ...element, min, max })
                }
            }
        };
        return list;
    },
    GET_TableAreasTop: (state, { GET_TableAreasList }) => {
        return GET_TableAreasList.filter(el => el.top);
    },
    GET_TableAreasBottom: (state, { GET_TableAreasList }) => {
        return GET_TableAreasList.filter(el => !el.top);
    },
}
const mutations = {
    SET_TableAreasClone(state, payload = {}) {
        state.tableAreasClone = JSON.parse(JSON.stringify(payload))
    },
    SET_CurrentChips(state, payload) {
        state.currentChips = payload
    },
    PUSH_TableAreas(state, { key, bet }) {
        const area = state.tableAreas[key];
        area.bets.push(bet);
        area.total = area.bets.reduce(
            (sum, current) => parseFloat((sum + current.val).toFixed(10)),
            0
        );
    },
    CLEAR_All_TableArea(state) {
        for (const key in state.tableAreas) {
            if (Object.hasOwnProperty.call(state.tableAreas, key)) {
                const area = state.tableAreas[key];
                area.bets = [];
                area.total = 0;
            }
        }
    },
    СLEAR_TableArea(state, key) {
        const area = state.tableAreas[key];
        area.bets = [];
        area.total = 0;
    },
    СLEAR_AllTableArea(state, key) {
        for (const key in state.tableAreas) {
            if (Object.hasOwnProperty.call(state.tableAreas, key)) {
                const area = state.tableAreas[key];
                area.bets = [];
                area.total = 0;
            }
        }
    },
}
const actions = {
    CLONE_TableAreas({ commit, state }) {
        commit('SET_TableAreasClone', state.tableAreas)
    },
    SET_CurrentChips({ commit }, payload) {
        commit('SET_CurrentChips', payload)
    },
    PUSH_TableAreas({ commit, getters }, key) {
        const bet = getters.GET_CurrentChips;
        commit('PUSH_TableAreas', { key, bet });
        commit('SET_Controls', {
            canDeal: true,
            canRebet: false,
            canRotate: false,
            canShowBetAreaOutcomes: false,
        });
    },
    СLEAR_TableArea({ commit, getters }, key) {
        commit('СLEAR_TableArea', key)
        let hasBets = getters.GET_TableAreasList.find(({ bets }) => bets.length)
        if (!hasBets) commit('SET_Controls', { canDeal: false })
    },
    CLEAR_All_TableArea({ commit, getters }, key) {
        commit('CLEAR_All_TableArea')
    },
    RESET_Bets({ commit }) {
        commit('СLEAR_AllTableArea');
        commit('SET_Controls', { canDeal: false });
    },
};
export default {
    state, getters, mutations, actions
}