<template>
  <div class="SettingsItem" :class="{ inMobileMenu: inMobileMenu }">
    <div class="wrap" @click="changeSetting">
      <span>{{ name }}</span>
      <div class="switcher" :class="{ active: active }">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SettingsItem",
  props: {
    name: { default: "" },
    activeDefault: { default: false },
    inMobileMenu: { default: false },
  },
  computed: {
    ...mapGetters(["GET_Settings"]),
    active() {
      return (
        this.GET_Settings[this.name] == "true" || this.GET_Settings[this.name] == true
      );
    },
  },
  created() {
    this.SET_DefaultSettings({ name: this.name, value: this.activeDefault });
  },
  methods: {
    ...mapActions(["UPDATE_OneSetting", "SET_DefaultSettings"]),
    changeSetting() {
      this.UPDATE_OneSetting(this.name);
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
