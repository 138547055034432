import device from "current-device";
if (device.mobile() || device.tablet()) store.commit('SET_isMobile', true);

import initGameParams from './initGameParams.js'

import Vue from "vue";
import App from "./App.vue";
import store from "./store";

// write game version in window variable
import { version, name } from '../package.json'
window.game = { version, name }


import axios from "./plugins/axios.js"
import game from "./plugins/game.js"
import getBetsKey from "./plugins/getBetsKey.js"
import Notifications from 'vue-notification'
import showError from "./plugins/showError.js"
import popup from "./plugins/popup.js"
import crypto from "./plugins/crypto.js"
import functions from "./plugins/functions.js"

import LoadingIcon from '@/components/LoadingIcon.vue'
Vue.component('LoadingIcon', LoadingIcon)

import ScrollBar from '@morioh/v-smooth-scrollbar'
Vue.use(ScrollBar);


initGameParams(); // set params for game

Vue.use(axios, { store })
Vue.use(game, { store, axios })
Vue.use(Notifications)

Vue.use(LoadingIcon)

Vue.use(getBetsKey)
Vue.use(showError)
Vue.use(popup, { store })
Vue.use(crypto)
Vue.use(functions, { store })

Vue.config.productionTip = false;

window.vue = new Vue({
  store,
  render: h => h(App)
}).$mount("#app");

document.addEventListener("visibilitychange", (event) => {
  if (document.visibilityState == "visible") {
    console.log("tab is active");
    store.dispatch('UNMUTE_Sounds')
  } else {
    console.log("tab is inactive");
    store.dispatch('MUTE_Sounds')
  }
});