<template>
  <div class="Chips" id="chips">
    <div class="arrow left" @click="slidePrev" :class="{ active: showPrevArrow }">
      <img src="@/assets/img/arrow_chips.svg" alt="arrow" />
    </div>
    <div class="arrow right" @click="slideNext" :class="{ active: showNextArrow }">
      <img src="@/assets/img/arrow_chips.svg" alt="arrow" />
    </div>
    <div class="shipsWrap">
      <div class="box"></div>
      <div
        class="ChipsList swiper-container"
        v-if="GET_ChipsList.length"
        v-swiper:ChipsListSlider="swiperOption"
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in GET_ChipsList" :key="item.val">
            <div
              class="ChipsItem"
              :data-val="item.val"
              :class="[{ active: GET_CurrentChips.val == item.val }]"
              @click="chipsClick(item.val, item.img)"
            >
              <ToolTip
                v-if="item.val == 0"
                text="The chip “X” removes placed bets from the betting area."
              />
              <img :src="image(item.img)" alt="chips" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { directive } from "vue-awesome-swiper";
import ToolTip from "@/components/ToolTip";
import "swiper/swiper.min.css";
export default {
  name: "Chips",
  components: { ToolTip },
  computed: {
    ...mapGetters(["GET_CurrentChips", "GET_ChipsList"]),
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      showPrevArrow: false,
      showNextArrow: false,
      swiperOption: {
        slidesPerView: 6,
        breakpoints: {
          0: {
            slidesPerView: 4,
          },
          380: {
            slidesPerView: 5,
          },
          400: {
            slidesPerView: 6,
          },
          600: {
            slidesPerView: 9,
          },
          992: {
            slidesPerView: 6,
          },
          1481: {
            slidesPerView: 7,
          },
          1680: {
            slidesPerView: 6,
          },
        },
        on: {
          init: () => {
            setTimeout(() => {
              this.showNextArrow = this.ChipsListSlider.progress < 0.95;
            }, 500);
          },
          slideChange: () => {
            this.showNextArrow = this.ChipsListSlider.progress < 0.95;
            this.showPrevArrow = this.ChipsListSlider.activeIndex > 0;
          },
        },
      },
    };
  },
  methods: {
    ...mapActions(["SET_CurrentChips", "SET_CursorImage"]),
    chipsClick(val, img) {
      this.SET_CurrentChips({ val, img });
      this.SET_CursorImage(img);
    },
    image(name) {
      return require(`@/assets/img/chips/${name}`);
    },
    slidePrev() {
      this.ChipsListSlider.slidePrev(250, true);
    },
    slideNext() {
      this.ChipsListSlider.slideNext(250, true);
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
