export const ICurrenciesConfig = {
    FUN: {
        symbol: 'FUN', // default currency symbol is '$'
        format: '%s %v', // controls output: %s = symbol, %v = value/number
        decimalSeparator: '.', // decimal point separator
        thousandsSeparator: ',', // thousands separator
        precision: 2, // minimum decimal places
    },
    XGB: {
        symbol: '', // default currency symbol is '$'
        format: '%s %v', // controls output: %s = symbol, %v = value/number
        decimalSeparator: '.', // decimal point separator
        thousandsSeparator: ',', // thousands separator
        precision: 2, // minimum decimal places
    },
    USD: {
        symbol: '$',
        format: '%s%v',
        decimalSeparator: '.',
        thousandsSeparator: ',',
        precision: 2,
    },
    EUR: {
        symbol: '€',
        format: '%s%v',
        decimalSeparator: '.',
        thousandsSeparator: ',',
        precision: 2,
    },
    IDR: {
        symbol: 'Rp',
        format: '%s%v',
        decimalSeparator: ',',
        thousandsSeparator: '.',
        precision: 2,
    },
    SAT: {
        symbol: 'ƀ',
        format: '%s %v',
        decimalSeparator: '.',
        thousandsSeparator: ',',
        precision: 0,
    },
    BTC: {
        symbol: 'BTC', //Ƀ
        format: '%s %v',
        decimalSeparator: '.',
        thousandsSeparator: ',',
        precision: 0,
    },
    mBTC: {
        symbol: 'mɃ',
        format: '%s %v',
        decimalSeparator: '.',
        thousandsSeparator: ',',
        precision: 2,
    },
    uBTC: {
        symbol: 'μɃ',
        format: '%s %v',
        decimalSeparator: '.',
        thousandsSeparator: ',',
        precision: 4,
    },
    AED: {
        symbol: 'د.إ',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v %s',
        precision: 2,
    },
    AFN: {
        symbol: '؋',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    ALL: {
        symbol: 'Lek',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v%s',
        precision: 2,
    },
    AMD: {
        symbol: '֏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v %s',
        precision: 2,
    },
    ANG: {
        symbol: 'ƒ',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    AOA: {
        symbol: 'Kz',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    ARS: {
        symbol: '$',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%s%v',
        precision: 2,
    },
    AUD: {
        symbol: 'AU $',
        thousandsSeparator: ' ',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    AWG: {
        symbol: 'ƒ',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    AZN: {
        symbol: '₼',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    BAM: {
        symbol: 'КМ',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    BBD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    BDT: {
        symbol: '৳',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    BGN: {
        symbol: 'лв.',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    BHD: {
        symbol: 'د.ب.‏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 3,
    },
    BIF: {
        symbol: 'FBu',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 0,
    },
    BMD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    BND: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    BOB: {
        symbol: 'Bs.',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    VES: {
        symbol: 'Bs.',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%s %v',
        precision: 2,
    },
    BRL: {
        symbol: 'R$',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%s %v',
        precision: 2,
    },
    BSD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    BTN: {
        symbol: 'Nu.',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 1,
    },
    BWP: {
        symbol: 'P',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    BYN: {
        symbol: 'Br',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    BYR: {
        symbol: 'р.',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    BZD: {
        symbol: 'BZ$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    CAD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    CDF: {
        symbol: 'FC',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 0,
    },
    CHF: {
        symbol: 'CHF',
        thousandsSeparator: "'",
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    CLP: {
        symbol: '$',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%s%v',
        precision: 2,
    },
    CNY: {
        symbol: '¥',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    COP: {
        symbol: '$',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%s%v',
        precision: 2,
    },
    CRC: {
        symbol: '₡',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%s%v',
        precision: 2,
    },
    CUC: {
        symbol: 'CUC',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    CUP: {
        symbol: '$MN',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    CVE: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    CZK: {
        symbol: 'Kč',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    DJF: {
        symbol: 'Fdj',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 0,
    },
    DKK: {
        symbol: 'kr.',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    DOP: {
        symbol: 'RD$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    DZD: {
        symbol: 'د.ج.‏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    EGP: {
        symbol: 'ج.م.‏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    ERN: {
        symbol: 'Nfk',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 2,
    },
    ETB: {
        symbol: 'ETB',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    FJD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    FKP: {
        symbol: '£',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    GBP: {
        symbol: '£',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    GEL: {
        symbol: 'GEL',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    GHS: {
        symbol: 'GH¢',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    GIP: {
        symbol: '£',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    GMD: {
        symbol: 'D',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 2,
    },
    GNF: {
        symbol: 'FG',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 0,
    },
    GTQ: {
        symbol: 'Q',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    GYD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    HKD: {
        symbol: 'HK$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    HNL: {
        symbol: 'L.',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    HRK: {
        symbol: 'kn',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    HTG: {
        symbol: 'G',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    HUF: {
        symbol: 'Ft',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    ILS: {
        symbol: '₪',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    INR: {
        symbol: '₹',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    IQD: {
        symbol: 'د.ع',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v %s',
        precision: 2,
    },
    IRR: {
        symbol: 'ريال',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    ISK: {
        symbol: 'kr.',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 0,
    },
    JMD: {
        symbol: 'J$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    JOD: {
        symbol: 'د.ا.‏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 3,
    },
    JPY: {
        symbol: '¥',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    KES: {
        symbol: 'KSh',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    KGS: {
        symbol: 'сом',
        thousandsSeparator: ' ',
        decimalSeparator: '.',
        format: '%v %s',
        precision: 2,
    },
    KHR: {
        symbol: '៛',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 0,
    },
    KMF: {
        symbol: 'CF',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 2,
    },
    KPW: {
        symbol: '₩',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 0,
    },
    KRW: {
        symbol: '₩',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    KWD: {
        symbol: 'د.ك.‏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 3,
    },
    KYD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    KZT: {
        symbol: '₸',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    LAK: {
        symbol: '₭',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 0,
    },
    LBP: {
        symbol: 'ل.ل.‏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    LKR: {
        symbol: '₨',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 0,
    },
    LRD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    LSL: {
        symbol: 'M',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 2,
    },
    LYD: {
        symbol: 'د.ل.‏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 3,
    },
    MAD: {
        symbol: 'د.م.‏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    MDL: {
        symbol: 'lei',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v %s',
        precision: 2,
    },
    MGA: {
        symbol: 'Ar',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 0,
    },
    MKD: {
        symbol: 'ден.',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    MMK: {
        symbol: 'K',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    MNT: {
        symbol: '₮',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    MOP: {
        symbol: 'MOP$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    MRO: {
        symbol: 'UM',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 2,
    },
    MTL: {
        symbol: '₤',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    MUR: {
        symbol: '₨',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    MVR: {
        symbol: 'MVR',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v %s',
        precision: 1,
    },
    MWK: {
        symbol: 'MK',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    MXN: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    MYR: {
        symbol: 'RM',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    MZN: {
        symbol: 'MT',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 0,
    },
    NAD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    NGN: {
        symbol: '₦',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    NIO: {
        symbol: 'C$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    NOK: {
        symbol: 'kr',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    NPR: {
        symbol: '₨',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    NZD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    OMR: {
        symbol: '﷼',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 3,
    },
    PAB: {
        symbol: 'B/.',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    PEN: {
        symbol: 'S/.',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    PGK: {
        symbol: 'K',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    PHP: {
        symbol: '₱',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    PKR: {
        symbol: '₨',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    PLN: {
        symbol: 'zł',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    PYG: {
        symbol: '₲',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    QAR: {
        symbol: '﷼',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    RON: {
        symbol: 'lei',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    RSD: {
        symbol: 'Дин.',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    RUB: {
        symbol: '₽',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    RWF: {
        symbol: 'RWF',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%s %v',
        precision: 2,
    },
    SAR: {
        symbol: '﷼',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    SBD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    SCR: {
        symbol: '₨',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    SDD: {
        symbol: 'LSd',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 2,
    },
    SDG: {
        symbol: '£‏',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    SEK: {
        symbol: 'kr',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    SGD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    SHP: {
        symbol: '£',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    SLL: {
        symbol: 'Le',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    SOS: {
        symbol: 'S',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    SRD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    STD: {
        symbol: 'Db',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    SVC: {
        symbol: '₡',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    SYP: {
        symbol: '£',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    SZL: {
        symbol: 'E',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    THB: {
        symbol: '฿',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    TJS: {
        symbol: 'SM',
        thousandsSeparator: ' ',
        decimalSeparator: '.',
        format: '%v %s',
        precision: 2,
    },
    TMT: {
        symbol: 'm',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v%s',
        precision: 0,
    },
    TND: {
        symbol: 'د.ت',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 3,
    },
    TOP: {
        symbol: 'T$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    TRY: {
        symbol: '₺',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    TTD: {
        symbol: 'TT$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    TVD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    TWD: {
        symbol: 'NT$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    TZS: {
        symbol: 'TSh',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    UAH: {
        symbol: '₴',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%s%v',
        precision: 2,
    },
    UGX: {
        symbol: 'USh',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    UYU: {
        symbol: '$',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%s%v',
        precision: 2,
    },
    UZS: {
        symbol: 'сўм',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v %s',
        precision: 2,
    },
    VEB: {
        symbol: 'Bs.',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    VEF: {
        symbol: 'Bs.',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%s %v',
        precision: 2,
    },
    VND: {
        symbol: '₫',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        format: '%v %s',
        precision: 2,
    },
    VUV: {
        symbol: 'VT',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 2,
    },
    WST: {
        symbol: 'WS$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    XAF: {
        symbol: 'F',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 0,
    },
    XCD: {
        symbol: '$',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    XBT: {
        symbol: 'Ƀ',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 2,
    },
    XOF: {
        symbol: 'F',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%v%s',
        precision: 0,
    },
    XPF: {
        symbol: 'F',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%v%s',
        precision: 2,
    },
    YER: {
        symbol: '﷼',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s %v',
        precision: 2,
    },
    ZAR: {
        symbol: 'R',
        thousandsSeparator: ' ',
        decimalSeparator: ',',
        format: '%s%v',
        precision: 2,
    },
    ZMW: {
        symbol: 'ZK',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
    WON: {
        symbol: '₩',
        thousandsSeparator: ',',
        decimalSeparator: '.',
        format: '%s%v',
        precision: 2,
    },
};