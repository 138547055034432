<template>
  <div id="app" :class="{ disabled: GET_DisabledInterface }">
    <Preloader v-if="$store.state.showPreloader" />
    <Canvas />
    <RotatePhone />
    <template v-if="!IS_Loading">
      <AppCursor v-if="!$store.getters.GET_isMobile" />
      <Interface />
      <ReloadPage />
      <Audio />
    </template>
    <Connection />
  </div>
</template>

<script>
import AppCursor from "@/components/Cursor/";
import Canvas from "@/components/Canvas/";
import Interface from "@/components/Interface/";
import ReloadPage from "@/components/ReloadPage/";
import RotatePhone from "@/components/RotatePhone/";
import Preloader from "@/components/Preloader/";
import Audio from "@/components/Audio/";
import Connection from "@/components/Connection/";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    AppCursor,
    Canvas,
    Interface,
    ReloadPage,
    RotatePhone,
    Preloader,
    Audio,
    Connection,
  },
  computed: {
    ...mapGetters([
      "GET_GameState",
      "GET_Babylon",
      "GET_DisabledInterface",
      "GET_ConnectionLost",
      "IS_Loading",
      "GET_LoadingError"
    ]),
    showFPS() {
      return process.env.develop;
    },
  },
  methods: {
    ...mapActions(["CLEAR_Dealer"]),
  },
  async mounted() {
    await this.$game.loginPlatform();
    await this.$game.getState();
    this.GET_Babylon.init3dGame();
    await this.$store.dispatch("GET_History");
    await this.$store.dispatch("UPDATE_Settings");
    this.$store.dispatch("GENERATE_PlayerSeed");
    this.$store.dispatch("SET_DefaultChips");
    this.$store.dispatch("INIT_PingConnection", this.$game.ping);
    // this.$store.dispatch("CREATE_CustomHistory", {
    //   count: 1,
    //   winner: "banker",
    //   push: false,
    // });
    // this.$game.ping();
    if (this.GET_GameState && this.GET_GameState.state === "NO_GAME") {
      this.$store.commit("SET_Controls", {
        activeTable: true,
      });
    }
  },
};
</script>

<style lang="less" src="@/assets/styles/global.less"></style>
<style lang="less" scoped>
#app.disabled {
  pointer-events: none;
  user-select: none;
}
#newConfig {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
</style>
