<template>
  <div class="Feedback statisticsItem">
    <img class="close" src="@/assets/img/menu-icon-close.svg" alt="menu" />
    <div class="content" :class="{ maxWidth: step }">
      <template v-if="step == 0">
        <img class="icon" src="@/assets/img/feedback.svg" alt="feedback" />
        <h3>We need your feedback</h3>
        <p>Take a small survey or write us your opinion individually.</p>
        <div class="mainBtn h36 w100" @click="step = 1">small poll</div>
        <div class="textLine"><span>Or whrite us</span></div>
        <div class="row">
          <a href="#" target="_blank" class="mainBtn gray w50">Email</a>
          <a href="#" target="_blank" class="mainBtn gray w50">Telegram</a>
        </div>
      </template>
      <template v-else-if="step == 1">
        <h3>Please rate your played experience</h3>
        <div class="formWrap" :class="{ loading }">
          <LoadingIcon v-if="loading" />
          <form novalidate @submit.prevent="submit" :key="key">
            <div
              class="rateWrap"
              @mouseenter="hoverRate = true"
              @mouseleave="hoverRate = false"
            >
              <div
                class="rateItem"
                v-for="(rate, index) in rateList"
                :key="index"
                :class="{ active: index == activeRate && !hoverRate }"
                @click="activeRate = index"
              >
                <img :src="rate.img" alt="rate" />
                <span>{{ rate.name }}</span>
              </div>
            </div>
            <Label
              inputType="textarea"
              placeholder="Tell us your mind about “3D Baccarat”"
              @onInput="onInputText"
              :error="textError"
              :errorText="textErrorText"
              ref="textarea"
            />
            <button type="submit" class="mainBtn">send</button>
          </form>
        </div>
      </template>
      <template v-else>
        <img class="icon" src="@/assets/img/feedback.svg" alt="feedback" />
        <h3>Thank you!</h3>
        <p>
          Thanks for sharing your thoughts!<br />
          We appreciate your feedback.
        </p>
        <div class="mainBtn h36 step3" @click="step = 0">DONE</div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Label from "@/components/Label";
import { mapGetters } from "vuex";
export default {
  name: "Feedback",
  components: { Label },
  data: function () {
    return {
      key: 0,
      loading: false,
      step: 2,
      hoverRate: false,
      //
      error: false,
      text: "",
      textError: false,
      textErrorText: "",
      activeRate: 4,
      rateList: [
        {
          img: require("./img/rate_1.svg"),
          name: "Aweful",
        },
        {
          img: require("./img/rate_2.svg"),
          name: "Bad",
        },
        {
          img: require("./img/rate_3.svg"),
          name: "Okay",
        },
        {
          img: require("./img/rate_4.svg"),
          name: "Good",
        },
        {
          img: require("./img/rate_5.svg"),
          name: "Amaizing",
        },
      ],
      unsubscribe: null,
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type == "SET_ModalOpen") {
        const { open, name } = mutation.payload;
        if (name == 'Feedback' && open) {
          this.step = 0;
        }
      }
    });
  },
  computed: {
    ...mapGetters(["GET_GameState"]),
  },
  methods: {
    // ...mapActions([""]),
    onInputText(value = this.text) {
      this.text = value;
      const textError = this.$simpleInputValidate(value, true, "textarea");
      this.textError = textError.error;
      if (textError.error) {
        this.textErrorText = textError.text;
        this.$refs.textarea.$refs.input.focus();
      }
      return textError.error;
    },
    async submit() {
      this.error = this.onInputText();
      if (!this.error) {
        this.loading = true;
        const request = await this.request();
        if (request.status == 200) {
          this.reset();
          this.step = 2;
        }
        this.loading = false;
      }
    },
    reset() {
      this.text = "";
      this.error = false;
      this.key++;
    },
    async request() {
      return await axios({
        method: "post",
        url: "https://3dcasino.redlab.site/send_mail.php",
        data: {
          text: this.text,
          game_id: this.GET_GameState.gameId,
          rate: this.activeRate + 1,
        },
      });
    },
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
