import validate from './json/validate';
import main from './json/main';

const data = {
    validate,
    main
}

const translate = (name, key = 'main') => {
    try {
        const val = data[key][name];
        if (typeof val == 'undefined') return "Field not found";
        return val;
    } catch (error) {
        return "Key not found";
    }
}

export default translate