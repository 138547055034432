import simpleInputValidate from './functions/simpleInputValidate'
import Countly from 'countly-sdk-web'

export default {
    install(Vue, { store }) {
        Vue.prototype.$rnd = (min = 0, max = 2048) => {
            var rand = min - 0.5 + Math.random() * (max - min + 1);
            rand = Math.round(rand);
            return rand;
        };
        Vue.prototype.$numberWithCommas = (x) => {
            return parseFloat(x.toFixed(10)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
        };
        Vue.prototype.$isDesktop = () => store.getters.isDesktop;
        Vue.prototype.$simpleInputValidate = simpleInputValidate;

        Vue.prototype.$Countly = (() => {
            if (process.env.production) {
                Countly.init({
                    app_key: "143689a3dc67afa4b0a18e775d5bfe1c176520af",
                    url: 'https://countly.onetouch.io',
                });
                // track sessions automatically
                Countly.track_sessions();
                // track pageviews automatically
                Countly.track_pageview();
                return Countly
            }
            return {
                add_event(data) {
                    console.log('add_event', data);
                }
            }
        })();
    }
}