const CryptoJS = require("crypto-js");
const salt = CryptoJS.enc.Utf8.parse('7a25f9132ec6a8b34');
const iv = CryptoJS.enc.Utf8.parse('7781157e2629b094f0e3dd48c4d786115');
const key = CryptoJS.PBKDF2(
    'redlab_hazard',
    CryptoJS.enc.Utf8.parse(salt),
    { keySize: 128 / 2, iterations: 5 }
);

export default {
    install(Vue) {
        Vue.prototype.$crypto = {
            encrypt: (data) => {
                return CryptoJS.AES.encrypt(data, key, { iv: iv }).toString()
            },
            decrypt: (data) => {
                return CryptoJS.AES.decrypt(data, key, { iv: iv }).toString(CryptoJS.enc.Utf8)
            }
        }
    }
}