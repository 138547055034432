<template>
  <div
    class="card"
    ref="card"
    v-bind:class="[{ active: card.active }, card.class]"
    v-bind:style="`background-position: ${position};`"
  ></div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    card: {
      default: () => {},
    },
    thirdCard: { default: false },
  },

  data: function () {
    return {
      created: false,
    };
  },
  computed: {
    ...mapGetters(["GET_StateCards"]),
    currentCard() {
      const { playerCards, dealerCards } = this.GET_StateCards;
      if (playerCards == undefined || dealerCards == undefined) return false;
      const { index, type } = this.card;
      return type == "player" ? playerCards[index] : dealerCards[index];
    },
    position() {
      if (!this.created || !this.currentCard) return false;
      const card = this.currentCard.card[0];
      const lear = this.currentCard.card[1];
      const line = (() => {
        if (lear == "c") return 2; // крест
        if (lear == "h") return 0; // чирва
        if (lear == "s") return 1; // пика
        if (lear == "d") return 3; // бубна
      })();
      const column = (() => {
        if (card == "A") return 0;
        if (card == "T") return 9;
        if (card == "K") return 12;
        if (card == "Q") return 11;
        if (card == "J") return 10;
        return parseInt(card) - 1;
      })();
      const { width, height } = this.$refs.card.getBoundingClientRect();
      return `${-(this.thirdCard ? height : width) * column}px ${
        -(this.thirdCard ? width : height) * line
      }px`;
    },
  },
  mounted: function () {
    this.created = true;
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
