<template>
  <div class="Interface">
    <notifications
      :max="3"
      group="foo"
      position="bottom left"
      type="error"
      classes="vue-notification-template vue-notification warn warn notification"
    />
    <HeaderMobile v-if="GET_Dealer && !$isDesktop()" />
    <TopRow v-show="GET_Dealer" />
    <GamePanel />
    <FlipCard />
    <Modals />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopRow from "@/components/TopRow/";
import GamePanel from "@/components/GamePanel/";
import FlipCard from "@/components/FlipCard/";
import HeaderMobile from "@/components/HeaderMobile/";
import Modals from "@/components/Modals/";

export default {
  name: "Interface",
  components: { TopRow, GamePanel, FlipCard, HeaderMobile, Modals },
  computed: {
    ...mapGetters(["GET_Dealer"]),
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
