<template>
  <div class="HeaderMobile">
    <div class="name">Welcome, {{ $store.state.guestName }}</div>
    <div class="buttons">
      <!-- statistics -->
      <div class="buttonItem statistics" @click="$popup.show('Statistics')">
        <img src="@/assets/img/statistics.svg" alt="statistics" />
      </div>
      <!-- tableLimits -->
      <div class="buttonItem tableLimits" @click="$popup.show('TableLimits')">
        <img src="@/assets/img/tableLimits.svg" alt="tableLimits" />
      </div>
      <!-- menu -->
      <div class="buttonItem menu" @click="menuClick">
        <img src="@/assets/img/menu-icon.svg" alt="menu" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "default",
  components: {},
  props: {
    data: { default: () => {} },
  },
  data: function () {
    return {
      ...this.data,
    };
  },
  computed: {
    ...mapGetters(["DISABLED_Menu"]),
  },
  methods: {
    menuClick() {
      if (!this.DISABLED_Menu) this.$popup.show("Menu");
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
