<template>
  <div class="connection">
    <div class="status" v-if="showStatus" :class="status"></div>
    <div
      class="ConnectionLost"
      id="ConnectionLost"
      v-if="GET_ConnectionLost || GET_LoadingError"
    >
      <div class="content">
        <svg
          class="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"
            fill="white"
          />
        </svg>
        <h3>{{ title }}</h3>
        <p>Please check your network connection.</p>
        <div class="mainBtn h36 w50" @click="reload">Reload page</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Connection",
  computed: {
    ...mapGetters(["GET_ConnectionLost", "GET_LoadingError"]),
    title() {
      if (this.GET_LoadingError) return "Failed to load game resources";
      return "Lost connection";
    },
    status() {
      if (this.GET_ConnectionLost || this.GET_LoadingError) return "error";
      return "active";
    },
     showStatus() {
      return process.env.develop;
    },
  },
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
