var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"statisticsTable derivedTable BigEyeBoyTable"},[_c('tbody',_vm._l((_vm.tableData),function(row,rowIndex){return _c('tr',{key:_vm.name.trim() + '_row_' + rowIndex},_vm._l((row),function(column,columnIndex){return _c('td',{key:_vm.name + '_column_' + columnIndex,style:('width:' + 100 / _vm.tableData[0].length + '%')},[(column)?_c('div',{staticClass:"row margin0"},[_c('div',{staticClass:"redColumn"},_vm._l((column.redCount),function(redCount){return _c('span',{key:'row_' +
                rowIndex +
                '_column_' +
                columnIndex +
                '_redCount_' +
                redCount +
                '_' +
                _vm.GET_HistoryDataKey,staticClass:"circle red"})}),0),_c('div',{staticClass:"blueCount"},_vm._l((column.blueCount),function(blueCount){return _c('span',{key:'row_' +
                rowIndex +
                '_column_' +
                columnIndex +
                '_blueCount_' +
                blueCount +
                '_' +
                _vm.GET_HistoryDataKey,staticClass:"circle blue"})}),0)]):_vm._e()])}),0)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }