<template>
  <div class="menu" :class="{disabled: DISABLED_Menu}">
    <div
      class="menuIcon"
      :class="{ active: GET_MenuIsOpenClass }"
      @click="SHOW_HIDE_Menu"
    >
      <img class="open" src="@/assets/img/menu-icon.svg" alt="menu" />
      <img class="close" src="@/assets/img/menu-icon-close.svg" alt="menu" />
    </div>
    <MenuContent v-show="GET_MenuIsOpen" />
  </div>
</template>

<script>
import MenuContent from "./MenuContent";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "game-menu",
  components: { MenuContent },
  computed: {
    ...mapGetters(["GET_MenuIsOpen", "GET_MenuIsOpenClass", "DISABLED_Menu"]),
  },
  data: function () {
    return {};
  },
  methods: {
    ...mapActions(["SHOW_HIDE_Menu"]),
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
