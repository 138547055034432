import store from "../..";
import chipsList from './chipsList'

const state = {
    currentChips: { val: null, img: null },
    chipsList: chipsList,
}

const getters = {
    GET_CurrentChips: state => {
        return state.currentChips;
    },
    GET_ChipsList: (state, {GET_ICurrencyFormatter}) => {
        let { min, max } = store.getters.GET_GameState.config.betLimit;
        const hasMinChip = state.chipsList.find(({ val }) => val == min) ? true : false;
        if (!hasMinChip){
            const filteredByMin = state.chipsList.filter(({val}) => val != 0 && val < min);
            if (filteredByMin.length) {
                min = filteredByMin[filteredByMin.length - 1].val;
                GET_ICurrencyFormatter.setPrecision(min)
            }
        }



        return state.chipsList.filter(el => el.val >= min && el.val <= max / 4 || el.val == 0).sort((a, b) => (a.val > b.val ? 1 : -1));
    },
}
const mutations = {
    SET_CurrentChips(state, payload) {
        state.currentChips = payload
    },
}
const actions = {
    SET_CurrentChips({ commit }, payload) {
        commit('SET_CurrentChips', payload)
    },
    SET_DefaultChips({ commit, getters }, payload) {
        state.currentChips = getters.GET_ChipsList.find(({ val }) => val > 0);
        commit("SET_CursorImage", state.currentChips.img)
    },
};
export default {
    state, getters, mutations, actions
}