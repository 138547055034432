const getSound = (name) => {
    return document.querySelector(`.SoundElem[name='${name}']`);
}
let firstSoundInit = false;


const state = {
    sound: null,
    soundsList: [],
    showUnmuteIcon: false,
}
const getters = {
    GET_Sound: state => state.sound,
    GET_SoundsList: state => state.soundsList,
    GET_ShowUnmuteIcon: state => state.showUnmuteIcon,

}
const mutations = {
    SET_Babylon_sound: (state, payload) => {
        state.sound = payload;
    },
    SET_SoundsList: (state, payload) => {
        state.soundsList = payload;
    },
    SET_ShowUnmuteIcon: (state, payload) => {
        state.showUnmuteIcon = payload;
    },
}
const actions = {
    CHANGE_Setting({ commit, state, getters, dispatch }, { name, value }) {
        const settings = { ...getters.GET_Settings };
        if (name == 'Volume') {
            // Sound effects

            // Background music
            const key = getters.GET_Sound['Background music'];
            const sound = getSound('Background music');
            if (key && sound) sound.volume = value / 100;
        };
        if (name == 'Audio') {
            // Sound effects

            // Background music
            const background = getters.GET_Sound['Background music'];
            settings['Background music'] = value;
            settings['Sound effects'] = value;
            commit('SET_OneSettings', { name: 'Sound effects', value })
            commit('SET_OneSettings', { name: 'Background music', value })
            background[value ? 'play' : 'pause']();
        };

        if (name == 'Sound effects') {
            //   empty
        };

        if (name == 'Background music') {
            const key = getters.GET_Sound[name];
            const sound = getSound(name);
            if (key && sound) sound[value ? 'play' : 'pause']();
            settings[name] = value;
        };


        if (!settings['Background music'] && !settings['Sound effects']) {
            settings['Audio'] = false;
            commit('SET_OneSettings', { name: 'Audio', value: false })
        };
        if (settings['Background music'] || settings['Sound effects']) {
            settings['Audio'] = true;
            commit('SET_OneSettings', { name: 'Audio', value: true })
        };
        if (name != 'Volume') commit('SET_ShowUnmuteIcon', false)
    },
    ENABLE_Sounds({ commit, state, getters, dispatch }, focus = false) {
        const settings = getters.GET_Settings;
        if (!firstSoundInit && focus) return false;
        if (!focus) firstSoundInit = true;

        if (settings['Sound effects']) {
            // empty
        };

        if (settings['Background music']) {
            const key = getters.GET_Sound['Background music'];
            const sound = getSound('Background music');
            if (key && sound && sound.paused) playSound(sound, this);
        };
        dispatch('CHANGE_Setting', { name: "Volume", value: settings["Volume"] ? settings["Volume"] : 50 })
    },
    UNMUTE_Sounds({ commit, state, getters, dispatch }) {
        dispatch('ENABLE_Sounds', true)
    },
    MUTE_Sounds({ commit, state, getters, dispatch }) {
        // Background music
        const background = getters.GET_Sound['Background music'];
        const sound = getSound('Background music');
        if (background && sound && !sound.paused) sound.pause();

    }
};

const playSound = (el, store) => {
    const promise = el.play();
    if (promise !== undefined) {
        promise.then(data => {
            // alert('Autoplay started')
            store.commit('SET_ShowUnmuteIcon', false) // Autoplay started
        }).catch(error => {
            // alert('Autoplay was prevented')
            store.commit('SET_ShowUnmuteIcon', true) // Autoplay was prevented
        });
    }
}

export default {
    state, getters, mutations, actions
}