import Vue from "vue";
import { TweenMax, TweenLite, Power1 } from "gsap";
const ease = Power1.easeInOut

const state = {
    availableModels: ['Girl_1', 'Girl_2', 'Girl_3'],
    dealer: null,
    tempDealer: null,
    time: 0.6
}

const getters = {
    GET_Dealer: (state, { GET_Settings }) => {
        if (GET_Settings.model && state.availableModels.includes(GET_Settings.model)) {
            return GET_Settings.model;
        };
        return state.dealer;
    },
    GET_TempDealer: (state) => {
        return state.tempDealer;
    },
}

const mutations = {
    SET_Dealer(state, payload) {
        state.dealer = payload
    },
    SET_TempDealer(state, payload) {
        state.tempDealer = payload
    },
}

const actions = {
    async RESELECT_Dealer({ state, dispatch, getters, commit }) {
        commit('SET_DisabledInterface', true);
        await new Promise((resolve, reject) => {
            TweenMax.to('#renderCanvas, #SelectDealer, #interfaceTopRow', state.time,
                { opacity: 0, ease, onComplete: resolve })
        });
        commit('SET_Dealer', false);
        commit('SET_TempDealer', false);
        Vue.prototype.$game.setStorage({ model: false });
        getters.GET_Babylon.SelectDealerScene(true);
        TweenMax.set('#renderCanvas, #SelectDealer, #interfaceTopRow', { opacity: 0 });
        await new Promise((resolve, reject) => {
            TweenMax.fromTo('#renderCanvas, #SelectDealer, #interfaceTopRow', state.time, { opacity: 0 },
                { opacity: 1, ease, onComplete: resolve }).delay(0)
        });
        commit('SET_DisabledInterface', false);
    },
    async SELECT_Dealer({ state, dispatch, getters, commit }) {
        const Model = getters.GET_TempDealer;

        getters.GET_Babylon.PlayAnimation('happy', Model)
        await new Promise((resolve, reject) => {
            TweenLite.to('#renderCanvas, #SelectDealer', state.time, {
                opacity: 0, ease, onComplete: resolve
            }).delay(state.time)
        });
        TweenMax.set('#renderCanvas, #SelectDealer, #interfaceTopRow', { opacity: 0 });
        commit('SET_DisabledInterface', true);
        commit('SET_Dealer', Model);
        await getters.GET_Babylon.ClearDealerScene(Model);
        Vue.prototype.$game.setStorage({ model: Model });
        getters.GET_Babylon.PlayAnimation('hello', Model)
        await new Promise((resolve, reject) => {
            TweenMax.fromTo('#renderCanvas, #SelectDealer, #interfaceTopRow', state.time,
                { opacity: 0 },
                {
                    opacity: 1, ease, onComplete: resolve
                })
        });
        commit('SET_DisabledInterface', false);
        Vue.prototype.$Countly.add_event({
            key: "select_dealer",
            count: 1,
            sum: Model,
        });
    },
    Animate_Dealer({ getters }, Model) {
        // getters.GET_Babylon.PlayAnimation('fade', Model)
        getters.GET_Babylon.Animate_Dealer(Model)
    },
};
export default {
    state, getters, mutations, actions
}