<template>
  <Layout id="History" title="History" :class="{ empty: emptyHistory }">
    <template v-if="emptyHistory">
      <div class="emptyHistory">
        <img class="icon" src="@assets/img/empty-history.svg" alt="empty-history" />
        <h5 class="h2">History</h5>
        <p>The game history is available only when playing for money</p>
      </div>
    </template>
    <template v-else>
      <HistoryCard
        v-for="(card, index) in GET_HistoryData"
        :class="{ last: index == GET_HistoryData.length - 1 }"
        :key="'history_' + card.gameId"
        :card="card"
      />
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "@/components/Modals/PopupLayout";
import HistoryCard from "@/components/HistoryCard";
export default {
  name: "History",
  components: {
    Layout,
    HistoryCard,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["GET_HistoryData"]),
    emptyHistory() {
      return !this.GET_HistoryData.length;
    },
    historyReversed() {
      return this.GET_HistoryData.slice(0).reverse();
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped src="./component.less"></style>
<style lang="less">
#History.empty {
  .popupContent {
    min-height: 320px;
  }
  .title {
    display: none;
  }
}
</style>
