<template>
  <div class="UserStatistics">
    <div class="UserStatisticsItem balance">
      <div class="circle">
        <img src="@/assets/img/balance.svg" alt="balance" />
      </div>
      <div class="content">
        <span class="title">Balance</span>
        <span class="val">{{ GET_Currency }}</span>
      </div>
    </div>
    <!--  -->
    <div class="UserStatisticsItem lastWin">
      <div class="circle">
        <img src="@/assets/img/lastWin.svg" alt="lastWin" />
      </div>
      <div class="content">
        <span class="title">Last win</span>
        <span class="val">{{ GET_LastWin_Currency }}</span>
      </div>
    </div>
    <!--  -->
    <div class="UserStatisticsItem totalBet">
      <div class="circle">
        <img src="@/assets/img/totalBet.svg" alt="total bet" />
      </div>
      <div class="content">
        <span class="title">total bet</span>
        <span class="val">{{ GET_TotalBet_Currency }}</span>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UserStatistics",
  data() {
    return {
      totalBet: 123,
    };
  },
  computed: {
    ...mapGetters(["GET_Currency", "GET_LastWin_Currency", "GET_TotalBet_Currency"]),
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
