<template>
  <div class="LabelWrap">
    <label
      class="Label"
      :class="[
        'type_' + type,
        {
          error,
          background,
          focus: focusNow,
          firstFocus: firstFocus,
          iconLeft: iconLeft,
          iconRight: iconRight,
          hasList: list && list.length,
          hasTextarea: inputType == 'textarea',
          errorBorder: error && errorText == '',
        },
      ]"
    >
      <span class="hover_text" v-if="type == 'label'">
        {{ current_placeholder }}
      </span>
      <span
        class="icon iconLeft"
        @click="iconLeftClick($event)"
        v-if="iconLeft"
        v-html="iconLeft"
      ></span>
      <span
        class="icon iconRight"
        @click="iconRightClick($event)"
        v-if="iconRight"
        v-html="iconRight"
      ></span>

      <template v-if="inputType == 'textarea'">
        <textarea
          v-model="input"
          @focus="focus"
          @blur="blur"
          @input="onInput($event)"
          :placeholder="type != 'label' ? placeholder : ''"
          :maxlength="limit"
          ref="input"
        ></textarea>
        <span class="limit">
          <span>{{ input.length }}</span> / {{ limit }}
        </span>
      </template>
      <template v-else>
        <input
          :type="inputType"
          v-model="input"
          @focus="focus"
          @blur="blur"
          @input="onInput($event)"
          :placeholder="type != 'label' ? placeholder : ''"
          ref="input"
          :autocomplete="inputType"
          :name="name"
        />
      </template>
    </label>
    <span class="errorText" v-if="error && errorText">{{ errorText }}</span>
  </div>
</template>

<script>
/**
 * The Label component.
 */
export default {
  name: "Label",
  props: {
    name: { default: null, type: String },
    /**
     * The placeholder for the label
     */
    placeholder: { default: null, type: String },
    second_placeholder: { default: null, type: String },
    /**
     * Error state
     */
    error: { default: false },
    /**
     * Error text
     */
    errorText: { default: null, type: String },
    /**
     * Init value in v-model
     */
    value: { default: null, type: String },
    /**
     * Background in component
     * @values true, false
     */
    background: { default: false },
    /**
     * Icon (html content) in button.
     *
     * Use require('@/assets/img/logo.svg?raw')
     */
    icon: { default: false },
    /**
     * Left icon (html content).
     *
     * Use require('@/assets/img/logo.svg?raw')
     */
    iconLeft: { default: null },
    /**
     * Right icon (html content).
     *
     * Use require('@/assets/img/logo.svg?raw')
     */
    iconRight: { default: null },
    /**
     * Visual view type
     * @values label, hideLabel
     */
    type: { default: "hideLabel" },
    /**
     * Type of input element
     * @values text, email, textarea...
     */
    inputType: { default: "text" },
    /**
     * List of dependencies
     * @values [{ active: false, error: false, text: "minimum 8" }]
     */
    list: { default: null, type: Array },
    limit: { default: 150, type: Number },
  },
  data() {
    return {
      firstFocus: false,
      focusNow: false,
      input: "",
    };
  },
  computed: {
    current_placeholder() {
      if (this.type == "label" && this.input.length && this.second_placeholder) {
        return this.second_placeholder;
      }
      return this.placeholder;
    },
  },
  methods: {
    focus() {
      this.focusNow = true;
      this.firstFocus = true;
    },
    blur() {
      this.focusNow = this.input.length > 0;
    },
    iconLeftClick($event) {
      /**
       * Emit iconLeftClick.
       * @property {$event} event
       */
      this.$emit("iconLeftClick", $event);
    },

    iconRightClick($event) {
      /**
       * Emit iconRightClick.
       * @property {$event} event
       */
      this.$emit("iconRightClick", $event);
    },
    onInput($event) {
      /**
       * Emit input value.
       * @property {$event} event
       */
      this.$emit("onInput", this.input, $event);
    },
  },
  created() {
    if (this.value != null) {
      this.input = this.value;
      this.focusNow = true;
      this.firstFocus = true;
    }
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
