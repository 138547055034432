<template>
  <Layout id="Feedback">
    <Feedback class="w100 static" />
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "@/components/Modals/PopupLayout";
import Feedback from "@/components/Feedback/";
export default {
  name: "History",
  components: {
    Layout,
    Feedback,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {},
};
</script>

<style lang="less" scoped src="./component.less"></style>
