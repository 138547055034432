import { SetCamera, MoveCameraToDealer } from './camera'
import MyBabylon from '../index'

import store from "@/store/";

const Optimization = function () {
    MyBabylon.scene.getMeshByUniqueID(8) ? MyBabylon.scene.getMeshByUniqueID(8).dispose() : '' // remove background helper
    // MyBabylon.scene.freezeMaterials();

    // ---------------- OPTIMISATION
    // Options (target 60fps (which is not possible) with a check every 500ms)
    var options = new BABYLON.SceneOptimizerOptions(60, 500);
    options.addOptimization(new BABYLON.SceneOptimizerOptions.LowDegradationAllowed());

    var priority = 0;
    options.optimizations.push(new BABYLON.ShadowsOptimization(priority));
    options.optimizations.push(new BABYLON.LensFlaresOptimization(priority));

    // Next priority
    priority++;
    options.optimizations.push(new BABYLON.PostProcessesOptimization(priority));
    options.optimizations.push(new BABYLON.ParticlesOptimization(priority));

    // Next priority
    priority++;
    options.optimizations.push(new BABYLON.TextureOptimization(priority, 256));

    // Next priority
    priority++;
    options.optimizations.push(new BABYLON.RenderTargetsOptimization(priority));

    // Next priority
    priority++;
    options.optimizations.push(new BABYLON.HardwareScalingOptimization(priority, 4));

    var optimizer = new BABYLON.SceneOptimizer(MyBabylon.scene, options);     // Optimizer
    MyBabylon.scene.meshes.forEach(mesh => {
        if (mesh.material) {
            //scene.meshes[i].material.freeze();                   // generate error on console : Reducing shaders checking and updating if matreial are static
            mesh.doNotSyncBoundingInfo = true;        // In conjonction with mesh.alwaysSelectAsActiveMesh turn off bounding info synchronization
            mesh.alwaysSelectAsActiveMesh = true;     // will always benne renderer, stop this calculation on meshes.
        }
        mesh.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
        // mesh.freezeWorldMatrix();
    });
    MyBabylon.scene.clearCachedVertexData();                     // Removing cached VertexData
    MyBabylon.scene.cleanCachedTextureBuffer();                  // Removing cached texture buffers
}

const RemoveGirls = async function (currentGirl = 'Girl_2') {
    return new Promise(reject => {
        [
            ...MyBabylon.scene.meshes,
            ...MyBabylon.scene.skeletons,
            ...MyBabylon.scene.animationGroups,
            ...MyBabylon.scene.transformNodes,
            ...MyBabylon.scene.geometries,
        ].filter(({ type, model }) => type == 'girl' && model != currentGirl).forEach(element => {
            element.dispose ? element.dispose() : null;
        });

        currentGirl != 'Girl_1' ? delete MyBabylon.characterRoot['Girl_1'] : null;
        currentGirl != 'Girl_2' ? delete MyBabylon.characterRoot['Girl_2'] : null;
        currentGirl != 'Girl_3' ? delete MyBabylon.characterRoot['Girl_3'] : null;

        MyBabylon.paramsList = MyBabylon.paramsList.filter(({ anim: { name, model } }) => {
            return !model || model == currentGirl;
        })

        // remove animations from AnimationGroups
        for (const key in MyBabylon.AnimationGroups) {
            if (Object.hasOwnProperty.call(MyBabylon.AnimationGroups, key)) {
                if (key.includes('Girl_') && key != currentGirl) {
                    MyBabylon.AnimationGroups[key].forEach(anim => anim.dispose ? anim.dispose() : null);
                    delete MyBabylon.AnimationGroups[key];
                }
            }
        }

        MyBabylon.scene.render();
        setTimeout(reject, 4);
    });
}

const HideLoadingUICallBack = function () {
    store.dispatch("HIDE_Preloader");
    if (!store.getters.GET_Dealer) {
        SelectDealerScene()
    } else {
        if (store.getters.GET_GameState.state === "NO_GAME") {
            setTimeout(() => { MyBabylon.PlayAnimation('hello') }, 1200);
        }
        if (store.getters.GET_GameState.state === "DEAL_DONE") {
            MyBabylon.PlayAnimation('anim_2');
            MyBabylon.nextCharacterAnimationQueue[store.getters.GET_Dealer].push('anim_3')
            store.dispatch('GAME_Resume')
        }
    }
}

const SelectDealerScene = function (animate = true) {
    SetCamera()
    if (!store.getters.GET_Dealer) {
        MyBabylon.meshes['Girl_1'] ? MyBabylon.meshes['Girl_1'].setEnabled(true) : null;
        MyBabylon.meshes['Girl_2'] ? MyBabylon.meshes['Girl_2'].setEnabled(true) : null;
        MyBabylon.meshes['Girl_3'] ? MyBabylon.meshes['Girl_3'].setEnabled(true) : null;
        MyBabylon.meshes['Table'] ? MyBabylon.meshes['Table'].setEnabled(false) : null;
        MyBabylon.meshes['Cards'] ? MyBabylon.meshes['Cards'].setEnabled(false) : null;
        MyBabylon.meshes['Devices'] ? MyBabylon.meshes['Devices'].setEnabled(false) : null;
        // move models
        const shiftPosition = window.innerWidth > 620 ? 2.8 : 5;
        MyBabylon.characterRoot['Girl_1'] ? MyBabylon.characterRoot['Girl_1'].position.x = -shiftPosition : null;
        MyBabylon.characterRoot['Girl_3'] ? MyBabylon.characterRoot['Girl_3'].position.x = shiftPosition : null;
        // rotate models
        MyBabylon.characterRoot['Girl_1'] ? MyBabylon.characterRoot['Girl_1'].rotationQuaternion = null : null;
        MyBabylon.characterRoot['Girl_3'] ? MyBabylon.characterRoot['Girl_3'].rotationQuaternion = null : null;
        if (window.innerWidth > 620) {
            MyBabylon.characterRoot['Girl_1'] ? MyBabylon.characterRoot['Girl_1'].rotation.y = 160 * (Math.PI / 180) : null;
            MyBabylon.characterRoot['Girl_3'] ? MyBabylon.characterRoot['Girl_3'].rotation.y = 200 * (Math.PI / 180) : null;
        } else {
            MyBabylon.characterRoot['Girl_1'] ? MyBabylon.characterRoot['Girl_1'].rotation.y = 180 * (Math.PI / 180) : null;
            MyBabylon.characterRoot['Girl_3'] ? MyBabylon.characterRoot['Girl_3'].rotation.y = 180 * (Math.PI / 180) : null;
            SetDefaultMobileDealer();
        }
        // play animations
        if (animate && !store.getters.GET_Dealer) {
            MyBabylon.PlayAnimation('select_idle', 'Girl_1', true);
            MyBabylon.PlayAnimation('select_idle', 'Girl_2', true);
            MyBabylon.PlayAnimation('select_idle', 'Girl_3', true);
        }
    }
}

const ClearDealerScene = async function (Model = store.getters.GET_Dealer) {
    MyBabylon.scene.animationGroups.forEach(anim => {
        anim.onAnimationGroupEndObservable.clear();
        anim.setWeightForAllAnimatables(0)
        anim.stop();
    });

    if (MyBabylon.characterRoot['Girl_1']) {
        MyBabylon.characterRoot['Girl_1'].position.x = 0;
        if (Model != 'Girl_1') MyBabylon.meshes['Girl_1'].setEnabled(false);
    }
    if (MyBabylon.characterRoot['Girl_2']) {
        if (Model != 'Girl_2') MyBabylon.meshes['Girl_2'].setEnabled(false);
    }
    if (MyBabylon.characterRoot['Girl_3']) {
        MyBabylon.characterRoot['Girl_3'].position.x = 0;
        if (Model != 'Girl_3') MyBabylon.meshes['Girl_3'].setEnabled(false);
    }

    MyBabylon.characterRoot[Model].rotation.y = 180 * (Math.PI / 180);
    SetCamera()
    MyBabylon.meshes['Table'].setEnabled(true);
    MyBabylon.meshes['Cards'].setEnabled(true);
    MyBabylon.meshes['Devices'].setEnabled(true);

    // MyBabylon.paramsList.forEach(({ anim }) => {
    //     anim.onAnimationGroupEndObservable.clear();
    //     anim.setWeightForAllAnimatables(0)
    //     anim.stop();
    //     anim.reset();
    // });

    // await RemoveGirls.call(this, Model);
    MyBabylon.scene.clearCachedVertexData();
    MyBabylon.scene.cleanCachedTextureBuffer();
    // MyBabylon.scene.render();
}

const SetDefaultMobileDealer = () => {
    if (!store.getters.GET_TempDealer) {
        const Model = store.state.dealer.availableModels[1];
        store._mutations.SET_TempDealer[0](Model);
    };
}

export {
    ClearDealerScene,
    SetCamera,
    MoveCameraToDealer,
    SelectDealerScene,
    SetDefaultMobileDealer,
    HideLoadingUICallBack,
    Optimization,
}