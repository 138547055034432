<template>
  <div class="MenuContent" id="MenuContent" :class="{ inMobileMenu: inMobileMenu }">
    <div class="wrap" id="MenuContentWrap">
      <div class="level1" id="MenuContentLevel1">
        <VolumeControll />
        <!-- Settings -->
        <MenuContentItem icon="settings.svg" name="Settings" :subLevel="true" />
        <!-- Game rules -->
        <MenuContentItem
          icon="game-rules.svg"
          name="Game rules"
          :subLevel="false"
          @click.native="$popup.show('GameRules'), SHOW_HIDE_Menu()"
        />
        <!-- History -->
        <MenuContentItem
          icon="history.svg"
          name="History"
          :subLevel="false"
          @click.native="$popup.show('History'), SHOW_HIDE_Menu()"
        />
        <!-- Provably Fair -->
        <MenuContentItem
          icon="provably-fair.svg"
          name="Provably Fair"
          :subLevel="false"
          @click.native="$popup.show('ProvablyFair'), SHOW_HIDE_Menu()"
        />
        <!-- Select Dealer -->
        <MenuContentItem
          v-if="GET_Dealer"
          icon="dealer.svg"
          name="Select Dealer"
          :subLevel="false"
          @click.native="SelectDealerClick"
        />
      </div>
      <div class="level2" id="MenuContentLevel2">
        <!-- Settings -->
        <div class="secondLevelItem" data-name="Settings">
          <div class="titleRow" @click="HIDE_MenuSecondLevel()">
            <img class="arrowLeft" src="@/assets/img/arrow-left.svg" alt="arrow-left" />
            <span>Settings</span>
          </div>
          <!-- Squeeze On -->
          <SettingsItem
            name="Squeeze On"
            :activeDefault="true"
            :inMobileMenu="inMobileMenu"
          />
          <!-- Audio -->
          <!-- <SettingsItem name="Audio" :activeDefault="true" :inMobileMenu="inMobileMenu" /> -->
          <!-- Sound effects -->
          <!-- <SettingsItem
            name="Sound effects"
            :activeDefault="true"
            :inMobileMenu="inMobileMenu"
          /> -->
          <!-- Background music -->
          <SettingsItem
            name="Background music"
            :activeDefault="true"
            :inMobileMenu="inMobileMenu"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import MenuContentItem from "./MenuContentItem/";
import SettingsItem from "./SettingsItem/";
import VolumeControll from "./VolumeControll/";

export default {
  name: "MenuContent",
  components: { MenuContentItem, SettingsItem, VolumeControll },
  props: {
    inMobileMenu: { default: false },
  },
  computed: {
    ...mapGetters(["GET_Dealer"]),
  },
  methods: {
    ...mapActions(["HIDE_MenuSecondLevel", "SHOW_HIDE_Menu", "RESELECT_Dealer"]),
    SelectDealerClick() {
      this.RESELECT_Dealer();
      this.$popup.hide();
      this.SHOW_HIDE_Menu(true);
      this.$Countly.add_event({
        key: "re_select_dealer",
      });
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
