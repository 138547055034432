<template>
  <Layout id="Statistics" title="Statistics" :disableScroll="true" class="smallTitle">
    <ShoeTable class="inMobileMenu" />
    <StatisticsTable class="inMobileMenu" />
  </Layout>
</template>

<script>
import Layout from "@/components/Modals/PopupLayout";
import StatisticsTable from "@/components/StatisticsTable/";
import ShoeTable from "@/components/ShoeTable/";
export default {
  name: "StatisticsModal",
  components: {
    Layout,
    StatisticsTable,
    ShoeTable,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="less" scoped src="./component.less"></style>
<style lang="less">
#Statistics {
  .popupContent {
    padding-left: 0;
  }
  .content {
    padding-right: 0;
  }
  .h2.title {
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
