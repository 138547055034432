const simple3d = !true;
const fastAnimations = process.env.fastAnimations;
// const animationSpeed = fastAnimations ? 5 : 1;
const animationSpeed = 1;

export { animationSpeed, simple3d };
import { PlayAnimation, Animate_Dealer, GetAnimatablesWeight, } from './functions/animations'
import { LoadModels, ConvertCardTextureData, ResetCardAnimation } from './functions/models'
import { checkResizeInit } from './functions/responsive'
import { ClearDealerScene, SetCamera, SelectDealerScene } from './functions/'

let viewportUnitsBuggyfill = require("viewport-units-buggyfill");
import store from "@/store/";
import {
    Engine,
    HemisphericLight,
    Vector3,
    Scene,
    Color3,
    DirectionalLight,
    Sound,
    // ShadowGenerator,
} from 'babylonjs';
import 'babylonjs-loaders';

// Sounds
import background_sound from "@assets/audio/background.mp3"
const soundsList = [
    { name: "Background music", sound: background_sound }
]

const MyBabylon = class babylon {
    characterRoot = {};
    meshes = {};
    AnimationGroups = {};
    nextCharacterAnimationQueue = {};
    currentAnimation = null;
    constructor() {
        this.sound = {};
        this.halfWidth = window.innerWidth / 2;
        this.halfHeight = window.innerHeight / 2;
        this.animations = [];
        this.tableRoot,
            this.cardsRoot,
            this.devicesRoot,
            this.urrentParam,
            this.skybox,
            this.camera;
        this.ResetCardAnimationNow = false;
        this.buttonsList = [];
        this.paramsList = [];
        this.forceBeforeAnimation = false;
        this.cardAnimations = [];
        this.cardsList = [];
        this.cardsListInit = [];
        this.cardsMatList = [];
        this.cardsMatListInit = [];
        this.scene = undefined;
        this.bottomShift = 4;
        this.onBeforeAnimationOnce = false;
        this.shadowGenerator, this.engine;
        this.timerResize, this.UiPanelLeft, this.UiPanel;
        // this.tween = new TimelineLite();
        this.ratio = window.devicePixelRatio;
        this.halfWidth = window.innerWidth / 2;
        this.halfHeight = window.innerHeight / 2;
        this.mouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        this.camPosObj = { x: 0, y: 0 }
        this.mouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        this.viewport = viewportUnitsBuggyfill;
    }

    ClearDealerScene = ClearDealerScene;
    PlayAnimation = PlayAnimation;
    Animate_Dealer = Animate_Dealer;
    ResetCardAnimation = ResetCardAnimation;
    GetAnimatablesWeight = GetAnimatablesWeight;
    SelectDealerScene = SelectDealerScene;

    ReconnectCallback() {
        const { state } = store.getters.GET_GameState;
        if (this.currentAnimation == 'anim_4') {
            PlayAnimation('idle');
        }

        if (state == 'NO_GAME' && store.getters.GET_Dealer && !this.ResetCardAnimationNow) {
            this.ResetCardAnimation();
        }
        console.log('ReconnectCallback', this.currentAnimation);

    };

    resize(e) {
        this.halfWidth = window.innerWidth / 2;
        this.halfHeight = window.innerHeight / 2;
        this.canvas.style.width = window.innerWidth + 'px';
        this.canvas.style.height = window.innerHeight + 'px';
        this.viewport.refresh();
        this.engine.resize();
        this.viewport.refresh();
        clearTimeout(this.timerResize);

        this.timerResize = setTimeout(() => {
            this.halfWidth = window.innerWidth / 2;
            this.halfHeight = window.innerHeight / 2;
            this.canvas.style.width = window.innerWidth + 'px';
            this.canvas.style.height = window.innerHeight + 'px';
            this.viewport.refresh();
            this.engine.resize();
            this.viewport.refresh();
            store.commit('SET_WindowWidth', window.innerWidth);
            store.commit('SET_WindowHeight', window.innerHeight);
            SetCamera();
        }, 500);
    };



    // createScene function that creates and return the scene
    createScene() {
        var scene = new Scene(this.engine);

        // Lights
        var light = new HemisphericLight("light1", new Vector3(0, 1, 0), scene);
        light.intensity = 1.4;
        light.specular = Color3.Black();

        var light2 = new DirectionalLight("dir01", new Vector3(0, -5, 0), scene);
        light2.position = new Vector3(0, 8, 0);
        light2.intensity = 2;

        // Shadows
        // this.shadowGenerator = new ShadowGenerator(1024, light2);
        // this.shadowGenerator.useBlurExponentialShadowMap = true;
        // this.shadowGenerator.blurKernel = 32;

        window.scene = scene;
        this.engine._hardwareScalingLevel = 1.0 / (window.devicePixelRatio || 1.0);
        // this.engine._hardwareScalingLevel = 5;

        scene.clearCachedVertexData();
        scene.cleanCachedTextureBuffer();

        scene.autoClear = false; // Color buffer
        scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously

        this.viewport.init({
            force: true,
            refreshDebounceWait: 250,
            hacks: window.viewportUnitsBuggyfillHacks,
        });
        this.resize();
        return scene;
    }

    async init3dGame() {
        // get the canvas DOM element
        this.canvas = document.getElementById('renderCanvas');
        this.divFps = document.getElementById("fps");
        // 

        // this.divFps.style.display = 'block';
        // load the 3D this.engine
        this.engine = new Engine(this.canvas, true, {
            alpha: false,
            enableOfflineSupport: false,
            doNotHandleContextLost: true,
            blockMaterialDirtyMechanism: true, // Blocking the dirty mechanism
            autoClearDepthAndStencil: false,  // Depth and stencil, obviously
        }, true);
        // call the createScene function
        this.scene = this.createScene();

        // This creates and positions a free camera (non-mesh)
        SetCamera();

        await LoadModels();

        // run the render loop
        this.engine.runRenderLoop(() => {
            if (!store.getters.GET_ConnectionLost && !store.getters.GET_LoadingError) {
                this.scene.render();
                this.divFps.innerHTML = this.engine.getFps().toFixed() + " fps";
            }
        });
        window.addEventListener("resize", this.resize.bind(this));
        // this.scene.debugLayer.show();
        checkResizeInit();
    }

    ConvertCardTextureData = ConvertCardTextureData

    async initSound() {
        // this.scene.audioEnabled = 1;
        // BABYLON.Engine.audioEngine.unlock()
        // const promiseList = [];
        soundsList.forEach(({ name, sound }) => {
            //     //     promiseList.push(new Promise((resolve, reject) => {
            //     // this.sound[name] = new Sound(name, sound, this.scene, resolve, { loop: true });
            //     //     }))
            this.sound[name] = sound;
        });
        store.commit('SET_SoundsList', soundsList)
        // return Promise.all(promiseList);
    }
}


const my = new MyBabylon()
window.MyBabylon = my;
export default my