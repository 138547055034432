<template>
  <div class="StatisticsTable">
    <div class="statisticsTabs">
      <div class="scrollWrap" ref="scrollWrap">
        <div class="wrap">
          <div
            class="tabsItem"
            v-for="tabItem in tabsList"
            :key="tabItem"
            :class="{ active: activeTab == tabItem }"
            @click="tabClick(tabItem, $event)"
          >
            {{ tabItem }}
          </div>
        </div>
      </div>
    </div>
    <BeadRoad
      v-if="activeTab == 'Bead road'"
      :key="key + '_Bead road_' + GET_HistoryDataKey"
    />
    <BigRoad
      v-show="activeTab == 'Big Road'"
      :key="key + '_Big Road_' + GET_HistoryDataKey"
    />
    <BigEyeBoy
      v-if="activeTab == 'Big Eye Boy'"
      :key="key + '_Big Eye Boy_' + GET_HistoryDataKey"
    />
    <SmallRoad
      v-if="activeTab == 'Small Road'"
      :key="key + '_Small Road_' + GET_HistoryDataKey"
    />
    <CockroachPig
      v-if="activeTab == 'Cockroach Pig'"
      :key="key + '_Cockroach Pig_' + GET_HistoryDataKey"
    />
    <BeadPlate
      v-if="activeTab == 'Bead Plate'"
      :key="key + '_Bead Plate_' + GET_HistoryDataKey"
    />
  </div>
</template>

<script>
import { TweenMax } from "gsap";
import { mapGetters } from "vuex";
import BeadRoad from "./BeadRoad/";
import BigRoad from "./BigRoad/";
import BigEyeBoy from "./BigEyeBoy/";
import SmallRoad from "./SmallRoad/";
import CockroachPig from "./CockroachPig/";
import BeadPlate from "./BeadPlate/";

export default {
  name: "StatisticsTable",
  components: {
    BeadRoad,
    BigRoad,
    BigEyeBoy,
    SmallRoad,
    CockroachPig,
    BeadPlate,
  },

  data: function () {
    return {
      key: 0,
      activeTab: "Bead road",
      tabsList: [
        "Bead road",
        "Big Road",
        "Big Eye Boy",
        "Small Road",
        "Cockroach Pig",
        "Bead Plate",
      ],
      settings: {
        suppressScrollY: !false,
        suppressScrollX: !true,
        wheelPropagation: true,
        useBothWheelAxes: true,
      },
    };
  },
  computed: {
    ...mapGetters(["GET_HistoryDataKey"]),
  },
  methods: {
    tabClick(tabItem, e) {
      this.activeTab = tabItem;
      this.key++;
      let scrollLeft = 0;
      this.prevAll(e.target, []).forEach((prev) => {
        const marginRight = prev.style.marginRight;
        const marginLeft = prev.style.marginLeft;
        scrollLeft +=
          prev.clientWidth +
          (marginLeft != "" ? parseInt(marginLeft) : 0) +
          (marginRight != "" ? parseInt(marginRight) : 0);
      });
      TweenMax.to(this.$refs.scrollWrap, 0.25, { scrollLeft: scrollLeft / 2, ease: 'Power2.easeOut'});
    },
    scrollHanle(evt) {
      const scrollLeft = Math.floor(evt.target.scrollLeft);

      // console.log("scrollLeft", scrollLeft);
    },
    prevAll(element, result) {
      if (element.previousElementSibling) {
        result.push(element.previousElementSibling);
        element = element.previousElementSibling;
        this.prevAll(element, result);
      }
      return result;
    },
  },
};
</script>
<style lang="less" src="./component.less"></style>
