import store from "@/store/"

const COMPARE_LEFT = (n = 1, row, column) => {
    const left = store.getters.GET_BigRoad.table[row][column - n];
    const top = store.getters.GET_BigRoad.table[row - 1][column - n];
    if (!left && top || left && !top) return false;
    return (!left && !top) || (left.winner == top.winner)
};

const COMPARE_COLUMNS = (n = 1, column) => store.getters.GET_BigRoad.columns[column - 1].count == store.getters.GET_BigRoad.columns[column - 1 - n].count;

const CREATE_TABLE = () => {
    const { columns, rows } = store.getters.GET_StatisticsTableSettings;
    let rowsArr = new Array(rows);
    for (let index = 0; index < rowsArr.length; index++) {
        rowsArr[index] = new Array(columns);
    }
    return rowsArr;
}

const SLICE_TABLE_DATA = (columnIndex, table) => {
    const { columns, rows } = store.getters.GET_StatisticsTableSettings;
    let newTableData = table.slice();
    if (columnIndex >= columns) {
        newTableData = newTableData.map((row) => {
            const newRow = [];
            for (let index = 0; index <= columnIndex; index++) {
                newRow[index] = row[index];
            }
            return newRow.slice(newRow.length - columns);
        });
    }
    newTableData = newTableData.slice(0, rows);
    return newTableData;
}

const BUILD_DERIVED_TABLE = (list, n = 0) => {

    const table = CREATE_TABLE();

    let bigRoadCol, bigRoadRow, activeCell;
    let columnIndex = 0;
    let rowIndex = 0;
    const derrivedList = list.map((historyElem, index) => {
        let result = false;
        // result == true (red)
        // result == false (blue)
        bigRoadCol = historyElem.column;
        bigRoadRow = historyElem.row;
        if (bigRoadRow == 0) {
            result = COMPARE_COLUMNS(n, bigRoadCol);
        } else {
            result = COMPARE_LEFT(n, bigRoadRow, bigRoadCol);
        }
        return {
            index,
            result,
            color: result ? "red" : "blue",
            bigRoadCell: store.getters.GET_BigRoad.table[bigRoadRow][bigRoadCol],
        };
    });


    derrivedList.forEach((derrived, index, arr) => {
        derrived.difference = !index ? false : derrived.result != arr[index - 1].result;

        if (derrived.difference && !derrived.result) {
            rowIndex = 0;
        }
        if (derrived.difference && derrived.result) {
            rowIndex = 0;
            columnIndex++;
        }

        if (rowIndex + 1 > store.getters.GET_StatisticsTableSettings.rows) return true;

        if (!table[rowIndex][columnIndex]) {
            table[rowIndex][columnIndex] = { redCount: 0, blueCount: 0 };
        }

        activeCell = table[rowIndex][columnIndex];
        activeCell.derrived = derrived;
        activeCell.result = derrived.result;
        activeCell.difference = derrived.difference;
        activeCell[derrived.result ? "redCount" : "blueCount"]++;

        if (
            (!activeCell.difference || !rowIndex) &&
            activeCell[derrived.result ? "redCount" : "blueCount"] >= 2
        )
            rowIndex++;
    });

    return SLICE_TABLE_DATA(columnIndex, table);
}

export { BUILD_DERIVED_TABLE, SLICE_TABLE_DATA, CREATE_TABLE }