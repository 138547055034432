<template>
  <table class="statisticsTable BeadRoadTable">
    <tbody>
      <tr v-for="(row, rowIndex) in sliceTableData" :key="name + '_row_' + rowIndex">
        <td
          v-for="(column, columnIndex) in row"
          :key="name + '_column_' + columnIndex"
          :style="'width:' + 100 / sliceTableData[0].length + '%'"
        >
          <span
            :class="[
              column.winner,
              { pairBanker: column.pairBanker },
              { pairPlayer: column.pairPlayer },
            ]"
            v-if="column"
            >{{ column.score }}</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import { SLICE_TABLE_DATA } from "../compare";
export default {
  name: "BeadRoadTable",
  data() {
    return {
      name: "BeadRoadTable",
      table: null,
      sliceTableData: null,
      columnIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["GET_AllHistoryaReverse", "GET_StatisticsTableSettings"]),
  },
  methods: {
    getHistoryInfo(history) {
      const totalDealerScore = parseInt(history.totalDealerScore);
      const totalPlayerScore = parseInt(history.totalPlayerScore);
      const score =
        totalDealerScore > totalPlayerScore ? totalDealerScore : totalPlayerScore;
      let winner = totalDealerScore > totalPlayerScore ? "banker" : "player";
      if (totalDealerScore == totalPlayerScore) winner = "tie";
      return { score, winner };
    },
    buildTable() {
      if (this.GET_AllHistoryaReverse && this.GET_AllHistoryaReverse.length) {
        this.GET_AllHistoryaReverse.forEach((element, index) => {
          const { score, winner } = this.getHistoryInfo(element);
          const pair = (name) => {
            if (winner === "tie") return false;
            const cards = element.resultTable[name].cards.split(", ");
               if (cards[0][0] === cards[1][0]) return true;
            // if (
            //   cards[0][0] === cards[1][0] ||
            //   (cards[2] != undefined &&
            //     (cards[1][0] === cards[2][0] || cards[0][0] === cards[2][0]))
            // )
            //   return true;
            return false;
          };
          if (index % this.table.length == 0 && index > 0) this.columnIndex++;
          this.table[index % this.table.length][this.columnIndex] = {
            score: score,
            winner: winner,
            pairBanker: pair("BANKER"),
            pairPlayer: pair("PLAYER"),
          };
        });
      }
    },
  },
  created() {
    let rows = new Array(this.GET_StatisticsTableSettings.rows);
    for (let index = 0; index < rows.length; index++) {
      rows[index] = new Array(this.GET_StatisticsTableSettings.columns);
    }
    this.table = rows;
  },
  mounted() {
    this.buildTable();
    this.sliceTableData = SLICE_TABLE_DATA(this.columnIndex, this.table);
  },
};
</script>

<style lang="less" scoped src="../BeadRoadTable.less"></style>
<style lang="less" scoped src="./component.less"></style>