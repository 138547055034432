<template>
  <div class="MenuContentItem" @click="click">
    <div class="icon">
      <img v-if="icon" :src="image" :alt="name" />
    </div>
    <span>{{ name }}</span>
    <img class="arrow" v-if="subLevel" src="./img/arrow.svg" alt="arrow" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MenuContentItem",
  props: {
    icon: { default: "" },
    name: { default: "" },
    subLevel: { default: false },
  },
  computed: {
    image() {
      return require("./img/" + this.icon);
    },
  },
  methods: {
    ...mapActions(["SHOW_MenuSecondLevel"]),
    click() {
      if (this.subLevel) this.SHOW_MenuSecondLevel(this.name);
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
