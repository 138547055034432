import { Vector3, FreeCamera } from 'babylonjs';

import { TweenLite, Power1, Power0 } from "gsap";
import MyBabylon from '../index'
import store from "@/store/";

const SetCamera = () => {
    const showAllModels = !store.getters.GET_Dealer;
    if (!MyBabylon.camera) {
        MyBabylon.camera = new FreeCamera("camera1", new Vector3(0, 7 + MyBabylon.bottomShift, -8.3), MyBabylon.scene);
        MyBabylon.camera.setTarget(new Vector3(0, 4, 4));
    }
    MyBabylon.camera.position.z = showAllModels ? -9 : -8;
    if (window.innerWidth <= 620) {
        MyBabylon.camera.rotation.x = 0.5;
        // MyBabylon.camera.position.y = showAllModels ? 12 : 13.3;
        // MyBabylon.camera.position.z = showAllModels ? -10 : -13;
        MyBabylon.camera.position.y = showAllModels ? 11.4 : 13.3;
        MyBabylon.camera.position.z = showAllModels ? -8 : -13;
    } else if (window.innerWidth < 991) {
        MyBabylon.camera.rotation.x = 0.5;
        MyBabylon.camera.position.y = showAllModels ? 14 : 13.3;
        MyBabylon.camera.position.z = showAllModels ? -13 : -13;
    } else {
        MyBabylon.camera.position.y = showAllModels ? 11.6 : (7 + MyBabylon.bottomShift);
        MyBabylon.camera.rotation.x = 0.43;
        MyBabylon.camera.position.set(0, 7 + MyBabylon.bottomShift, -8)
    }
    if (store.getters.GET_Dealer) {
        MyBabylon.camera.position.x = 0;
    }
};

const MoveCameraToDealer = (Model = store.getters.GET_TempDealer) => {
    console.log('MoveCameraToDealer', Model);
    const index = parseInt(Model.replace('Girl_', ''));
    const x = (() => {
        if (index == 1) return -5;
        if (index == 3) return 5;
        return 0;
    })()
    TweenLite.to(MyBabylon.camera.position, 0.35, {
        x, ease: Power1.easeOut, onComplete: () => {
            MyBabylon.PlayAnimation('fade', Model);
        }
    });
}

export { SetCamera, MoveCameraToDealer }