import { TweenLite } from "gsap";

export default {
    install(Vue, { store, axios }) {
        Vue.prototype.$popup = {
            hide_popup_timer: undefined,
            shift: 100,
            openModal: false,
            timeAnimation: 0.75,
            ease: 'Power3.easeInOut',
            easeFast: 'Power4.easeInOut',
            animTime: 0.5,
            isVisible: (el) => window.getComputedStyle(el).display !== 'none',
            mobile: () => window.innerWidth <= 991,
            show: function (target_block, event, $this) {
                TweenLite.to('.popup_bg', this.animTime, {
                    display: "block",
                    opacity: 1
                })
                store.commit('SET_ModalOpen', { open: true, name: target_block });
                const block = document.querySelector('div.popup_block.' + target_block);
                let set_time = 0;
                if (this.openModal) {
                    let visibleBlock = Array.prototype.slice.call(document.querySelectorAll('.popup_block')).filter(el => this.isVisible(el));
                    TweenLite.fromTo(visibleBlock, this.timeAnimation / 2, {}, { y: this.shift / 5, opacity: 0, ease: this.easeFast });
                    // если есть видимая модалка то даем ей плавно скрыться
                    set_time = this.timeAnimation * 1000 / 2;
                }
                // 
                this.openCallBack(target_block, block);
                // 
                setTimeout(() => {
                    if (block) store.dispatch('SHOW_Popup', target_block)
                    document.querySelectorAll('.popup_block').forEach(el => {
                        el.removeAttribute('style')
                    })

                    clearTimeout(this.hide_popup_timer);
                    block.style.height = "";
                    TweenLite.set(block, { display: "block" })
                    TweenLite.set('div.popup_block.' + target_block + ' .popupContent', { scrollTop: 0 });
                    // block.querySelector(".content").scrollTop = 0;

                    TweenLite.fromTo(block,
                        this.timeAnimation,
                        {
                            opacity: 0,
                            y: this.shift
                        },
                        {
                            opacity: 1,
                            y: 0,
                            ease: this.ease,
                            onComplete: () => {
                                this.openModal = true;
                                block.querySelector(".content").scrollTop = 0;
                                block.style.transform = '';
                            },
                        }
                    );
                }, set_time);
            },
            hide: function (target_block) {
                const visibleElems = Array.prototype.slice.call(document.querySelectorAll('.popup_block')).filter(el => this.isVisible(el));
                let target_blockDiv = document.querySelectorAll('div.popup_block.' + target_block)
                const block = target_blockDiv.length ? target_blockDiv : visibleElems;
                const hasOpenUserMenu = document.querySelector("#menuPopup.show") !== null ? true : false;
                store.commit('SET_ModalOpen', { open: false, name: block });
                if (this.openModal || visibleElems.length) {
                    setTimeout(() => {
                        TweenLite.to('.popup_bg', this.animTime, {
                            display: "none",
                            opacity: 0
                        })
                    }, this.timeAnimation / 2);
                    TweenLite.fromTo(block, this.timeAnimation, {},
                        {
                            opacity: 0,
                            y: this.shift,
                            ease: this.ease,
                            onComplete: () => {
                                // block.find(".content_wrap_inner, .popupWrap").scrollTop(0);
                                TweenLite.set(".popup_block", { display: '' })
                                Array.prototype.slice.call(document.querySelectorAll('.popup_block')).forEach(el => {
                                    el.style.display = 'none';
                                    el.style.opacity = ''
                                })
                                this.openModal = false;
                                TweenLite.set(".popup_bg, header", { "z-index": '' });
                                this.closeCallBack(block);
                            },
                        }
                    );
                };
            },
            openCallBack: function (target_block, block) {
                setTimeout(() => {
                    const content = block.querySelector('.content');
                    content.dispatchEvent(new Event('scroll', { bubbles: true }))
                    content.classList.add("scrolledTop");
                }, 250);

                switch (target_block) {
                    case 'Menu':
                        store.commit('SET_MenuOpen', true);
                        setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 100);
                        setTimeout(() => {
                            const content = block.querySelector('.content');
                            content.classList.remove("ps--active-y");
                            content.dispatchEvent(new Event('scroll', { bubbles: true }))
                            content.classList.add("scrolledTop");
                            content.classList.remove("ps--active-y");
                        }, 250);
                        break;
                    default:
                        break;
                }
            },
            closeCallBack: function (target_block, block) {
                store.dispatch('HIDE_Popup', target_block)
                target_block.forEach(element => {
                    // Menu
                    if (element.classList.contains('Menu')) {
                        if (store.getters.GET_MenuSecondLevel) {
                            store.dispatch('HIDE_MenuSecondLevel');
                        }
                    }
                });


            }
        }
    }
}