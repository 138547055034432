export default {
    install(Vue, { store, axios }) {
        Vue.prototype.$game = {
            async loginPlatform() {
                const request = await Vue.prototype.$axios({
                    method: "post",
                    url: "/api/v2/login/platform"
                }).then(({ data, status, headers }) => {
                    if (headers["x-auth-token"]) {
                        Vue.prototype.$axios.defaults.headers["X-Auth-Token"] = headers["x-auth-token"]; // x-auth-token
                    };
                }).catch(e => {
                    store.commit('SET_NeedReloadPage', true)
                })
                return request;
            },
            async getState() {
                const request = await Vue.prototype.$axios({
                    method: "get",
                    url: "/api/v1/baccarat/state"
                }).then(({ data, status, headers }) => {
                    store.commit("SET_GameState", { ...store.state.gameState, ...data });
                    const { betLimit, currency } = data.config;
                    store.dispatch("SET_Currency", { currency, betLimit });
                    return data;
                }).catch(async (error) => {
                    await this.playInit();
                })
                return request;
            },
            async history() {
                const request = await Vue.prototype.$axios({
                    method: "get",
                    url: "/api/v1/baccarat/history"
                }).then(({ data }) => {
                    store.commit('SET_HistoryData', data)
                    return data
                });
                return request;
            },
            async playInit(callback = () => { }) {
                console.log('playInit');
                const request = await Vue.prototype.$axios({
                    method: "post",
                    url: "/api/v1/baccarat/play",
                    data: { type: "INIT" },
                    play: true,
                }).then(({ data }) => {
                    const { betLimit, currency } = data.config;
                    store.dispatch("SET_Currency", { currency, betLimit });
                    store.dispatch("GENERATE_PlayerSeed");
                    store.dispatch('PlayInit', data)
                    callback();
                });
                return request;
            },
            async playDeal(callBack = () => { }) {
                if (store.getters.GET_DisableAPIRequests) return false;
                // if user set empty seed string
                if (!store.getters.GET_PlayerSeed.length) {
                    store.dispatch('GENERATE_PlayerSeed');
                };
                const request = await Vue.prototype.$axios({
                    method: "post",
                    url: "/api/v1/baccarat/play",
                    play: true,
                    data: {
                        type: "DEAL",
                        clientSeed: store.getters.GET_PlayerSeed,
                        bets: store.getters.GET_BetsData,
                        gameId: store.getters.GET_GameState.gameId,
                    }
                }).then(({ data }) => {
                    store.dispatch('PlayDeal', data)
                    callBack();
                });
                return request;
            },
            async gameResolve() {
                if (store.getters.GET_DisableAPIRequests) return false;
                const request = await Vue.prototype.$axios({
                    method: "post",
                    url: "/api/v1/baccarat/play",
                    play: true,
                    data: {
                        type: "RESOLVE",
                        gameId: store.getters.GET_GameState.gameId,
                    }
                }).then(({ data }) => {
                    store.dispatch('GameResolve', data)
                });
                return request;
            },
            async setStorage(data = {}) {
                store.commit('UPDATE_Settings', data);
                const request = await Vue.prototype.$axios({
                    method: "POST",
                    url: "/api/v1/client/storage",
                    data: { data },
                }).then(({ data, status, headers }) => {
                }).catch(error => {
                    //     this.playInit();
                })
                return request;
            },
            async ping() {
                const request = await Vue.prototype.$axios({
                    method: "get",
                    url: "/api/v1/long-ping",
                    ping: true,
                    params: {
                        configId: store.getters.GET_GameState.config.id,
                        gameType: 'BACCARAT',
                    },
                }).then(({ status }) => {
                    return status
                }).catch((error) => {
                    return 404
                })
                return request;
            },
        }
    }
}