import { ICurrenciesConfig } from './ICurrenciesConfig';

export default class ICurrencyFormatter {
    NBSP = '\xa0';
    NNBSP = '\u202F';
    _IS_MAC_LIKE = !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
    _minPrecision = 0; // minimum allowed precision
    _currenciesConfig = ICurrenciesConfig;
    _options = {
        symbol: '$',
        format: '%v %s',
        decimalSeparator: '.',
        thousandsSeparator: ',',
        precision: 2,
    }

    constructor() { }

    get minPrecision() {
        return this._minPrecision;
    }

    set minPrecision(precision) {
        this._minPrecision = precision;
    }

    set setBalance(balance) {
        this._balance = balance;
    }

    setCurrency(currency, limit) {
        if (this._currencyCode === currency) return;
        this._currencyCode = currency;
        if (this._currenciesConfig.hasOwnProperty(this._currencyCode)) {
            this._options = { ...this._options, ...this._currenciesConfig[this._currencyCode] }
        } else {
            this._options = { ...this._options, ...this._currenciesConfig.FUN }
            this._options.symbol = this._currencyCode;
        }
        this.setPrecision(limit)
    }

    replaceSpaceWithNBSP(str) {
        return str.replace(/ /g, this._IS_MAC_LIKE === true ? this.NBSP : this.NNBSP);
    }

    formatCurrency(value) {
        const formattedValue = this._options.format
            .replace('%s', this._options.symbol)
            .replace('%v', this.formatNumber(value, this._options));
        return this.replaceSpaceWithNBSP(formattedValue);
    }

    formatNumber(value, options = this._options, forcePrecision = false) {
        let precision = ~~options.precision;
        if (!forcePrecision) {
            precision = Math.max(this._minPrecision, ~~options.precision);
        }
        const reg = '\\d(?=(\\d{' + 3 + '})+' + (precision > 0 ? '\\D' : '$') + ')';

        const targetValNum = this.roundDown(value, precision);
        const targetVal = targetValNum.toFixed(precision);

        const result = targetVal
            .replace('.', options.decimalSeparator)
            .replace(new RegExp(reg, 'g'), '$&' + options.thousandsSeparator);

        return this.replaceSpaceWithNBSP(result);
    }

    format(value, getOnlyNumbers = false, options, forcePrecision) {
        // for 'INR' only
        if (this._currencyCode === 'INR') {
            const formatted = value
                .toLocaleString('en-IN', {
                    currency: 'INR',
                    maximumFractionDigits: 2,
                    style: 'currency',
                })
                // TODO
                // eslint-disable-next-line no-irregular-whitespace
                .replace(/ /g, '');
            return getOnlyNumbers ? formatted.split('').slice(1, -1).join('') : formatted;
        }

        return getOnlyNumbers ? this.formatNumber(value, options, forcePrecision) : this.formatCurrency(value);
    }

    roundDown(number, decimals = 0) {
        let strNumber = number + '';
        const io = strNumber.lastIndexOf('.');
        const lastNum = io + decimals;
        if (io >= 0) {
            if (lastNum >= 0 && lastNum < strNumber.length) {
                strNumber = strNumber.substring(0, lastNum + 1);
            }
        }
        return parseFloat(strNumber);
    }

    get currency() {
        return this.format(this._balance)
    }

    setPrecision(limit = 0.05) {
        let precision = 2;
        const split = limit.toString().split('.');
        if (split.length > 1) precision = split[split.length - 1].length;
        this._options.precision = precision;
    }

}