import store from "./store";

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const rndUserId = () => 'redlab_user_' + rnd(1, 9585);

const createGameGetParams = (params) => {
    let paramsSrt = '';
    for (const key in params) {
        paramsSrt += key + '=' + params[key] + '&'
    }
    return paramsSrt
}

const rnd = (min, max) => {
    var rand = min - 0.5 + Math.random() * (max - min + 1);
    rand = Math.round(rand);
    return rand;
};

export default () => {
    const session_id = getParameterByName('session_id');
    if (session_id == null) { // redirect
        const url = 'https://qa-core.onetouch.io/sandbox/game/redirect?';
        const params = {
            userId: rndUserId(),
            subPartnerId: '',
            isIframe: false,
            isDevtool1: false,
            isDevtool2: false,
            isDemo: false,
            configId: '5d495eafb0d3eb00019fc5cf',
            lobbyUrl: 'https://onetouch.io',
            devicePlatform: 'all',
            siteUrl: window.location.href,
            engineBaseUrl: 'https://qa-core.onetouch.io',
            initialBalance: ''
        };
        window.location.href = url + createGameGetParams(params);
    } else {
        store.commit('SET_Session_id', session_id)
    }
}