<template>
  <div class="Audio" v-show="false">
    <audio
      controls
      preload="auto"
      v-for="(item, index) in GET_SoundsList"
      :key="index"
      :src="item.sound"
      class="SoundElem"
      :id="('sound_' + item.name) | name"
      :name="item.name"
      ref="audio"
    >
      {{ item.name }}
    </audio>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import LoadingIcon from "@/components/LoadingIcon.vue";
/**
 * The NEW component.
 */
export default {
  name: "Audio",
  components: {},
  props: {
    /**
     * The custom data
     */
    customData: { default: () => {} },
  },
  data: function () {
    return {
      unsubscribe: null,
    };
  },
  computed: {
    ...mapGetters(["GET_SoundsList"]),
  },
  created() {
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type == "SET_SoundsList") {
    //     setTimeout(() => {
    //       this.init();
    //     }, 150);
    //   }
    // });
  },
  filters: {
    name(val) {
      return val.replace(/ /gim, "_").toLowerCase();
    },
  },
  methods: {
    // ...mapActions([""]),
    // init() {
    //   console.log("this.$refs.audio", this.$refs.audio);
    //   const sound = {};
    //   this.$refs.audio.forEach((element) => {
    //     const name = element.getAttribute("name");
    //     sound[name] = element;
    //   });
    //   this.$store.commit("SET_Babylon_sound", sound);
    // },
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
