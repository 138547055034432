import MyBabylon from '../index'
import store from "@/store/";
import { MoveCameraToDealer } from './camera'
import { animationSpeed } from '../index'
import { TweenMax, TweenLite, Power0 } from "gsap";

const ease0 = Power0.easeNone;

const AnimationGroupEnd = function (anim) {
    // anim.syncAllAnimationsWith(null);
    // anim.onAnimationGroupEndObservable.clear()
    const nextAnimParam = DetectNextCharacterAnimation(anim);
    AnimationStopCallBack(anim, nextAnimParam)
}

const DetectNextCharacterAnimation = ({ name, model }) => {
    if (MyBabylon.nextCharacterAnimationQueue[model].length) {
        const nextParam = MyBabylon.paramsList.find(anim => {
            anim.name === MyBabylon.nextCharacterAnimationQueue[model][0]
            if (anim.name === MyBabylon.nextCharacterAnimationQueue[model][0]) {
                MyBabylon.nextCharacterAnimationQueue[model].shift();
                return true;
            }
            return false;
        });
        if (nextParam) return nextParam;
    };
    return MyBabylon.AnimationGroups[model].find(anim => anim.name === (!store.getters.GET_Dealer ? 'select_idle' : 'idle'));
}

const AnimationStopCallBack = function (current, next) {
    if (current.name === 'anim_3' && store.getters.GET_GameState.state === 'DEAL_DONE') {
        store.dispatch("GAME_DealCallFirstBack");
        store.dispatch("CONVERT_CardsTextureData");
        if (store.getters.GET_FlitCardName) {
            PlayAnimation(next.name, next.model, true);
        }
    } else if (current.name === 'anim_4' && store.getters.GET_GameState.state === 'DEAL_DONE') {
        store.dispatch('SET_PlayerCards', [true]);
        store.dispatch('SET_BankerCards', [true]);
        if (store.getters.NEED_FlitCard) {
            if (store.getters.GET_FlitCardName == 'banker') {
                store.dispatch('DETECT_DealerThirdCard');
            } else if (store.getters.GET_FlitCardName == 'player') {
                store.dispatch('DETECT_PlayerThirdCard');
            } else {
                const { thirdPlayerCard, thirdDealerCard, playerCards, dealerCards } = store.getters.GET_StateCards;
                if (thirdPlayerCard || thirdDealerCard) {
                    store.commit('SET_Controls', {
                        thirdCardNow: true,
                        rotatedCardNowIndex: thirdPlayerCard ? 2 : 5,
                        rotatedCardNow: thirdPlayerCard ? playerCards[2] :
                            dealerCards[2]
                    });
                    store.dispatch('SHOW_FlipCard');
                }
                if (!thirdPlayerCard && !thirdDealerCard) {
                    store.dispatch('SET_PairCards'); // ?
                }
            }
        } else {
            store.dispatch('SET_PairCards');
        }
    } else if ((current.name === 'anim_5B' || current.name === 'anim_5A' || current.name === 'anim_5P') && (store.getters.GET_GameState.state === 'DEAL_DONE' || store.getters.GET_GameState.state === 'RESOLVED')) {
        store.dispatch('SET_PairCards', true);
        setTimeout(() => { store.dispatch('SHOW_Results') }, 250);
    } else if ((current.name === 'anim_6' || current.name === 'anim_7' || current.name === 'anim_8') && !MyBabylon.ResetCardAnimationNow) {
        PlayAnimation('collect_cards');
    } else if (current.name === 'collect_cards' && !MyBabylon.ResetCardAnimationNow) {
        store.dispatch('GAME_DoneCallback')
        PlayAnimation('idle', undefined, true);
        // setTimeout(MyBabylon.ResetCardAnimation, 200)
    } else if (next) {
        PlayAnimation(next.name, next.model, true);
    };
}

const PlayCardAnimation = (name) => {
    MyBabylon.cardAnimations.forEach(anim => anim.name == name ? anim.play() : anim.stop())
}

const Animate_Dealer = (Model) => {
    if (window.innerWidth > 620) {
        PlayAnimation('fade', Model);
    } else {
        MoveCameraToDealer(Model);
    }
}

const PlayAnimation = function (name, Model = store.getters.GET_Dealer, important = false) {
    const animWeightTime = 0.1;
    const anim = MyBabylon.AnimationGroups[Model].find(anim => anim.name == name);
    // console.log('PlayAnimation', { name, important });
    if (anim && (!anim.isPlaying || important)) {
        MyBabylon.AnimationGroups[Model].forEach(animEach => {
            animEach.onAnimationGroupEndObservable.clear();
            animEach.syncAllAnimationsWith(null);
            // animEach.setWeightForAllAnimatables(0)
            if (animEach.name != name && animEach.weight > 0) {
                const obj = { weight: animEach.weight }
                TweenLite.to(obj, animWeightTime, {
                    weight: 0, ease: ease0, onUpdate: () => {
                        animEach.setWeightForAllAnimatables(obj.weight)
                    }, onComplete: () => { animEach.weight = 0 }
                })
            }
        });
        // anim.setWeightForAllAnimatables(1)
        // anim.weight = 1;
        anim.reset(); // important to reset animation
        if (anim.weight < 1 || !GetAnimatablesWeight(anim)) {
            const obj = { weight: anim.weight }
            TweenMax.to(obj, animWeightTime / 3, {
                weight: 1, onUpdate: () => {
                    anim.setWeightForAllAnimatables(obj.weight)
                }, onComplete: () => { anim.weight = 1 }
            });
        } else {
            anim.setWeightForAllAnimatables(1)
        }
        anim.play();
        anim.onAnimationGroupEndObservable.addOnce(() => { AnimationGroupEnd(anim) });
        PlayCardAnimation(name);
        MyBabylon.currentAnimation = name;
    }
    if (anim && anim.isPlaying) anim.setWeightForAllAnimatables(1)
    // 
    if (MyBabylon.cardsListInit.length) {
        const { thirdPlayerCard, thirdDealerCard } = store.getters.GET_StateCards;
        MyBabylon.cardsListInit[4].setEnabled(thirdPlayerCard);
        MyBabylon.cardsListInit[6].setEnabled(thirdPlayerCard);
        MyBabylon.cardsListInit[5].setEnabled(thirdDealerCard);
        MyBabylon.cardsListInit[7].setEnabled(thirdDealerCard);
    }
}

const InitAnimations = function () {
    MyBabylon.scene.animationGroups.forEach(anim => {
        const animParam = { name: anim.name, anim: anim, weight: 0 };
        anim.stop();
        anim.weight = 0;
        anim.setWeightForAllAnimatables(0);
        anim.syncAllAnimationsWith(null);
        MyBabylon.paramsList.push(animParam)
        anim.speedRatio = animationSpeed;
        // anim.onAnimationGroupPlayObservable.add(() => {
        anim.onAnimationGroupEndObservable.clear()
        // })
    });
}

const GetAnimatablesWeight = function ({ animatables }) {
    if (!animatables || !animatables.length) return null;
    let min = 2;
    animatables.forEach(({ _weight }) => {
        _weight < min ? min = _weight : null;
    });
    return min;
}

export { PlayAnimation, InitAnimations, Animate_Dealer, GetAnimatablesWeight }