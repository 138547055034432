import ICurrencyFormatter from './ICurrencyFormatter'


const state = {
    ICurrencyFormatter: new ICurrencyFormatter(),
}

const getters = {
    GET_Currency: (state, { GET_BalanceWithBets }) => {
        return state.ICurrencyFormatter.format(GET_BalanceWithBets)
    },
    GET_LastWin_Currency: (state, { GET_LastWin }) => {
        return state.ICurrencyFormatter.format(GET_LastWin);
    },
    GET_TotalBet_Currency: (state, { GET_TotalBet }) => {
        return state.ICurrencyFormatter.format(GET_TotalBet)
    },
    GET_BetLimit: (state, { GET_GameState }) => {
        return GET_GameState.config.betLimit;
    },
    GET_ICurrencyFormatter: state => {
        return state.ICurrencyFormatter
    },
}

const mutations = {}

const actions = {
    SET_Currency({ commit, state, getters }, { currency, betLimit }) {
        state.ICurrencyFormatter.setCurrency(currency, betLimit?.min);
    },
};

export default {
    state, getters, mutations, actions
}