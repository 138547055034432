import WebSocketPing from '../../../plugins/WebSocketPing'

const state = {
    connectionLost: false,
    loadingError: false,
    connection: 'api' // [socket, api]
}
const getters = {
    GET_ConnectionLost: state => state.connectionLost,
    GET_LoadingError: state => state.loadingError,
    GET_PingConnection: state => state.connection,
}
const mutations = {
    SET_ConnectionLost: (state, payload) => {
        state.connectionLost = payload;
    },
    SET_LoadingError: (state, payload = true) => {
        console.log('SET_LoadingError', payload);
        state.loadingError = payload;
    },
}
const actions = {
    INIT_PingConnection({ commit, state, getters, dispatch, app }, request) {
        if (state.connection == 'socket') {
            const ping = new WebSocketPing(this)
        } else if (state.connection == 'api') {
            setInterval(async () => {
                const status = await request();
                if (!getters.GET_ConnectionLost && status == 404) {
                    commit('SET_ConnectionLost', true);
                };
                if (getters.GET_ConnectionLost && status == 200 && !getters.GET_LoadingError) {
                    commit('SET_ConnectionLost', false);
                    getters.GET_Babylon.ReconnectCallback();
                };
            }, 2000);
        }
    }
}

export default {
    state, getters, mutations, actions
}