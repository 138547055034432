<template>
  <table class="LimitsTable">
    <template v-if="betTypeLimits">
      <thead>
        <tr>
          <th>bet</th>
          <th>Min/Max</th>
          <th>Payout</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Player</td>
          <td>
            {{ currency(betTypeLimits.BANKER_PLAYER_TYPE.min) }} /
            {{ currency(betTypeLimits.BANKER_PLAYER_TYPE.max) }}
          </td>
          <td>1:1</td>
        </tr>
        <tr>
          <td>banker</td>
          <td>
            {{ currency(betTypeLimits.BANKER_PLAYER_TYPE.min) }} /
            {{ currency(betTypeLimits.BANKER_PLAYER_TYPE.max) }}
          </td>
          <td
            v-html="
              IS_NO_COMMISSION_BACCARAT
                ? '1-1<br> Banker wins on 6 points 0.5-1'
                : '0.95-1 (5% Commission)'
            "
          ></td>
        </tr>
        <tr>
          <td>Tie</td>
          <td>
            {{ currency(betTypeLimits.TIE_TYPE.min) }} /
            {{ currency(betTypeLimits.TIE_TYPE.max) }}
          </td>
          <td>8:1</td>
        </tr>
        <tr>
          <td>player pair</td>
          <td>
            {{ currency(betTypeLimits.PLAYER_BANKER_PAIR_TYPE.min) }} /
            {{ currency(betTypeLimits.PLAYER_BANKER_PAIR_TYPE.max) }}
          </td>
          <td>11:1</td>
        </tr>
        <tr>
          <td>banker pair</td>
          <td>
            {{ currency(betTypeLimits.PLAYER_BANKER_PAIR_TYPE.min) }} /
            {{ currency(betTypeLimits.PLAYER_BANKER_PAIR_TYPE.max) }}
          </td>
          <td>11:1</td>
        </tr>
        <tr>
          <td>big</td>
          <td>
            {{ currency(betTypeLimits.BIG_TYPE.min) }} /
            {{ currency(betTypeLimits.BIG_TYPE.max) }}
          </td>
          <td>0.54:1</td>
        </tr>
        <tr>
          <td>small</td>
          <td>
            {{ currency(betTypeLimits.SMALL_TYPE.min) }} /
            {{ currency(betTypeLimits.SMALL_TYPE.max) }}
          </td>
          <td>1.5:1</td>
        </tr>
        <tr v-if="IS_NO_COMMISSION_BACCARAT" style="white-space: nowrap">
          <td>Lucky Six</td>
          <td>
            {{ currency(betTypeLimits.LUCKY_SIX_TYPE.min) }} /
            {{ currency(betTypeLimits.LUCKY_SIX_TYPE.max) }}
          </td>
          <td>
            3 cards win 20-1 2<br />
            cards win 12-1
          </td>
        </tr>
      </tbody>
    </template>
    <LoadingIcon :absolute="true" v-else />
  </table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LimitsTable",
  components: {},
  data: function () {
    return {
      ...this.data,
    };
  },
  computed: {
    ...mapGetters([
      "GET_GameState",
      "IS_NO_COMMISSION_BACCARAT",
      "GET_ICurrencyFormatter",
    ]),
    betTypeLimits() {
      return this.GET_GameState && this.GET_GameState.config
        ? this.GET_GameState.config.betTypeLimits
        : false;
    },
  },
  methods: {
    currency(value) {
      return this.GET_ICurrencyFormatter.format(value);
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
