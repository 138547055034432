<template>
  <div class="SelectDealer" :class="{ disabled }" id="SelectDealer">
    <div class="mainBtn transparent selectDealerBtn h54">Select dealer</div>
    <div class="DealerCardList">
      <DealerCard
        v-for="dealer in list"
        :key="dealer.id"
        :dealer="dealer"
        :class="{ active: dealer.id == GET_TempDealer }"
        @changeActiveDealer="changeActiveDealer"
        @mouseenter.native="mouseenter(dealer.id)"
        @mouseleave.native="cardHover = false"
        :cardHover="cardHover"
      />
    </div>
    <div class="mainBtn play" @click="select" :class="{ disabled: disabledBtn }">
      <span>Let’s play</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DealerCard from "./DealerCard";
export default {
  name: "SelectDealer",
  components: { DealerCard },
  props: {
    customData: { default: () => {} },
  },
  data: function () {
    return {
      cardHover: false,
      disabled: false,
      activeDealer: null,
      list: [
        {
          name: "Melinda",
          img: require("@/assets/img/dealers/Girl_1.png"),
          id: "Girl_1",
        },
        {
          name: "Stasy",
          img: require("@/assets/img/dealers/Girl_2.png"),
          id: "Girl_2",
        },
        {
          name: "Araya",
          img: require("@/assets/img/dealers/Girl_3.png"),
          id: "Girl_3",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["GET_TempDealer"]),
    disabledBtn() {
      return !this.GET_TempDealer;
    },
  },
  created() {},
  methods: {
    ...mapActions(["SELECT_Dealer", "Animate_Dealer"]),
    ...mapMutations(["SET_TempDealer"]),
    mouseenter(id) {
      this.cardHover = true;
      if (window.innerWidth > 620) this.Animate_Dealer(id);
    },
    changeActiveDealer(id) {
      this.Animate_Dealer(id);
      this.SET_TempDealer(id);
    },
    select() {
      if (this.GET_TempDealer) {
        this.disabled = true;
        this.SELECT_Dealer();
      }
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
