<template>
  <div class="FlipCardLayout" id="FlipCardLayout">
    <div class="wrap">
      <div
        class="card"
        v-if="card"
        :class="{ disabled: GET_DisabledRotateBtn, hiddenSuit: hiddenSuit, turnNow }"
        @dblclick="click"
        @touchend="dbtup"
      >
        <div class="flip">
          <fw-turn :options="options" ref="turn">
            <div class="back"></div>
            <div class="front" v-bind:style="`background-position: ${position};`"></div>
          </fw-turn>
        </div>
      </div>
      <div class="timer">
        <div class="count">{{ GET_FlipCardTime }}</div>
        <img src="@assets/img/loader.svg" alt="loader" />
      </div>
    </div>
    <div
      v-if="GET_WindowWidth < 991"
      :class="{ disabled: GET_DisabledRotateBtn }"
      class="mainBtn flipCard clone"
      id="flipCardButtonClone"
      @click="click"
    >
      flip card
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { FwTurn } from "vue-turnjs";
export default {
  name: "FlipCard",
  components: { FwTurn },
  data() {
    return {
      turnNow: false,
      hiddenSuit: true,
      tapedTwice: false,
      dbtupTimer: false,
      lastTap: 0,
      disabled: false,
      unsubscribe: null,
      defaultSize: {
        width: 233,
        height: 327,
      },
      options: {
        width: 233 * 2,
        height: 327,
        duration: 750,
        pages: 1,
        autoCenter: true,
        gradients: false,
        acceleration: true,
        disabled: true,
        page: 1,
        // display: "single",
        // turnCorners: "bl,br,tl,tr",
        // turnCorners: "r",
        // direction: "rtl",

        when: {
          turning: (event, page, pageObject) => {
            if (this.GET_DisabledRotateBtn) return;
            this.hiddenSuit = false;
            this.turnNow = page == 2 ? true : false;
          },
          turned: (event, page, pageObject) => {
            if (this.GET_DisabledRotateBtn) return;
            this.hiddenSuit = false;
            this.turnNow = false;
            if (page == 1) this.hiddenSuit = true;
            if (page == 2) {
              this.$store.commit("SET_Controls", { disabledRotateBtn: true });
              this.GAME_FlipCard("turned");
              setTimeout(() => {
                this.$refs.turn.goTo(1);
                this.hiddenSuit = true;
              }, 1000);
            }
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "GET_RotatedCardNow",
      "GET_RotatedCardNowOpen",
      "GET_ShowUserCards",
      "GET_FlipCardTime",
      "GET_DisabledRotateBtn",
      "GET_WindowWidth",
      "GET_WindowHeight",
    ]),
    card() {
      return this.GET_RotatedCardNow ? this.GET_RotatedCardNow.card : false;
    },
    size() {
      if (this.GET_WindowWidth < 991 && this.GET_WindowHeight < 700) {
        return {
          width: 150,
          height: 210,
        };
      }
      return this.defaultSize;
    },
    position() {
      const card = this.card[0];
      const lear = this.card[1];
      const line = (() => {
        if (lear == "c") return 2; // крест
        if (lear == "h") return 0; // чирва
        if (lear == "s") return 1; // пика
        if (lear == "d") return 3; // бубна
      })();
      const column = (() => {
        if (card == "A") return 0;
        if (card == "T") return 9;
        if (card == "K") return 12;
        if (card == "Q") return 11;
        if (card == "J") return 10;
        return parseInt(card) - 1;
      })();
      return `${-this.size.width * column}px ${-this.size.height * line}px`;
    },
  },
  methods: {
    ...mapActions(["GAME_FlipCard"]),
    click() {
      if (this.GET_DisabledRotateBtn) return false;
      this.$store.commit("SET_Controls", { disabledRotateBtn: true });
      this.$refs.turn.goTo(2);
      this.hiddenSuit = false;
      setTimeout(() => {
        this.GAME_FlipCard("turned");
        setTimeout(() => {
          this.hiddenSuit = true;
          this.$refs.turn.goTo(1);
        }, 1000);
      }, 750);
    },
    dbtup(event) {
      var currentTime = new Date().getTime();
      var tapLength = currentTime - this.lastTap;
      event.preventDefault();
      clearTimeout(this.dbtupTimer);
      if (tapLength < 500 && tapLength > 0) {
        //Double Tap/Click
        this.click();
        this.tapedTwice = true;
      } else {
        //Single Tap/Click
        this.dbtupTimer = setTimeout(function () {
          //Single Tap/Click code here
          this.tapedTwice = false;
          clearTimeout(this.dbtupTimer);
        }, 300);
      }
      this.lastTap = currentTime;
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribeAction(({ type, payload }, state) => {
      if (type == "GAME_FlipCard" && payload != "turned") {
        this.hiddenSuit = false;
        this.$store.commit("SET_Controls", { disabledRotateBtn: true });
        this.$refs.turn.goTo(2);
        setTimeout(() => {
          this.hiddenSuit = true;
          this.$refs.turn.goTo(1);
        }, 1500);
      }
    });
    const { width, height } = this.size;
    this.options.width = width * 2;
    this.options.height = height;
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
