<template>
  <div
    :class="['GameTableLineItem', { active: tableCell.active || betAreaOutcomes }]"
    @click="tableCellClick"
  >
    <span class="title" v-html="tableCell.title"></span>
    <span class="symbol" v-if="tableCell.symbol">
      <img :src="symbol(tableCell.symbol)" alt="symbol" />
    </span>
    <div class="bet">
      <div class="ChipsList" v-if="tableCell.bets">
        <div
          class="CellChipsItem"
          v-for="(item, index) in mergeBets"
          :key="index"
          :style="`top: ${-index * 3}px`"
        >
          <img :src="image(item.img)" alt="chips" />
        </div>
      </div>
      <span class="value" v-if="value">{{ currency }}</span>
    </div>
  </div>
</template>

<script>
// import LoadingIcon from "@/components/LoadingIcon.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TableCell",
  props: {
    key_api: { default: () => "" },
    tableCell: { default: () => {} },
  },
  computed: {
    ...mapGetters([
      "GET_GameState",
      "GET_CanShowBetAreaOutcomes",
      "GET_Balance",
      "GET_CurrentChips",
      "GET_BetTypeLimits",
      "GET_TableAreas",
      "GET_TotalBet",
      "GET_DisableOppositeBets",
      "GET_ChipsList",
      "GET_ICurrencyFormatter",
      "GET_ActiveTable",
    ]),
    currency() {
      return this.GET_ICurrencyFormatter.format(this.value);
    },
    sortedBets() {
      return this.tableCell.bets.slice().sort((a, b) => (a.val > b.val ? -1 : 1));
    },
    ChipsListReverse() {
      return this.GET_ChipsList.slice(0).reverse();
    },
    betAreaOutcomes() {
      if (
        !this.GET_GameState ||
        !this.GET_GameState.betAreaOutcomes ||
        !this.GET_CanShowBetAreaOutcomes
      )
        return false;
      const { betAreaOutcomes } = this.GET_GameState;
      return betAreaOutcomes.includes(this.key_api);
    },
    value() {
      if (!this.currentTableAreas) return false;
      return this.currentTableAreas.total;
    },
    currentTableAreas() {
      return this.GET_TableAreas[this.tableCell.key];
    },
    mergeBets() {
      const list = [];
      if (initValut == 0) return list;
      let initValut = parseFloat(this.value.toFixed(10));
      this.ChipsListReverse.filter(({ val }) => val).forEach((chip) => {
        const chipVal = parseFloat(chip.val.toFixed(10));
        const floor = Math.floor(initValut / chipVal);
        if (floor >= 1 || initValut / chipVal == 1) {
          initValut = parseFloat((initValut - chipVal * floor).toFixed(10));
          for (let index = 0; index < floor; index++) {
            list.push(chip);
          }
        }
      });
      return list.sort((a, b) => (a.val > b.val ? -1 : 1));
    },
  },
  methods: {
    ...mapActions(["PUSH_TableAreas", "СLEAR_TableArea"]),
    image(name) {
      return require(`@/assets/img/chips/${name}`);
    },
    symbol(name) {
      return require(`@/assets/img/table_symbol/${name}`);
    },
    tableCellClick() {
      if (!this.GET_ActiveTable) return;
      // clear area
      if (this.GET_CurrentChips.val === 0) {
        this.СLEAR_TableArea(this.tableCell.key);
        return false;
      }

      // negative balance
      // if (this.GET_Balance - this.GET_CurrentChips.val < 0) {
      if (this.GET_TotalBet + this.GET_CurrentChips.val > this.GET_Balance) {
        this.$showError(
          "Insufficient funds",
          "Pleace make a deposit or decrease bet size"
        );
        return false;
      }

      // limits
      const betTypeLimits = Object.entries(this.GET_BetTypeLimits);
      const currentLimitEl = betTypeLimits.find((el) => {
        return el[1].betTypes.includes(this.key_api);
      });
      const currentLimit = currentLimitEl[1];
      // min limit
      // if (currentLimit.min > this.GET_CurrentChips.val) {
      //   this.$showError("", `Min position bet reached. Limit ${currentLimit.min}`);
      //   return false;
      // }
      // max limit
      if (this.currentTableAreas.total + this.GET_CurrentChips.val > currentLimit.max) {
        this.$showError("", `Max position bet reached. Limit ${currentLimit.max}`);
        return false;
      }

      // opposite
      if (
        this.GET_DisableOppositeBets &&
        ((this.tableCell.key == "big" && this.GET_TableAreas.small.total) ||
          (this.tableCell.key == "small" && this.GET_TableAreas.big.total) ||
          (this.tableCell.key == "player" && this.GET_TableAreas.banker.total) ||
          (this.tableCell.key == "banker" && this.GET_TableAreas.player.total) ||
          (this.tableCell.key == "banker_pair" &&
            this.GET_TableAreas.player_pair.total) ||
          (this.tableCell.key == "player_pair" && this.GET_TableAreas.banker_pair.total))
      ) {
        this.$showError("", "Opposite bet not allowed");
        return false;
      }
      this.PUSH_TableAreas(this.tableCell.key);
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
