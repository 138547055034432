<template>
  <div class="ReloadPage" id="ReloadPage">
    <img src="@/assets/img/reloadNoticeIcon.svg" alt="reload icon" />
    <span><u>error.</u> Progress will be saved. <b>reload the page</b></span>
    <div class="wrapImage" @click="reload">
      <img src="@/assets/img/reloadIcon.svg" alt="reload icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ReloadPage",
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
