<template>
  <div
    class="GameTable"
    :class="{ disable: !GET_ActiveTable, no_commission: IS_NO_COMMISSION_BACCARAT }"
    @mouseenter="$store.dispatch('ShowHideCursor', true)"
    @mouseleave="$store.dispatch('ShowHideCursor', false)"
  >
    <div class="winner" id="winner">{{ GET_WinnerName }}</div>
    <div class="GameTableLine top">
      <TableCell
        v-for="tableCell in GET_TableAreasTop"
        :key="tableCell.key"
        :key_api="$getBetsKey(tableCell.key)"
        :class="$getBetsKey(tableCell.key)"
        :tableCell="tableCell"
      />
    </div>
    <div class="GameTableLine bottom">
      <TableCell
        class="bottom"
        v-for="tableCell in GET_TableAreasBottom"
        :key="tableCell.key"
        :key_api="$getBetsKey(tableCell.key)"
        :class="$getBetsKey(tableCell.key)"
        :tableCell="tableCell"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TableCell from "./TableCell/";

export default {
  name: "GameTable",
  components: {
    TableCell,
  },

  computed: {
    ...mapGetters([
      "GET_TableAreas",
      "GET_TableAreasTop",
      "GET_TableAreasBottom",
      "GET_ActiveTable",
      "GET_WinnerName",
      "IS_NO_COMMISSION_BACCARAT",
    ]),
  },
  mounted: function () {
    this.$nextTick(function () {});
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped src="./component.less"></style>
<style lang="less">
.GameTableLine.bottom .GameTableLineItem {
  // .title {
    // text-transform: uppercase;
    // font-weight: bold;
  // }
  &:last-child .bet {
    right: 35%;
  }
}
</style>
