<template>
  <div
    class="DealerCard"
    @click="$emit('changeActiveDealer', dealer.id)"
    :class="{ pulse: !GET_TempDealer && !cardHover }"
  >
    <div class="img">
      <img :src="dealer.img" :alt="dealer.name" />
    </div>
    <div class="name">
      <span>{{ dealer.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DealerCard",
  props: {
    dealer: { default: () => {} },
    cardHover: { default: false },
  },
  computed: {
    ...mapGetters(["GET_TempDealer"]),
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
