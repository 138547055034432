<template>
  <div class="ToolTip" ref="ToolTip" @mouseenter="mouseenter" @mouseleave="mouseleave">
    <div
      class="content"
      ref="content"
      v-if="content && show"
      :id="'content_' + _uid"
      :class="['type_' + type, 'position_' + position]"
    >
      <img class="icon" src="@/assets/img/notify.svg" alt="notify" />
      <div v-html="content"></div>
      <div class="rect" ref="rect"></div>
    </div>
  </div>
</template>

<script>
import { TweenLite, TimelineMax } from "gsap";
export default {
  name: "ToolTip",
  props: {
    type: { default: "info", type: String },
    text: { default: null, type: String },
    position: { default: "right", type: String },
  },
  data: function () {
    return {
      show: false,
      timer: null,
      timeline: new TimelineMax(),
    };
  },
  computed: {
    content() {
      return this.text;
    },
  },
  methods: {
    mouseenter(e) {
      if (!this.content) return false;
      this.show = true;
      const parentRect = this.$refs.ToolTip.getBoundingClientRect();
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (!this.$refs.content) return false;
        document.body.appendChild(this.$refs.content);
        TweenLite.set([this.$refs.content, this.$refs.rect], { marginLeft: 0 });
        const { width } = this.$refs.content.getBoundingClientRect();
        const marginLeft = (() => {
          const right = parentRect.left + width;
          const left = parentRect.left;
          if (right > window.innerWidth) {
            return -(right - window.innerWidth + 20);
          }
          if (left < 8) return Math.abs(left);
          return 0;
        })();

        TweenLite.set(this.$refs.rect, {
          marginLeft: -marginLeft,
        });
        TweenLite.set(this.$refs.content, {
          left: parentRect.left + parentRect.width / 2,
          top: parentRect.top + pageYOffset - 15,
        });
        this.timeline.clear();
        this.timeline.fromTo(
          this.$refs.content,
          0.2,
          {
            visibility: "hidden",
            marginLeft: marginLeft,
          },
          {
            visibility: "visible",
            opacity: 1,
          }
        );
      }, 50);
    },
    mouseleave(e) {
      if (!this.content) return false;
      clearTimeout(this.timer);
      this.timeline.clear().to(this.$refs.content, 0.2, {
        opacity: 0,
        onComplete: () => {
          this.show = false;
          // TweenLite.set(this.$refs.content, { visibility: "hidden" });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
