const url = '192.168.0.200:7071'
export default class WebSocketPing {
    inteval = null;
    socket = null;
    closed = false;
    timeout = null;
    pingTimeout = null;
    connectionError = false;
    constructor(store) {
        this.store = store;
        this.connect();
    }

    initPingCallback() {
        console.log('initPingCallback');
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.connectionError = true;
            this.socket.close();
            this.socket = null;
            this.store.commit('SET_ConnectionLost', true);
            this.reconnect();
        }, 1000)
    }

    connect() {
        this.timeout = setTimeout(() => {
            this.connectionError = true;
            this.socket.close();
            this.socket = null;
        }, 2000);
        this.socket = new WebSocket('ws://' + url);
        this.socket.onopen = () => {
            clearTimeout(this.timeout);
            clearInterval(this.inteval);
            this.store.commit('SET_ConnectionLost', false);
            if (this.closed) this.store.getters.GET_Babylon.ReconnectCallback();
            this.closed = false;
            this.connectionError = false;
            // ping
            this.socket.send('PING');
            this.initPingCallback()
        };

        this.socket.onmessage = ({ data }) => {
            if (data == 'PONG') {
                clearTimeout(this.timeout);
                clearTimeout(this.pingTimeout);
                this.pingTimeout = setTimeout(() => {
                    this.socket.send('PING');
                    this.initPingCallback()
                }, 1500);
            }
        };

        this.socket.onerror = (error) => {
            this.connectionError = true;
            this.store.commit('SET_ConnectionLost', true);
            this.reconnect();
        };

        this.socket.onclose = (event) => {
            this.closed = true;
            clearTimeout(this.timeout);
            clearTimeout(this.pingTimeout);
            if (!this.connectionError) {
                this.reconnect();
                this.store.commit('SET_ConnectionLost', true);
            }
        };
    }

    reconnect() {
        this.socket = null;
        clearInterval(this.inteval);
        this.inteval = setInterval(() => { this.connect() }, 2000);
    }
}