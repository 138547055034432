<template>
  <div class="RotatePhone" id="RotatePhone" v-if="show">
    <span>Please flip your phone vertically for the best experience.</span>
    <img src="@assets/img/rotatePhone.svg" alt="rotate phone" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "RotatePhone",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters(["GET_isMobile"]),
  },
  mounted() {
    window.addEventListener("resize", this.checkRotatePhone.bind(this));
    window.addEventListener("orientationchange", this.checkRotatePhone.bind(this));
    this.checkRotatePhone();
  },
  methods: {
    checkRotatePhone(e) {
      if (this.GET_isMobile) {
        this.show = window.innerWidth > window.innerHeight && window.innerHeight < 600;
      } else {
        this.show = false;
      }
      // setTimeout(() => {
      //   this.$store.state.game.babylon.setCamera()
      // }, 500);
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
