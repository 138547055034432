<template>
  <table class="shoe" >
    <thead>
      <tr>
        <th>shoe</th>
        <th>Player</th>
        <th>Banker</th>
        <th>Tie</th>
        <th>Banker pair</th>
        <th>Player pair</th>
        <th>Natural</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="current">
        <td>current</td>
        <td v-for="(shoe, index) in current" :key="'show_' + index + '_' + shoe">
          {{ shoe }}
        </td>
      </tr>
      <tr v-else>
      <td>current</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ShoeTable",
  props: {
    data: {
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["GET_AllHistory"]),
    current() {
      if (!this.GET_AllHistory || !this.GET_AllHistory.length) return false;
      let Player = 0;
      let Banker = 0;
      let Tie = 0;
      let BankerPair = 0;
      let PlayerPair = 0;
      let Natural = 0;
      this.GET_AllHistory.forEach((element, index) => {
        const totalDealerScore = parseInt(element.totalDealerScore);
        const totalPlayerScore = parseInt(element.totalPlayerScore);
        if (totalDealerScore < totalPlayerScore) Player++;
        if (totalDealerScore > totalPlayerScore) Banker++;
        if (totalDealerScore == totalPlayerScore) Tie++;
        let winner = totalDealerScore > totalPlayerScore ? "banker" : "player";
        if (totalDealerScore == totalPlayerScore) winner = "tie";
        const pair = (name) => {
          if (winner === "tie") return false;
          const cards = element.resultTable[name].cards.split(", ");
          if (
            cards[0][0] === cards[1][0]
            // cards[0][0] === cards[1][0] ||
            // (cards[2] != undefined &&
            //   (cards[1][0] === cards[2][0] || cards[0][0] === cards[2][0]))
          ) {
            if (name === "BANKER") {
              BankerPair++;
            } else {
              PlayerPair++;
            }
          }
        };
        pair("BANKER");
        pair("PLAYER");
        if (winner != "tie") {
          if (totalDealerScore == 8 || totalDealerScore == 9) Natural++;
          if (totalPlayerScore == 8 || totalPlayerScore == 9) Natural++;
        }
      });
      return [Player, Banker, Tie, BankerPair, PlayerPair, Natural];
    },
  },
  data: function () {
    return {
      ...this.data,
    };
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
