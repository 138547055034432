<template>
  <div class="topRow row between nowrap" id="interfaceTopRow">
    <ScoreBlock />
    <div class="statisticsWrap" v-if="$isDesktop()">
      <Feedback class="w100" v-if="false" />
      <div :class="['statisticsItem', { active: openStatistics }]">
        <div class="top" @click="openStatisticsItem('statistics')">
          <div class="icon">
            <img src="@/assets/img/statistics.svg" alt="statistics" />
          </div>
          <span>statistics</span>
          <div class="arrow">
            <img src="@/assets/img/arrow.svg" alt="arrow" />
          </div>
        </div>
        <div class="content">
          <ShoeTable />
          <StatisticsTable />
        </div>
      </div>
      <div :class="['statisticsItem', { active: openTableLimits }]">
        <div class="top" @click="openStatisticsItem('limits')">
          <div class="icon">
            <img src="@/assets/img/tableLimits.svg" alt="table limits" />
          </div>
          <span>Table limits</span>
          <div class="arrow">
            <img src="@/assets/img/arrow.svg" alt="arrow" />
          </div>
        </div>
        <div class="content">
          <LimitsTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LimitsTable from "@/components/LimitsTable/";
import StatisticsTable from "@/components/StatisticsTable/";
import ShoeTable from "@/components/ShoeTable/";
import ScoreBlock from "@/components/ScoreBlock/";
import Feedback from "@/components/Feedback/";
export default {
  name: "TopRow",
  components: {
    LimitsTable,
    StatisticsTable,
    ShoeTable,
    ScoreBlock,
    Feedback,
  },
  data() {
    return {
      openStatistics: true,
      openTableLimits: false,
    };
  },
  methods: {
    openStatisticsItem(name = "statistics") {
      if (name === "statistics") {
        this.openTableLimits = false;
        this.openStatistics = !this.openStatistics;
      }
      if (name === "limits") {
        this.openStatistics = false;
        this.openTableLimits = !this.openTableLimits;
      }
    },
  },
  created() {
    this.$root.$data.cards = this.cards;
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
