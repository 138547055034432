<template>
  <div class="ControllButtons row margin0">
    <div
      class="mainBtn flipCard"
      id="flipCard"
      v-if="GET_CanRotate"
      :class="{ disabled: GET_DisabledRotateBtn }"
      @click="flipCard"
    >
      flip card
    </div>
    <div
      :class="['mainBtn red', { disabled: !GET_CanDeal }]"
      @click="resetBets"
      v-if="!GET_CanRebet && !GET_CanRotate"
    >
      <img src="@/assets/img/remove.svg" />
      <span>Remove</span>
    </div>
    <div
      :class="['mainBtn', { disabled: !GET_CanDeal }]"
      @click="deal"
      v-if="!GET_CanRebet && !GET_CanRotate"
    >
      <span>Deal</span>
    </div>
    <template v-if="GET_CanRebet && !GET_CanRotate">
      <div class="mainBtn" @click="rebet(true)">rebet 2x</div>
      <div class="mainBtn" @click="rebet(false)">
        <img src="@/assets/img/rebet.svg" />
        <span>Rebet</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ControllButtons",
  computed: {
    ...mapGetters([
      "GET_DisabledRotateBtn",
      "GET_ActiveTable",
      "GET_CanDeal",
      "GET_CanRebet",
      "GET_CanRotate",
    ]),
  },
  methods: {
    flipCard() {
      if (this.GET_DisabledRotateBtn) return false;
      this.$store.dispatch("GAME_FlipCard");
    },
    resetBets() {
      if (!this.GET_CanDeal) return false;
      this.$store.dispatch("RESET_Bets");
    },
    deal() {
      if (!this.GET_CanDeal) return false;
      this.$store.dispatch("GAME_Deal");
    },
    rebet(setX2 = false) {
      if (!this.GET_CanRebet || this.GET_CanRotate) return false;
      this.$store.dispatch("REBET", setX2);
    },
  },
};
</script>

<style lang="less" scoped src="./component.less"></style>
