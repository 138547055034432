<template>
  <Layout id="GameRules" title="Game rules">
    <ul style="list-style-type: square">
      <li>Number of decks of cards used: 8</li>
      <li>Cards in a deck: 52</li>
      <li>Cards are shuffled before every hand</li>
    </ul>
    <br />
    <div class="rules" v-html="rules"></div>
    <template v-if="RTP">
      <br />
      <h4>RTP</h4>
      <div v-html="RTP"></div>
    </template>
  </Layout>
</template>

<script>
import rules_commition from "./rules/rules.txt";
import rules_no_commision from "./rules/rules_no_commision.txt";
import RTP_commition from "./rules/RTP.txt";
import RTP_no_commition from "./rules/RTP_no_commition.txt";

import { mapGetters } from "vuex";
import Layout from "@/components/Modals/PopupLayout";

export default {
  name: "GameRules",
  components: {
    Layout,
  },
  data() {
    return {
      rules_commition,
      rules_no_commision,
      RTP_commition,
      RTP_no_commition,
    };
  },
  computed: {
    ...mapGetters(["GET_GameState", "IS_NO_COMMISSION_BACCARAT"]),
    rules() {
      return this.IS_NO_COMMISSION_BACCARAT
        ? this.rules_no_commision
        : this.rules_commition;
    },
    RTP() {
      if (!this.GET_GameState.config.showRtp) return false;
      return this.IS_NO_COMMISSION_BACCARAT ? this.RTP_no_commition : this.RTP_commition;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped src="./component.less"></style>
