import store from "@/store/";
import MyBabylon from '../index'

import { InitAnimations } from './animations'
import { HideLoadingUICallBack, Optimization } from './index'
import { simple3d } from '../index'

import ModelSizesData from '../ModelSizes.json';
import Girl_1 from '@/assets/models/Girl_1/Girl_1.gltf';
import Girl_2 from '@/assets/models/Girl_2/casino_girl_3.gltf';
import Girl_3 from '@/assets/models/Girl_3/Casino_Girl_3.gltf';
import Table from '@/assets/models/Table/Casino_Girl_Anim_10_table.gltf';
import Cards from '@/assets/models/Cards/Casino_Girl_Anim.gltf';
import Devices from '@/assets/models/Devices/Casino_Girl_Anim_10_devices.gltf';
import Background_blur from '@/assets/img/background_blur.jpg';
import Cards_sprite from '@/assets/img/cards_sprite.svg';

const modelSizes = { total: 0, loaded: 0 };

import { SceneLoader, AnimationPropertiesOverride, AssetsManager } from 'babylonjs';

const GlobalScale = 0.065;
const ModelScale = 6.66;
const modelsList = [
    'Devices',
    'Cards',
    'Girl_1',
    'Girl_2',
    'Girl_3',
    'Table',
];

const modelsListFiltered = [];

const LoadGirl = async function (Model, Key) {
    console.log('LoadGirl', Key)
    return SceneLoader.ImportMeshAsync("", "./", Model.replace('/', ''), MyBabylon.scene, ({ loaded }) => calcLoadingPercent(Key, loaded)).then(({ meshes, skeletons, animationGroups, transformNodes, geometries }) => {
        calcLoadingPercent(Key); // loading


        MyBabylon.AnimationGroups[Key] = animationGroups;
        MyBabylon.nextCharacterAnimationQueue[Key] = [];

        [...meshes, ...skeletons, ...animationGroups, ...transformNodes, ...geometries].forEach(anim => {
            anim.type = 'girl';
            anim.model = Key;
        });

        var skeleton = skeletons[0];

        // scale
        const scale = ModelScale;
        MyBabylon.characterRoot[Key] = meshes.find(({ name }) => name == '__root__');
        MyBabylon.characterRoot[Key].scaling.set(-scale, scale, scale);

        MyBabylon.characterRoot[Key].position.z = 0.12;
        MyBabylon.characterRoot[Key].position.y = MyBabylon.bottomShift - 0.01;

        MyBabylon.meshes[Key] = MyBabylon.characterRoot[Key];

        var overrides = new AnimationPropertiesOverride();

        overrides.enableBlending = true;
        overrides.blendingSpeed = 0.1;

        skeleton.animationPropertiesOverride = overrides;
        if (MyBabylon.characterRoot[Key]._children[0]._children[0]) {
            MyBabylon.characterRoot[Key]._children[0]._children[0].freezeWorldMatrix();
        }
        if (MyBabylon.characterRoot[Key]._children[0]._children[1] && MyBabylon.characterRoot[Key]._children[0]._children[1]) {
            MyBabylon.characterRoot[Key]._children[0]._children[1].freezeWorldMatrix();
        }
        if (MyBabylon.characterRoot[Key]._children[0]._children[0].material) {
            MyBabylon.characterRoot[Key]._children[0]._children[0].material.freeze();
        };
        if (MyBabylon.characterRoot[Key]._children[0]._children[1] && MyBabylon.characterRoot[Key]._children[0]._children[1].material) {
            MyBabylon.characterRoot[Key]._children[0]._children[1].material.freeze();
        };

        // Shadow
        // MyBabylon.shadowGenerator.addShadowCaster(MyBabylon.scene.meshes[0], true);
        // for (var index = 0; index < meshes.length; index++) {
        //     meshes[index].receiveShadows = false;
        // }

        // disable non selected model
        if (store.getters.GET_Dealer && store.getters.GET_Dealer != Key)
            MyBabylon.characterRoot[Key].setEnabled(false);
    }).catch(err => {
        store.commit('SET_LoadingError')
    })
}

const LoadDevices = async function (Model, Key) {
    console.log('LoadDevices', Key)
    return SceneLoader.ImportMeshAsync("", "./", Model.replace('/', ''), MyBabylon.scene, ({ loaded }) => calcLoadingPercent(Key, loaded)).then(({ meshes }) => {
        calcLoadingPercent(Key) // loading        
        // scale
        MyBabylon.devicesRoot = meshes.find(({ name }) => name == '__root__');
        MyBabylon.devicesRoot.scaling.set(-GlobalScale, GlobalScale, GlobalScale);
        MyBabylon.devicesRoot.position.y = MyBabylon.bottomShift + 0.01;
        MyBabylon.devicesRoot.freezeWorldMatrix();
        MyBabylon.devicesRoot._children[0]._children[0]._children[0].material.freeze();
        MyBabylon.devicesRoot._children[0]._children[0]._children[1].material.freeze();
        MyBabylon.devicesRoot._children[1]._children[0]._children[0].material.freeze();
        MyBabylon.devicesRoot._children[1]._children[0]._children[1].material.freeze();

        MyBabylon.meshes['Devices'] = MyBabylon.devicesRoot;
        // Shadow
        // MyBabylon.shadowGenerator.addShadowCaster(MyBabylon.scene.meshes[0], true);
        // for (var index = 0; index < meshes.length; index++) {
        //     meshes[index].receiveShadows = true;
        // }
    }).catch(err => {
        store.commit('SET_LoadingError')
    })
}

const LoadCards = async function (Model, Key) {
    console.log('loadCards', Key)
    const texture = new BABYLON.Texture(Cards_sprite, MyBabylon.scene);
    texture.level = 1.5;
    return SceneLoader.ImportMeshAsync("", "./", Model.replace('/', ''), MyBabylon.scene, ({ loaded }) => calcLoadingPercent(Key, loaded)).then(({ meshes, animationGroups }) => {
        calcLoadingPercent(Key) // loading
        // scale
        MyBabylon.cardsRoot = meshes.find(({ name }) => name == '__root__');
        MyBabylon.cardsRoot.scaling.set(-GlobalScale, GlobalScale, GlobalScale);
        MyBabylon.cardsList = MyBabylon.cardsRoot._children[0]._children[0]._children;
        MyBabylon.cardsListInit = [...MyBabylon.cardsList];
        MyBabylon.cardsMatList = MyBabylon.scene.materials.filter(({ name }) => name.indexOf('mat_card') >= 0 && name != "mat_card_back");

        // MyBabylon.cardsRoot._children[0].doNotSyncBoundingInfo = true;
        // Shadow
        // MyBabylon.shadowGenerator.addShadowCaster(MyBabylon.scene.meshes[0], true);
        // for (var index = 0; index < meshes.length; index++) {
        // meshes[index].receiveShadows = false;
        // }

        MyBabylon.cardsRoot.position.y = MyBabylon.bottomShift;
        MyBabylon.cardsList.forEach((card, index) => {
            card._children[0]._children[index >= 4 ? 0 : 1].material.albedoTexture = texture.clone();
        });

        MyBabylon.cardAnimations = MyBabylon.scene.animationGroups.filter(anim => anim.type !== 'girl');
        MyBabylon.cardAnimations.forEach(anim => anim.stop())
        MyBabylon.cardAnimations.forEach(anim => anim.disposeOnEnd = true)
        MyBabylon.AnimationGroups[Key] = animationGroups;

        MyBabylon.meshes['Cards'] = MyBabylon.cardsRoot;
        // store.commit('SET_CardsList', MyBabylon.cardsList);
    }).catch(err => {
        store.commit('SET_LoadingError')
    })
}

const LoadTable = async function (Model, Key) {
    console.log('LoadTable', Key)
    return SceneLoader.ImportMeshAsync("", "./", Model.replace('/', ''), MyBabylon.scene, ({ loaded }) => calcLoadingPercent(Key, loaded)).then(({ meshes }) => {
        calcLoadingPercent(Key) // loading
        // scale
        MyBabylon.tableRoot = meshes.find(({ name }) => name == '__root__');
        MyBabylon.tableRoot.scaling.set(-GlobalScale, GlobalScale, GlobalScale);
        MyBabylon.tableRoot.position.y = MyBabylon.bottomShift;
        MyBabylon.tableRoot.freezeWorldMatrix();
        MyBabylon.tableRoot._children[0]._children[0].material.freeze();
        MyBabylon.meshes['Table'] = MyBabylon.tableRoot;
        // Shadow
        // MyBabylon.shadowGenerator.addShadowCaster(MyBabylon.scene.meshes[0], true);
        // for (var index = 0; index < meshes.length; index++) {
        //     meshes[index].receiveShadows = false;
        // }
    }).catch(err => {
        store.commit('SET_LoadingError')
    })
}

const CreateSkyBox = async function () {
    console.log('CreateSkyBox')
    return await new Promise((resolve, reject) => {
        const assetsManager = new AssetsManager(MyBabylon.scene);
        assetsManager.onTaskErrorObservable.add(function (task) {
            reject(task.errorObject.message);
            store.commit('SET_LoadingError');
        });

        const imageTask = assetsManager.addTextureTask("SkyBox", Background_blur);

        imageTask.onSuccess = function (task) {
            var mat = new BABYLON.StandardMaterial("mat", MyBabylon.scene);
            const emissiveColor = 0.4;
            mat.emissiveColor = new BABYLON.Color3(emissiveColor, emissiveColor, emissiveColor);
            mat.diffuseTexture = task.texture;

            var faceUV = new Array(6);
            var columns = 1;  // 13 columns
            var rows = 1;  // 4 rows
            const indexInColumn = 0;
            const indexInRow = 0;

            //overwrite face with sprite coordinates
            var Ubottom_left = (indexInColumn - 0) / columns;
            var Vbottom_left = indexInRow * 1 / rows;
            var Utop_right = (indexInColumn + 1) / columns;
            var Vtop_right = (indexInRow + 1) * 1 / rows;
            for (var i = 0; i < faceUV.length; i++) {
                faceUV[i] = new BABYLON.Vector4(0, 0, 0, 0);
            }
            faceUV[1] = new BABYLON.Vector4(Ubottom_left, Vbottom_left, Utop_right, Vtop_right); // back site
            const width = 300;
            const coef = 0.582759;
            MyBabylon.skybox = BABYLON.MeshBuilder.CreateBox("skyBox", {
                width: width,
                height: width * coef,
                depth: width * coef,
                faceUV: faceUV
            }, MyBabylon.scene);
            MyBabylon.skybox.scaling.set(-1, 1, 1)
            mat.reflectionFresnel = true;
            mat.reflectionfalloffdistance = 20;
            mat.fogEnabled = false;
            mat.reflectionStandardFresnelWeight = 5.0;
            mat.backFaceCulling = false;
            MyBabylon.skybox.material = mat;
            MyBabylon.skybox.rotation.y = Math.PI / 2 * 90;
            var helper = MyBabylon.scene.createDefaultEnvironment({ createGround: false });
            MyBabylon.skybox.position.y = 0.5;
            // 
            resolve();
        }
        assetsManager.load();
    });
}

const LoadModels = async function () {
    // calcModelSizes
    modelsList.includes('Girl_1') ? modelsListFiltered.push('Girl_1') : null;
    modelsList.includes('Girl_2') ? modelsListFiltered.push('Girl_2') : null;
    modelsList.includes('Girl_3') ? modelsListFiltered.push('Girl_3') : null;
    modelsList.includes('Devices') ? modelsListFiltered.push('Devices') : null;
    modelsList.includes('Cards') ? modelsListFiltered.push('Cards') : null;
    modelsList.includes('Table') ? modelsListFiltered.push('Table') : null;
    calcModelSizes();

    modelsList.includes('Devices') ? await LoadDevices.call(this, Devices, 'Devices') : null;
    modelsList.includes('Cards') ? await LoadCards.call(this, Cards, 'Cards') : null;
    modelsList.includes('Table') ? await LoadTable.call(this, Table, 'Table') : null;

    await Promise.all([
        LoadGirl(Girl_1, 'Girl_1'),
        LoadGirl(Girl_2, 'Girl_2'),
        LoadGirl(Girl_3, 'Girl_3'),
    ])

    InitAnimations();

    if (!simple3d) {
        await CreateSkyBox();
        await MyBabylon.initSound();
    }

    if (!store.getters.GET_LoadingError) {
        Optimization();
        HideLoadingUICallBack();
    }
}

const calcModelSizes = () => {
    modelsListFiltered.forEach(name => {
        const { size = 0 } = ModelSizesData[name];
        modelSizes[name] = { size, loaded: 0 };
        modelSizes.total += size;
    });
}

const calcLoadingPercent = (Key, loadedNow = modelSizes[Key].size) => {
    const loaded = loadedNow > modelSizes[Key].loaded ? loadedNow : modelSizes[Key].loaded;
    modelSizes[Key].loaded = loaded;
    const loadedModelPercent = parseInt(loaded / modelSizes[Key].size * 100);
    let totalLoaded = modelsListFiltered.reduce((sum, current) => {
        return sum + modelSizes[current].loaded;
    }, 0);
    const totalPercent = parseInt(totalLoaded / modelSizes.total * 100);
    store.commit('SET_LoadingPercent', totalPercent);
}

const ConvertCardTextureData = (cardIndex = 0, val = '') => {
    const card = val[0];
    const lear = val[1];
    // отсчёт идет от левого нижнего угла
    const line = (() => {
        if (lear == "c") return 1; // крест
        if (lear == "h") return 3; // чирва
        if (lear == "s") return 2; // пика
        if (lear == "d") return 0; // бубна
    })();
    const column = (() => {
        if (card == "A") return 0;
        if (card == "T") return 9;
        if (card == "K") return 12;
        if (card == "Q") return 11;
        if (card == "J") return 10;
        return parseInt(card) - 1;
    })();
    SetCardTexture(cardIndex, line, column);
}

const ResetCardAnimation = () => {
    console.log('ResetCardAnimation')
    // MyBabylon.ResetCardAnimationNow = true;
    // let name1 = 'collect_cards';
    // let name2 = 'anim_5A';
    // let name3 = 'collect_cards';
    // MyBabylon.cardsRoot.setEnabled(false)
    // MyBabylon.cardAnimations.forEach(anim => anim.name == name1 ? anim.play() : setTimeout(() => { anim.stop() }, 2500))
    // setTimeout(() => { MyBabylon.cardAnimations.forEach(anim => anim.name == name2 ? anim.play() : anim.stop()) }, 30);
    // setTimeout(() => { MyBabylon.cardAnimations.forEach(anim => anim.name == name3 ? anim.play() : anim.stop()) }, 50);
    // setTimeout(() => { MyBabylon.cardAnimations.forEach(anim => { anim.stop() }); MyBabylon.cardsRoot.setEnabled(true); MyBabylon.ResetCardAnimationNow = false; }, 75);
}

const SetAllCardsTexture = (row = 0, column = 0) => {
    for (let index = 0; index < MyBabylon.cardsList.length; index++) {
        SetCardTexture(index, row, column)
    }
}

const SetRndCardTextures = () => {
    for (let index = 0; index < MyBabylon.cardsList.length; index++) {
        SetCardTexture(index, rnd(0, 3), rnd(0, 12))
    }
}

const SetCardTexture = (cardIndex = 0, row = 0, column = 0) => {
    // MyBabylon.scene.unfreezeMaterials();
    if (cardIndex < 0 || cardIndex > MyBabylon.cardsList.length - 1) return false;
    const texture = MyBabylon.cardsList[cardIndex]._children[0]._children[cardIndex >= 4 ? 0 : 1].material.albedoTexture;
    let shift = 0;
    if (cardIndex < 4) {
        texture.uScale = 1;
    } else {
        shift = 1;
        texture.uScale = -1;
    }

    texture.vOffset = 1 / 4 * (row); // row
    texture.uOffset = 1 / 13 * (column - shift); // column

    if (cardIndex == 4 || cardIndex == 5) {
        return SetCardTexture(cardIndex + 2, row, column + 2)
    };
    // setTimeout(() => { MyBabylon.scene.freezeMaterials(); }, 500);
};


export { LoadModels, ConvertCardTextureData, ResetCardAnimation }